import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import _uniqBy from 'lodash/uniqBy';

import { ArrayLike } from './typeUtils';

export type SelectOption = {
  value: string;
  label: string;
};

export const arrayToKeyedObject = (array) =>
  array.reduce((acc, value) => {
    acc[value] = value;
    return acc;
  }, {});

export const arrayToSelectOptions = (array) =>
  array.map((value) => ({
    value,
    label: value,
  }));

export const includesAll = (wholeArray, partOfWholeArray) => {
  const notContainedElement = partOfWholeArray.find((arrayElement) => !_includes(wholeArray, arrayElement));

  return notContainedElement === undefined;
};

// Checks if two arrays contain the same elements regardless of order.
export const haveSameContents = (arr1: SelectOption[], arr2: SelectOption[]): boolean => {
  if (arr1?.length !== arr2?.length) return false;

  const combinedArr = _uniqBy([...arr1, ...arr2], 'value');

  /* eslint-disable-next-line */
  for (const item of combinedArr) {
    const filteredArr1Count = _filter(arr1, (arr1Item) => arr1Item.value === item.value).length;
    const filteredArr2Count = _filter(arr2, (arr2Item) => arr2Item.value === item.value).length;

    if (filteredArr1Count !== filteredArr2Count) {
      return false;
    }
  }

  return true;
};

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export const toArray = <T>(arr: ArrayLike<T>): T[] => (Array.isArray(arr) ? arr : [arr]);
