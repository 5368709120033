import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  ArtistSubscriptionFragment,
  PaywallAddPaymentOptionDoc,
  PaywallGetBillingInfoDoc,
  PaywallGetBillingInfoQuery,
} from '@minecraft.graphql-operations';
import { TokenPayload } from '@recurly/recurly-js';
import { captureExceptionEvent } from '@blocs.sentry';
import { useTranslation } from '@blocs.i18n';
import { parsePaymentsError } from './helpers/parseApolloError';

export type UseBilling = {
  wallet?: PaywallGetBillingInfoQuery['getBillingInfo'];
  loading?: boolean;
  error?: Error;
  onAddPaymentOption: (T: {
    // Token is only optional if we have already attempted a purchase but need the threeDActionToken
    token?: TokenPayload;
    isBackupPaymentMethod?: boolean;
    isPrimaryPaymentMethod?: boolean;
    threeDSecureActionResultTokenId?: string;
  }) => void;
  selectedPaymentOption?: PaywallGetBillingInfoQuery['getBillingInfo'][0];
  setSelectedPaymentOption: ({ id }: { id: PaywallGetBillingInfoQuery['getBillingInfo'][0]['id'] }) => void;
  addPaymentLoading?: boolean;
  addPaymentError?: string;
  showAddPaymentMethod?: boolean;
  setShowAddPaymentMethod: (T: boolean) => void;
  showThreeDSecurityForm?: boolean;
  threeDActionToken?: string;
  onThreeDActionError: () => void;
};

type UseBillingProps = {
  currentActiveSubscription: ArtistSubscriptionFragment;
};

class ThreeDFailure extends Error {}

export const useBilling = ({ currentActiveSubscription }: UseBillingProps): UseBilling => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(PaywallGetBillingInfoDoc, { variables: { input: {} } });
  const [showAddPaymentMethod, setShowAddPaymentMethod] = React.useState(false);
  const [savePaymentOption, { loading: addPaymentLoading, error: addPaymentError }] =
    useMutation(PaywallAddPaymentOptionDoc);
  const [threeDActionError, setThreeDActionError] = useState<Error>();
  const [currentlySelectedPaymentOption, setCurrentlySelectedPaymentOption] =
    useState<PaywallGetBillingInfoQuery['getBillingInfo'][0]>();
  const [showThreeDSecurityForm, setShowThreeDSecurityForm] = useState(false);
  const [threeDActionToken, setThreeDActionToken] = useState<string | undefined>();
  // We hold any older payment information here when we need to do the extra hop for 3D secure token:
  const preThreeDConfirmationCardInformation = useRef<{
    token: TokenPayload;
    isBackupPaymentMethod?: boolean;
    isPrimaryPaymentMethod?: boolean;
  }>();
  const [visibleError, setVisibleError] = useState<string>();

  // When we initially get all the data, we select the payment option that may be present on any known subscriptions
  // If not we just select the primary
  const initialSelectedPaymentOption = useMemo(() => {
    if (data && !loading && currentActiveSubscription) {
      let currentPayment = data.getBillingInfo.find(
        (paymentOption) => paymentOption.id === currentActiveSubscription.billingInfoId
      );

      if (!currentPayment) {
        currentPayment = data.getBillingInfo.find((paymentOption) => paymentOption.isPrimaryPaymentMethod);
      }

      return currentPayment;
    }

    return undefined;
  }, [data, loading, currentActiveSubscription]);

  const setSelectedPaymentOption = useCallback(
    ({ id }) => {
      // Find the payment method in the wallet that matches this id
      // With that we "hydrate" the selected payment option
      const foundPaymentOption = data.getBillingInfo.find((paymentOption) => paymentOption.id === id);
      setCurrentlySelectedPaymentOption(foundPaymentOption);
    },
    [data]
  );

  const onAddPaymentOption = useCallback(
    ({ token, isBackupPaymentMethod, isPrimaryPaymentMethod, threeDSecureActionResultTokenId }) => {
      setVisibleError(undefined);
      const savePaymentInput = {
        tokenId: token?.id ?? preThreeDConfirmationCardInformation.current?.token?.id,
        backupPaymentMethod:
          isBackupPaymentMethod ?? preThreeDConfirmationCardInformation.current?.isBackupPaymentMethod,
        primaryPaymentMethod:
          isPrimaryPaymentMethod ?? preThreeDConfirmationCardInformation.current?.isPrimaryPaymentMethod,
        threeDSecureActionResultTokenId,
      };

      // TokenId is not sent when we are in the 3D secure flow, so just to
      // be sure we've had the token previously we do a check here:
      if (!savePaymentInput.tokenId) {
        console.error('No token id found');
        captureExceptionEvent(new Error('No token id found'));

        return;
      }

      savePaymentOption({
        variables: { input: savePaymentInput },
        refetchQueries: ['PaywallGetBillingInfo'],
        awaitRefetchQueries: true,
      })
        .then((response) => {
          if (response?.errors?.length) {
            // There can be an error if we need to use the 3-D Secure flow
            const threeDToken: string =
              (response as any).errors[0]?.data?.errorData?.threeDSecureActionTokenId ||
              (response as any).errors[0]?.data?.errorData?.publicData?.threeDSecureActionTokenId;

            if (threeDToken) {
              // Save the previous information for when we attempt to finish the 3D secure flow
              // We will call this same function with only the threeDToken so we save them for the next round
              preThreeDConfirmationCardInformation.current = { token, isBackupPaymentMethod, isPrimaryPaymentMethod };
              setShowThreeDSecurityForm(true);
              setThreeDActionToken(threeDToken);
            }
          } else {
            // When we are done, we show the wallet
            setShowAddPaymentMethod(false);
            setShowThreeDSecurityForm(false);
            setThreeDActionToken(undefined);
          }
        })
        .catch((err) => {
          // This error is shown visually to the user via the addPaymentError
          console.error(err);
          captureExceptionEvent(err);
        });
    },
    [savePaymentOption]
  );

  const onThreeDActionError = useCallback(() => {
    // We clear the information we have stored for the 3D secure flow
    setShowThreeDSecurityForm(false);
    setThreeDActionToken(undefined);
    setThreeDActionError(new ThreeDFailure());
  }, []);

  useEffect(() => {
    // Hide the add billing screen if we have a wallet length (at first)
    if (!loading && data?.getBillingInfo) {
      setShowAddPaymentMethod(!data?.getBillingInfo.length);
    }
  }, [loading, data?.getBillingInfo]);

  useEffect(() => {
    // Safety net for unknown errors
    if (addPaymentError || threeDActionError) {
      // CICN-later Add all of the possible exception types here:
      if (threeDActionError && threeDActionError instanceof ThreeDFailure) {
        setVisibleError('3D Secure flow failed [3001]');
      } else {
        const paymentsError = parsePaymentsError(addPaymentError);
        setVisibleError(t(`common:paywall.errors.${paymentsError}`));
      }
    }
  }, [addPaymentError, threeDActionError, t]);

  return useMemo(
    () => ({
      addPaymentError: visibleError,
      addPaymentLoading,
      error,
      loading,
      onAddPaymentOption,
      onThreeDActionError,
      selectedPaymentOption: currentlySelectedPaymentOption ?? initialSelectedPaymentOption,
      setSelectedPaymentOption,
      setShowAddPaymentMethod,
      showAddPaymentMethod,
      showThreeDSecurityForm,
      threeDActionToken,
      wallet: data?.getBillingInfo,
    }),
    [
      addPaymentLoading,
      currentlySelectedPaymentOption,
      data,
      error,
      initialSelectedPaymentOption,
      loading,
      onAddPaymentOption,
      onThreeDActionError,
      setSelectedPaymentOption,
      setShowAddPaymentMethod,
      showAddPaymentMethod,
      showThreeDSecurityForm,
      threeDActionToken,
      visibleError,
    ]
  );
};
