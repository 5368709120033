import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { ProfileMeasurements } from '@blocs.common';
import styled from 'styled-components';
import { BaseballCardProfileFragment } from '@minecraft.graphql-operations';
import { DetailTextLarge } from '@minecraft.atoms';

type MeasurementsProps = Pick<BaseballCardProfileFragment, 'height' | 'weight' | 'id'>;

export const Measurements = (props: MeasurementsProps) => {
  return (
    <>
      {(props.height || props.weight) && (
        <DetailTextLarge as="div" testId="measurements-text">
          <ProfileMeasurements profileMeasurements={props} />
        </DetailTextLarge>
      )}
    </>
  );
};

const StyledLoadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.875rem 1fr;
  gap: 0.25rem;
`;

const LoadingWrapper = (props: MeasurementsProps | { loading: true }) => {
  return 'loading' in props ? (
    <StyledLoadingWrapper>
      <Skeleton circle />
      <Skeleton />
    </StyledLoadingWrapper>
  ) : (
    <Measurements {...props} />
  );
};

export default LoadingWrapper;
