import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';
import { media } from '@minecraft.themes';
import Button from '@minecraft.button';
import PaginationContext from '../PaginationContext';

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  padding: 0.4375rem 1rem;
  height: auto;

  ${media.sm`
    font-size: .75rem;
    padding: 0.3125rem 2rem;
    width: auto;
  `}

  &:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0;

    ${media.sm`
    margin-bottom: 0;
    margin-right: 1.25rem;
    `}
  }
`;

const StyledButtonText = styled.span`
  margin: auto;

  ${media.sm`
    margin: 0;
  `}
`;

export type Props = {
  className?: string;
};

const SeeMore: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const { totalRecords, pageLimit, currentPage, onPageChanged } = useContext(PaginationContext);
  return totalRecords > pageLimit * currentPage ? (
    <StyledButton
      className={className}
      outlined
      role="button"
      onClick={(): void =>
        onPageChanged({
          currentPage: currentPage + 1,
          pageLimit,
        })
      }
    >
      <StyledButtonText>{t('common:button.seeMore')}</StyledButtonText>
    </StyledButton>
  ) : null;
};

export default SeeMore;
