import React, { ReactElement, ReactText, FC } from 'react';
import _isUndefined from 'lodash/isUndefined';
import _isString from 'lodash/isString';
import _isNumber from 'lodash/isNumber';
import styled from 'styled-components';
import { Theme } from '@minecraft.themes';

/** Types */
type Props = {
  isBold?: boolean;
  className?: string;
  value?: any;
  label?: ReactText | ReactElement;
  count?: number;
  name?: string;
  checkmarkSize?: 'small' | 'large';
  'data-qa-id'?: string;
  children?: any;
  isChecked: boolean;
  disabled?: boolean;
  onChange: (e?: any) => void;
  testId?: string;
  noMargin?: boolean;
};

type LabelProps = {
  $isBold: boolean;
  $checkmarkSize: Props['checkmarkSize'];
  $hasLabel: boolean;
  disabled: boolean;
  theme: Theme;
};

type CheckmarkProps = {
  $checkmarkSize: Props['checkmarkSize'];
  disabled: boolean;
  theme: Theme;
};

/** Styled Components */
/* Customize the label (the container) */
const Label = styled.label<LabelProps>`
  color: ${(props): string =>
    props.disabled ? props.theme.colors.primary.grey1 : props.theme.colors.primary.darkGrey};
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  display: block;
  font-size: 1rem;
  line-height: ${(props): string =>
    props.$checkmarkSize === 'large' ? '1.25' : '1'}; /* line height should be match with Checkmark height */
  min-height: ${(props): string => (props.$checkmarkSize === 'large' ? '1.25rem' : '1rem')};
  padding-left: ${(props): string => {
    if (props.$hasLabel) {
      return props.$checkmarkSize === 'large' ? '1.75rem' : '1.5rem';
    }

    return props.$checkmarkSize === 'large' ? '1.35rem' : '1.5rem';
  }};
  position: relative;
  transition: color 0.3s ease;
  user-select: none;

  ${(props): string =>
    props.$isBold &&
    `
    font-weight: 600;
  `}
`;

/* Create a custom checkbox */
const Checkmark = styled.span<CheckmarkProps>`
  background-color: ${(props): string => props.theme.colors.primary.white};
  border: 1px solid
    ${(props): string => (props.disabled ? props.theme.colors.primary.ltGrey1 : props.theme.colors.primary.grey1)};
  border-radius: 0.125rem;
  box-sizing: content-box;
  display: inline-block;
  left: 0;
  position: absolute;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  top: 0;
  height: ${(props): string => (props.$checkmarkSize === 'large' ? '1.125rem' : '.875rem')};
  width: ${(props): string => (props.$checkmarkSize === 'large' ? '1.125rem' : '.875rem')};

  &::after {
    content: '';
    border: solid ${(props): string => props.theme.colors.primary.white};
    border-width: 0 3px 3px 0;
    height: 0.6875rem;
    left: ${(props): string => (props.$checkmarkSize === 'large' ? '0.4375rem' : '0.275rem')};
    opacity: 0;
    position: absolute;
    top: ${(props): string => (props.$checkmarkSize === 'large' ? '0.1875rem' : '0.08625rem')};
    transform: rotate(45deg);
    transition: opacity 0.3s;
    width: 0.375rem;
  }
`;

const StyledInput = styled.input`
  /* Visually hidden content so the input is accessible by screen readers */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked ~ ${Checkmark} {
    background-color: ${(props): string =>
      props.disabled ? props.theme.colors.primary.ltGrey1 : props.theme.colors.primary.grey1};

    &::after {
      opacity: 1;
    }
  }
`;

const CheckboxLabel: FC<Pick<Props, 'count' | 'label'> & { withCount: boolean }> = ({ withCount, count, label }) => {
  if ((_isString(label) || _isNumber(label)) && withCount) {
    return <>{`${label} (${count})`}</>;
  }

  return <>{label}</>;
};

/** Main Component */
const Checkbox: FC<Props> = ({
  isBold = false,
  label: primaryLabel,
  count,
  disabled: primaryDisabled,
  isChecked,
  checkmarkSize = 'large',
  onChange,
  className = '',
  children,
  testId,
  noMargin = false,
  ...restProps
}) => {
  const withCount = !_isUndefined(count);

  const disabled = primaryDisabled || (withCount && count <= 0);
  const computedClassName = (noMargin ? ['cn_mb-0'] : ['cn_mb-3']).concat(className?.split(' ')).join(' ');
  const hasLabel = primaryLabel || children;

  return (
    <Label
      $isBold={isBold}
      $checkmarkSize={checkmarkSize}
      $hasLabel={hasLabel}
      disabled={disabled}
      data-qa-id={restProps['data-qa-id'] ? `${restProps['data-qa-id']}-label` : ''}
      data-testid={testId || 'checkbox-label'}
      className={computedClassName}
    >
      {children || <CheckboxLabel label={primaryLabel} count={count} withCount={withCount} />}
      <StyledInput
        type="checkbox"
        checked={isChecked}
        disabled={disabled}
        onChange={onChange}
        data-testid={testId ? `${testId}-input` : 'checkbox-input'}
        {...restProps}
      />
      <Checkmark $checkmarkSize={checkmarkSize} disabled={disabled} className="checkmark" />
    </Label>
  );
};

export default Checkbox;
