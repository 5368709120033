import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { DesignSystemBgColor } from '@minecraft.themes';
import { FlexCol, FlexProps, FlexRow } from '@minecraft.atoms';
import { WindowWidthContext } from '@minecraft.utils';

type SectionSize = 'small' | 'medium' | 'large';

const Paddings = {
  largeMobile: classNames('cn_atom_p-4'),
  largeNonMobile: classNames('cn_atom_pt-1'),

  medium: classNames('cn_atom_px-2', 'cn_atom_py-3'),

  smallMobile: classNames('cn_atom_pt-1', 'cn_atom_pb-1', 'cn_atom_pl-2', 'cn_atom_pr-2'),
  smallNonMobile: classNames('cn_atom_p-2'),
};
const useClassName = (padding: SectionSize) => {
  const isMobile = WindowWidthContext.useIsMobile();

  return classNames(
    padding === 'large' && isMobile && Paddings.largeMobile,
    padding === 'large' && !isMobile && Paddings.largeNonMobile,

    padding === 'medium' && Paddings.medium,

    padding === 'small' && isMobile && Paddings.smallMobile,
    padding === 'small' && !isMobile && Paddings.smallNonMobile
  );
};

type SectionProps = {
  backgroundColor?: DesignSystemBgColor;
  flexDirection?: React.CSSProperties['flexDirection'];
  size?: SectionSize;
};

export const Section: React.FC<FlexProps & SectionProps> = ({
  backgroundColor,
  children,
  flexDirection = 'column',
  size,
  ...props
}) => {
  const className = useClassName(size);
  const Flex = flexDirection === 'column' ? FlexCol : FlexRow;

  return (
    <Flex bgColor={backgroundColor} grow={0} shrink={0} {...props} className={classNames(className, props.className)}>
      {children}
    </Flex>
  );
};

export const StyledDividedSection = styled.div`
  display: flex;
  flex-direction: column;

  &.left-border + &.left-border {
    border: none;
    border-left: 1px solid ${({ theme }) => theme.designSystem.sectionBreak.divider};
  }

  &.top-border + &.top-border {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.designSystem.sectionBreak.divider};
  }
`;

export const DividedSection: React.FC<React.ComponentProps<typeof StyledDividedSection>> = ({
  children,
  padding,
  ...props
}) => {
  const isMobile = WindowWidthContext.useIsMobile();

  return (
    <StyledDividedSection {...props} className={classNames(props.className, isMobile ? 'top-border' : 'left-border')}>
      {children}
    </StyledDividedSection>
  );
};
