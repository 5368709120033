import React from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import { FormikTouched, FormikErrors } from 'formik/dist/types';

export const ErrorText = styled.div`
  color: ${(props) => props.theme.colors.accent.red};
`;

export type FormikErrorMessageProps = {
  name: string;
  'data-qa-id'?: string;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
};

export default function ({ name, 'data-qa-id': qaId, touched, errors }: FormikErrorMessageProps): JSX.Element {
  return (
    <>
      {_get(touched, name) && _get(errors, name) && (
        <ErrorText data-qa-id={qaId && `${qaId}-error-message`}>{_get(errors, name)}</ErrorText>
      )}
    </>
  );
}
