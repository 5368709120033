import React, { useCallback, useMemo } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import Icon from '@minecraft.icon';
import { BodyText, BodyTextLarge, FlexCol, FlexDynamic, FlexRow } from '@minecraft.atoms';
import { media } from '@minecraft.themes';
import { useHistory } from 'react-router';
import { WizardData, WizardProps, WizardContext } from './types';

// Helper for setting color and border for active completed and default steps
const getStepNumberContainerStyles = (active: boolean, completed: boolean, theme: DefaultTheme, hasBorder: boolean) => {
  let backgroundColor: string;
  let color: string;
  let border: string;

  if (completed) {
    backgroundColor = theme.designSystem.wizard.completedStepBackgroundColor;
    border = theme.designSystem.wizard.completedStepBorder;
  } else if (active && !completed) {
    backgroundColor = theme.designSystem.wizard.activeStepBackgroundColor;
    border = theme.designSystem.wizard.activeStepBorder;
  } else {
    backgroundColor = theme.designSystem.wizard.stepBackgroundColor;
    border = theme.designSystem.wizard.stepBorder;
  }

  if (hasBorder) {
    return `
    background-color: ${backgroundColor};
    color: ${color};
    border: 2px solid ${border};
    `;
  }

  return `
      background-color: ${backgroundColor};
      color: ${color};
      `;
};

const StepWizardStepNumberContainer = styled(FlexCol)<{
  active: boolean;
  completed: boolean;
}>`
  ${({ active, completed, theme }) => getStepNumberContainerStyles(active, completed, theme, true)}
  border-radius: 50%;
  padding: 0.5rem;
  z-index: 2;

  &:hover {
    ${({ active, completed, theme }) => getStepNumberContainerStyles(active, completed, theme, false)}
  }

  ${media.md`
    padding:1rem;
  `}
`;

const StepWizardTitleContainer = styled.div`
  width: max-content;
  text-align: center;
`;

const StepNumber = styled(FlexRow)`
  height: 1rem;
  width: 1rem;
`;

const Line = styled.div<{ $isActive: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${(props) => {
    if (props.color === 'link') return props.theme.designSystem.wizard.activeStepColor;
    if (props.$isActive) return null;
    return props.theme.designSystem.wizard.stepColor;
  }};
  background: ${(props) =>
    props.$isActive &&
    `linear-gradient(to right, ${props.theme.designSystem.wizard.activeStepColor} 0%, ${props.theme.designSystem.wizard.stepColor} 100%)`};
  align-self: baseline;
  margin-top: 1rem;
  ${media.md`
    margin-top: 1.5rem
  `}
`;

const WizardWrapper = styled(FlexCol)`
  width: 100vw;
  align-self: center;
`;

// Body text does not allow for overriding the color the use of important is required
// to override the color of the body text
// TODO - remove the use of important investigate why the color is not being overridden - @georgiosp July 18 2023
const WizardHeaderBodyText = styled(BodyText)<{ $isActive: boolean }>`
  color: ${(props) =>
    props.$isActive
      ? props.theme.designSystem.wizard.activeStepColor
      : props.theme.designSystem.wizard.stepColor} !important;
`;

/**
 * Wizard component accepts a list of components and renders them into a stepped based UI
 * the wizard will provide a set of function and context that allows for control over moving between steps
 *
 * The wizard component does not effect the styles of its steps those should be styled and controlled outside the wizard
 * the only controlled UI is the wizard header within the wizard component
 *
 * A UI implementation example can be found in the following design on figma
 * https://www.figma.com/file/Lw7DgrLZj850OsQTJOKAOs/JIRA---B21-28339---Casting---Project-Creation---%22Quick-Project-Creation%22-workflow?node-id=1762-25782&t=T95RixqUsqvs64s7-0
 *
 * */

export const Wizard = <ContextData extends WizardData>({ wizard, isTransparent }: WizardProps<ContextData>) => {
  const { setWizardState, wizardState, steps } = wizard;
  const { currentStep, data } = wizardState;
  const history = useHistory();

  const currentStepConfig = useMemo(() => {
    return steps.find((s) => s.id === currentStep);
  }, [steps, currentStep]);

  const firstStep = useMemo(() => {
    return steps?.[0];
  }, [steps]);

  const lastStep = useMemo(() => {
    return steps?.[steps?.length - 1];
  }, [steps]);

  const completedSteps = useMemo(() => {
    const currentIndex = steps.findIndex((s) => s.id === currentStep);

    const previousSteps = steps.slice(0, currentIndex);

    return previousSteps.map((s) => s.id.toString());
  }, [currentStep, steps]);

  const onNext = useCallback(
    (newData: ContextData, updateQueryParams = false) => {
      const currentIndex = steps.findIndex((s) => s.id === currentStep);

      let nextIndex = currentIndex + 1;
      const maxIndex = steps.length - 1;

      if (nextIndex >= maxIndex) {
        nextIndex = maxIndex;
      }

      const nextStep = steps[nextIndex];

      setWizardState({
        currentStep: nextStep.id,
        data: newData || data,
      });

      if (updateQueryParams) {
        history.push(history.location.pathname.concat(`?step=${nextStep.id}`));
      }
    },
    [currentStep, data, steps]
  );

  const onPrev = useCallback(
    (newData: ContextData, updateQueryParams = false) => {
      const currentIndex = steps.findIndex((s) => s.id === currentStep);

      let nextIndex = currentIndex - 1;

      if (nextIndex <= 0) {
        nextIndex = 0;
      }

      const nextStep = steps[nextIndex];

      setWizardState({ currentStep: nextStep.id, data: newData || data });

      if (updateQueryParams) {
        history.push(history.location.pathname.concat(`?step=${nextStep.id}`));
      }
    },
    [currentStep, data, steps]
  );

  const goTo = useCallback(
    (id: string, newData?: ContextData) => {
      const nextStep = steps.find((s) => s.id === id);

      if (nextStep && nextStep?.id !== currentStep) {
        setWizardState({ currentStep: nextStep.id, data: newData || data });
      } else {
        console.warn(`could not find a step with id "${id}"`);
      }
    },
    [currentStep, data, steps]
  );

  const setData = useCallback((newData: ContextData) => setWizardState({ data: newData, currentStep }), [currentStep]);

  const wizardContext: WizardContext<ContextData> = useMemo(() => {
    return {
      data,
      hasNext: lastStep?.id !== currentStep,
      hasPrev: firstStep?.id !== currentStep,
      currentStep,
      loading: false,
      setData,
      onNext,
      onPrev,
      goTo,
    };
  }, [currentStep, firstStep, lastStep, data, onNext, onPrev, goTo, setData]);

  const Component = currentStepConfig?.Component;

  // check all steps have a title
  const allStepsHaveTitle = steps.every((step) => step.title);

  const mobileWizardWrapperClassNames = `cn_mb-8 cn_mt-4 ${
    allStepsHaveTitle ? `cn_w-1` : `cn_w-full`
  } cn_relative cn_flex_algin_self_center`;

  const wizardWrapperClassNames = 'cn_mb-8 cn_mt-4 cn_w-1/2 cn_relative cn_flex_algin_self_center';

  return (
    <WizardWrapper
      alignContent="center"
      data-testid="wizard-wrapper"
      bgColor={isTransparent ? 'transparent' : 'wizardWrapperBg'}
    >
      {/* Only render the header if steps are provided */}
      {steps.length > 0 && (
        <FlexDynamic
          className={mobileWizardWrapperClassNames}
          data-testid="wizard-header"
          justifyContent="space-between"
          alignItems="center"
          alignSelf="center"
          alignContent="center"
          wrap="nowrap"
          mdProps={{
            className: wizardWrapperClassNames,
          }}
        >
          {steps.map((step, index) => (
            <React.Fragment key={step.id}>
              <FlexCol alignContent="center" justifyContent="center" alignItems="center">
                <FlexCol alignItems="center">
                  <StepWizardStepNumberContainer
                    alignItems="center"
                    justifyContent="center"
                    active={step.id === currentStep}
                    completed={completedSteps.includes(step.id)}
                    id={step.id}
                  >
                    {completedSteps.includes(step.id) ? (
                      <Icon size="sm" name="checkmark" color="white" />
                    ) : (
                      <StepNumber
                        alignItems="center"
                        justifyContent="center"
                        wrap="nowrap"
                        data-testid={`${step.id}-number`}
                      >
                        <BodyTextLarge fontWeight="semibold" color={step.id === currentStep ? 'link' : 'statusDefault'}>
                          {index + 1}
                        </BodyTextLarge>
                      </StepNumber>
                    )}
                  </StepWizardStepNumberContainer>
                </FlexCol>

                <StepWizardTitleContainer className="cn_mt-2">
                  <WizardHeaderBodyText
                    fontWeight={step.id === currentStep ? 'bold' : 'regular'}
                    $isActive={completedSteps.includes(step.id) || step.id === currentStep}
                  >
                    {step.title}
                  </WizardHeaderBodyText>
                </StepWizardTitleContainer>
              </FlexCol>
              {index !== steps.length - 1 && (
                <Line
                  color={completedSteps.includes(step.id) ? 'link' : 'default'}
                  $isActive={step.id === currentStep}
                />
              )}
            </React.Fragment>
          ))}
        </FlexDynamic>
      )}

      {Component && <Component {...wizardContext} />}
    </WizardWrapper>
  );
};
