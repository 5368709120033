import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { TooltipChildren } from 'reactstrap/es/Tooltip';

import IconBase from '@minecraft.icon';
import UncontrolledTooltip from '@minecraft.uncontrolled-tooltip';

const StyledLink = styled(Link)`
  cursor: pointer;
  font-size: inherit;
`;

const IconNameMap = {
  location: 'spicerack-location',
  reel: 'spicerack-reel',
  resume: 'spicerack-resume',
};

type IconProps = {
  isActive?: boolean;
  link?: string;
  name: keyof typeof IconNameMap;
  size?: React.CSSProperties['lineHeight'];
  'aria-label'?: string;
  tooltip?: TooltipChildren;
};

const Icon = ({ isActive, link, name, size = '0.875', tooltip, 'aria-label': label, ...props }: IconProps) => {
  const theme = useTheme();
  const { active, inactive } = theme.designSystem.iconColors;
  const iconName = IconNameMap[name];

  return (
    <span id={props['id']} data-testid={props['data-testid']}>
      {link ? (
        <StyledLink
          data-testid={isActive ? 'active' : 'inactive'}
          role="link"
          target="_blank"
          to={link}
          aria-label={label ?? iconName}
        >
          <IconBase height={size} width={size} name={iconName} color={isActive ? active : inactive} />
        </StyledLink>
      ) : (
        <IconBase height={size} width={size} name={iconName} color={isActive ? active : inactive} />
      )}
      {isActive && tooltip && props['id'] && (
        <UncontrolledTooltip placement="top" target={props['id']}>
          {tooltip}
        </UncontrolledTooltip>
      )}
    </span>
  );
};

export default (props: IconProps | { loading: true; size: IconProps['size'] }) => {
  return 'loading' in props ? (
    <Skeleton circle height={props.size ?? '0.875rem'} width={props.size ?? '0.875rem'} />
  ) : (
    <Icon {...props} />
  );
};
