import React, { useContext, FC } from 'react';
import Select from '@minecraft.select';
import { WindowWidthContext } from '@minecraft.utils';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';
import { BodyText } from '@minecraft.atoms';
import Skeleton from 'react-loading-skeleton';
import PaginationContext from '../PaginationContext';
import { handlePageLimitChange, DropdownHeightStyle } from '../PaginationCommon';

/** Styled Components */
const StyledSelect = styled(Select)<any>`
  ${DropdownHeightStyle};
`;

/** Types */
export type Props = {
  className?: string;
  onlyText?: boolean;
  showResponsiveSelect?: boolean;
  loading?: boolean;
};

/** Main Component */
const ResultText: FC<Props> = ({ className, onlyText, loading, showResponsiveSelect = true }) => {
  const { totalRecords, pageLimit, currentPage, defaultResultSizeOptions, onPageChanged } =
    useContext(PaginationContext);
  const { t } = useTranslation();

  const { lessSm } = WindowWidthContext.useWidthData();
  const normalizedTotal = typeof totalRecords !== 'number' ? 0 : totalRecords;
  const normalizedPageLimit = typeof pageLimit !== 'number' ? normalizedTotal : pageLimit;
  const normalizedCurrentPage = typeof currentPage !== 'number' ? 1 : currentPage;

  const to = Math.min(normalizedPageLimit * normalizedCurrentPage, normalizedTotal);
  const from = to === 0 ? to : (normalizedCurrentPage - 1) * normalizedPageLimit + 1;

  const selectOptions = defaultResultSizeOptions.map((option) => ({
    value: option,
    label: t('common:filter.perPageOption', { option, totalRecords: normalizedTotal }),
  }));

  if (loading) return <Skeleton containerTestId="result-text-loader" width="10rem" height="1rem" />;

  if (showResponsiveSelect && lessSm && !onlyText) {
    return (
      <Select
        data-qa-id="pagination-page-size-list"
        wrapperProps={{ className: 'cn_w-full' }}
        className={className}
        value={pageLimit}
        options={selectOptions}
        size="large"
        onChange={(e): void => handlePageLimitChange(e, onPageChanged)}
        useFullWidth
      />
    );
  }

  return (
    <BodyText
      fontWeight="bold"
      qaId="pagination-result-details"
      testId="pagination-result-details"
      className={className}
    >
      {t('common:pagination.showXOfYResults', { from, to, total: normalizedTotal })}
    </BodyText>
  );
};

export default ResultText;
