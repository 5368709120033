import React, { FC } from 'react';
import { useUserContext } from '@blocs.auth';
import { Trans } from '@blocs.i18n';
import { gqlCountryEnum as countries } from '@minecraft.graphql-types';
import { StyledAuthorizeMessage, StyledExternalLink } from './styles';

const Link: FC = ({ children }) => {
  const { myAccount } = useUserContext();
  const policy =
    myAccount?.country?.code === countries.AU
      ? 'https://corp.castingnetworks.com/au/terms-of-use/#18'
      : 'https://corp.castingnetworks.com/terms-of-use';

  return (
    <StyledExternalLink target="_blank" href={policy}>
      {children}
    </StyledExternalLink>
  );
};

const AuthorizeMessage = () => {
  return (
    <StyledAuthorizeMessage>
      <Trans i18nKey="common:membership.authorizeMessage" components={[<Link />]} />
    </StyledAuthorizeMessage>
  );
};

export default AuthorizeMessage;
