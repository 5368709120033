import React, { Component, FC, ReactNode, useState } from 'react';
import {
  BasicContainer,
  BodyText,
  DividerWithLabel,
  FlexCol,
  FlexRow,
  H2Text,
  Loader,
  PrimaryButton,
} from '@minecraft.atoms';
import Skeleton from 'react-loading-skeleton';
import {
  CardNumberElement,
  Elements,
  RecurlyProvider,
  CardCvvElement,
  CardMonthElement,
  CardYearElement,
  ThreeDSecureAction,
} from '@recurly/react-recurly';
import { useExternalScript } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';
import styled from 'styled-components';
import { getTypographyStyles } from 'minecraft/src/atoms/Typography/utils';
import { Field, Form, Formik, useFormikContext } from 'formik';
import FormikCheckbox from '@minecraft.formik-checkbox';
import { TokenPayload } from '@recurly/recurly-js';
import Icon from '@minecraft.icon';
import ErrorMessage from '@minecraft.error-message';
import { useTranslation } from '@blocs.i18n';
import { captureExceptionEvent } from '@blocs.sentry';
import { ApplePayButton } from '../../ApplePay';
import { UseBilling } from '../useBilling';
import { WalletLoader } from './WalletLoader';
import { PaywallLocation } from '../enums/PaywallLocation';
import { useAddPaymentMethod } from './useAddPaymentMethods';
import { PAYMENT_METHOD_TYPE } from '../enums/PaymentMethods';
import { AddPaymentMethodProps, RecurlyFormType } from './types';

export const StyledInput = styled(Field)<{ $error: boolean }>`
  background: ${({ theme }) => theme.designSystem.bgColors.card};
  border: 1px solid
    ${({ theme, $error }) => ($error ? theme.designSystem.borderColors.error : theme.designSystem.borderColors.default)};
  font-size: ${({ theme }) => getTypographyStyles({ theme, fontSize: 'bodyRegular' }).fontSize};
  line-height: 1;
  outline: none;

  &:active {
    border-color: ${({ theme }) => theme.designSystem.borderColors.select};
  }

  &:focus,
  &:focus-visible {
    outline: 4px solid ${({ theme }) => theme.designSystem.borderColors.inputFocus};
  }
`;

export const StyledThreeDWrapper = styled.div`
  height: 400px;
  margin: auto;

  & > div {
    height: 100%;
  }
`;

export const AddPaymentSkeleton = () => {
  return (
    <FlexCol gap="6" className="cn_w-full">
      <FlexRow gap="4" className="cn_w-full">
        <Skeleton containerClassName="cn_w-full" height="2rem" />
        <Skeleton containerClassName="cn_w-full" height="2rem" />
      </FlexRow>
      <FlexCol gap="2" className="cn_w-full">
        <Skeleton containerClassName="cn_w-full" height="2rem" />
        <Skeleton containerClassName="cn_w-full" height="2rem" />
        <Skeleton containerClassName="cn_w-full" height="2rem" />
        <FlexRow gap="4" wrap="nowrap" className="cn_w-full">
          <Skeleton containerClassName="cn_w-full" height="2rem" />
          <Skeleton containerClassName="cn_w-full" height="2rem" />
        </FlexRow>
        <FlexRow gap="4" wrap="nowrap" alignItems="flex-end" className="cn_w-full">
          <Skeleton containerClassName="cn_w-full" height="2rem" />
          <Skeleton containerClassName="cn_w-full" height="2rem" />
        </FlexRow>
      </FlexCol>
      <Skeleton containerClassName="cn_w-full" height="3rem" />
    </FlexCol>
  );
};

export type InputProps = {
  type: string;
  name: string;
  label: string;
  hideLabel?: boolean;
  required?: boolean;
  'data-recurly'?: string;
  dataQaId?: string;
};
export const Input: FC<InputProps> = ({
  name,
  type,
  label,
  hideLabel,
  required,
  'data-recurly': dataRecurly,
  dataQaId,
}) => {
  const { errors, touched } = useFormikContext();

  return (
    <FlexCol data-qa-id={dataQaId} gap="1" className="cn_w-full">
      {!hideLabel && (
        <BodyText as="label" fontWeight="semibold" htmlFor={name}>
          {label}
          {required && ' *'}
        </BodyText>
      )}
      <StyledInput
        className="cn_w-full cn_atom_p-2 cn_atom_rounded-sm"
        $error={!!errors[name] && touched[name]}
        type={type}
        aria-label={hideLabel && label}
        id={name}
        name={name}
        data-recurly={dataRecurly}
      />
      {errors[name] && touched[name] ? <BodyText color="error">{errors[name]}</BodyText> : null}
    </FlexCol>
  );
};

const StyledRecurly = styled(CardNumberElement)<{ $error?: boolean; $focused?: boolean }>`
  // We have to style the sub-div due to Recurlys iFrame wrapping around the element
  & > div {
    height: 2.5rem;
    padding: 0 0.5rem;
    border: 1px solid
      ${({ theme, $error }) =>
        $error ? theme.designSystem.borderColors.error : theme.designSystem.borderColors.default};
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.designSystem.bgColors.card};

    outline: ${({ theme, $focused }) =>
      $focused ? `4px solid ${theme.designSystem.borderColors.inputFocus}` : undefined};
  }
`;

type StyledRecurlyInput = {
  label: string;
  name: string;
  required?: boolean;
  RecurlyInput: ReactNode;
  EndSlotComponent?: ReactNode;
  inputProps?: { className?: string; dataQaId?: string };
};

export const StyledRecurlyInput: FC<StyledRecurlyInput> = ({
  label,
  name,
  required,
  // The Recurly Element to use (CC, CVV, month, year are all sensitive fields that need an iFrame wrapped)
  RecurlyInput,
  EndSlotComponent,
  inputProps,
}) => {
  const [focused, setFocused] = useState(false);
  const { errors } = useFormikContext();

  const dataQaId = inputProps?.dataQaId;

  return (
    <FlexCol data-qa-id={dataQaId} gap="1" className="cn_w-full">
      <BodyText as="label" fontWeight="semibold" htmlFor={name}>
        {label}
        {required && ' *'}
      </BodyText>
      <FlexRow alignItems="flex-end" wrap="nowrap" className="cn_w-full">
        <StyledRecurly
          className={inputProps?.className}
          as={RecurlyInput}
          $focused={focused}
          $error={!!errors[name]}
          id={name}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {EndSlotComponent && <div className="cn_atom_pl-2">{EndSlotComponent}</div>}
      </FlexRow>
      {errors[name] ? <BodyText color="error">{errors[name]}</BodyText> : null}
    </FlexCol>
  );
};

const RecurlyForm: FC<RecurlyFormType & { paywallLocation?: PaywallLocation }> = ({
  addPaymentError,
  allowCancel,
  onCancel,
  onAddPaymentOption,
  addPaymentLoading,
  currencyCode,
  countryCode,
  paywallLocation,
}) => {
  const { t } = useTranslation();
  const { requestRecurlyTokenAndValidate, showApplePayButton, onClickApplePay, formRef, formikRef } =
    useAddPaymentMethod({
      onAddPaymentOption,
      currencyCode,
      countryCode,
    });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        // eslint-disable-next-line camelcase
        first_name: '',
        // eslint-disable-next-line camelcase
        last_name: '',
        // eslint-disable-next-line camelcase
        postal_code: '',
        paymentPriority: [],
      }}
      onSubmit={(formData, formikHelpers) => requestRecurlyTokenAndValidate(formData, formikHelpers)}
    >
      <Form ref={formRef}>
        <FlexCol gap="6">
          <FlexRow gap="2">
            <BasicContainer borderRadius="sm" borderPlacement="all" padding={2}>
              <FormikCheckbox
                noMargin
                className="cn_m-0"
                label={t('common:billing.card.primary.set')}
                value={PAYMENT_METHOD_TYPE.PRIMARY}
                groupName="paymentPriority"
              />
            </BasicContainer>
            <BasicContainer borderRadius="sm" borderPlacement="all" padding={2}>
              <FormikCheckbox
                noMargin
                className="cn_m-0"
                label={t('common:billing.card.backup.set')}
                value={PAYMENT_METHOD_TYPE.BACKUP}
                groupName="paymentPriority"
              />
            </BasicContainer>
          </FlexRow>
          <FlexCol gap="2">
            {showApplePayButton && (
              <>
                <ApplePayButton onClick={onClickApplePay} />
                <DividerWithLabel label={t('talent:billing.card.or')} />
              </>
            )}
            <Input
              type="text"
              name="first_name"
              label={t('common:form.label.firstName')}
              required
              data-recurly="first_name"
              dataQaId="cc-firstname-container"
            />
            <Input
              type="text"
              name="last_name"
              label={t('common:form.label.lastName')}
              required
              data-recurly="last_name"
              dataQaId="cc-lastname-container"
            />
            <FlexRow data-qa-id="cc-number-container" gap="4" wrap="nowrap" alignItems="center">
              <StyledRecurlyInput
                name="number"
                label={t('common:form.label.cardNumber')}
                required
                RecurlyInput={CardNumberElement}
                EndSlotComponent={
                  <FlexRow gap="2" wrap="nowrap">
                    <Icon name="master-card" />
                    <Icon name="visa" />
                    <Icon name="amex" />
                  </FlexRow>
                }
              />
            </FlexRow>
            <FlexRow data-qa-id="cc-expiration-container" gap="4" wrap="nowrap">
              <StyledRecurlyInput
                inputProps={{ dataQaId: 'cc-month-expiration-container' }}
                name="month"
                label={t('common:form.label.month')}
                required
                RecurlyInput={CardMonthElement}
              />
              <StyledRecurlyInput
                inputProps={{ dataQaId: 'cc-year-expiration-container' }}
                name="year"
                label={t('common:form.label.year')}
                required
                RecurlyInput={CardYearElement}
              />
            </FlexRow>
            <FlexRow gap="4" wrap="nowrap" alignItems="center">
              <StyledRecurlyInput
                name="cvv"
                label={t('common:form.label.cvv')}
                inputProps={{ className: 'cn_w-12', dataQaId: 'cc-cvv-container' }}
                required
                RecurlyInput={CardCvvElement}
                EndSlotComponent={
                  <FlexRow wrap="nowrap">
                    <Icon name="amex-cvv" style={{ width: '3.5rem', height: '2rem' }} />
                    <Icon name="visa-mc-cvv" style={{ width: '3.5rem', height: '2rem' }} />
                  </FlexRow>
                }
              />
              <Input
                type="text"
                name="postal_code"
                label={t('common:form.label.zip')}
                required
                data-recurly="postal_code"
                dataQaId="cc-zipcode-container"
              />
            </FlexRow>
          </FlexCol>
          <FlexCol className="cn_w-full" justifyContent="flex-end" gap="2" alignItems="flex-end">
            {addPaymentError && <BodyText color="error">{addPaymentError}</BodyText>}
            <FlexRow className="cn_w-full" justifyContent="flex-end" gap="2">
              {allowCancel && (
                <PrimaryButton
                  type="button"
                  isSmall
                  onClick={onCancel}
                  variant="secondary"
                  label={t('common:button.cancel')}
                  disabled={addPaymentLoading}
                />
              )}
              <PrimaryButton
                type="submit"
                isSmall
                label={t('common:paywall.addPaymentMethod.title')}
                data-unique-id={`add-payment-method-${paywallLocation}`}
                disabled={addPaymentLoading}
              />
            </FlexRow>
          </FlexCol>
        </FlexCol>
      </Form>
    </Formik>
  );
};

type ThreeDSecureFormProps = {
  threeDActionToken: UseBilling['threeDActionToken'];
  onAddPaymentOption: UseBilling['onAddPaymentOption'];
  onError: UseBilling['onThreeDActionError'];
  addPaymentLoading: UseBilling['addPaymentLoading'];
};

// This component needs to be a class to catch any render exceptions that may happen
// in the ThreeDSecureAction component which could suffer from cross domain issues and other unknowns
export class ThreeDSecureForm extends Component<ThreeDSecureFormProps, { renderedError?: boolean }> {
  static getDerivedStateFromError(error) {
    console.error('Error rendering ThreeDSecureForm', error);
    captureExceptionEvent(error);

    return { renderedError: true };
  }

  constructor(props: ThreeDSecureFormProps) {
    super(props);
    this.state = { renderedError: false };
  }

  componentDidCatch(error) {
    captureExceptionEvent(error);
    console.error('Error rendering ThreeDSecureForm', error);
  }

  render() {
    const { renderedError } = this.state;
    const { threeDActionToken, onAddPaymentOption, onError, addPaymentLoading } = this.props;

    if ((!threeDActionToken && !renderedError) || addPaymentLoading) return <WalletLoader />;

    return (
      <>
        {renderedError ? (
          <ErrorMessage />
        ) : (
          <StyledThreeDWrapper>
            <ThreeDSecureAction
              actionTokenId={threeDActionToken}
              onToken={(token: TokenPayload) => onAddPaymentOption({ threeDSecureActionResultTokenId: token.id })}
              onError={onError}
            />
          </StyledThreeDWrapper>
        )}
      </>
    );
  }
}

export const AddPaymentMethod: FC<AddPaymentMethodProps> = ({
  onCancel,
  addPaymentError,
  addPaymentLoading,
  allowCancel,
  onAddPaymentOption,
  showThreeDSecurityForm,
  threeDActionToken,
  onThreeDActionError,
  currencyCode,
  countryCode,
  paywallLocation,
}) => {
  const { t } = useTranslation();
  const config = getEnvironment();
  const billingPlatformPublicKey = config['RECURLY_PUBLIC_KEY'];
  const [externalScriptLoaded, loadBillingScriptError] = useExternalScript('https://js.recurly.com/v4/recurly.js');

  return (
    <BasicContainer
      borderRadius="md"
      className="cn_w-full"
      padding={4}
      borderPlacement="all"
      data-testid="add-payment-container"
    >
      <FlexCol gap="4">
        <H2Text>{t('common:paywall.addPaymentMethod.title')}</H2Text>
        <Loader
          loading={!externalScriptLoaded && !loadBillingScriptError}
          error={loadBillingScriptError && t('common:globalErrors.generic')}
          LoaderComponent={<AddPaymentSkeleton />}
        >
          <RecurlyProvider publicKey={billingPlatformPublicKey} required={['cvv', 'postal_code']}>
            <Elements>
              {showThreeDSecurityForm ? (
                <ThreeDSecureForm
                  onAddPaymentOption={onAddPaymentOption}
                  threeDActionToken={threeDActionToken}
                  onError={onThreeDActionError}
                  addPaymentLoading={addPaymentLoading}
                />
              ) : (
                <RecurlyForm
                  onCancel={onCancel}
                  allowCancel={allowCancel}
                  addPaymentError={addPaymentError}
                  onAddPaymentOption={onAddPaymentOption}
                  addPaymentLoading={addPaymentLoading}
                  currencyCode={currencyCode}
                  countryCode={countryCode}
                  paywallLocation={paywallLocation}
                />
              )}
            </Elements>
          </RecurlyProvider>
        </Loader>
      </FlexCol>
    </BasicContainer>
  );
};
