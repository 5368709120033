/* eslint-disable camelcase */
import TagManager from 'react-gtm-module';
import { getEnvironment } from '@minecraft.environment';
import { getAccessToken, parseJwt, PRODUCTION } from '@blocs.auth';

type PublishEvent = {
  event: string;
} & Record<string, unknown>;

export function publishEvent(event: PublishEvent) {
  const accessToken = getAccessToken();
  const jwt = parseJwt(accessToken);

  const { id, email, artistId, role } = jwt || {};

  const gtmEvent = {
    ...event,
    user_id: id ?? event.user_id,
    user_type: role?.code ?? event.user_type,
    locale: navigator.language,
    email: email ?? event.email,
    artist_id: artistId ?? event.artist_id,
  };

  if (getEnvironment()?.ENV !== PRODUCTION && !process.env.TEST) {
    // eslint-disable-next-line no-console
    console.log('GTM event: ', gtmEvent);
  }

  // clears any previous ecommerce data to avoid interference between events
  TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  TagManager.dataLayer({
    dataLayer: gtmEvent,
  });
}
