import styled from 'styled-components';
import { DropdownItem as DropdownItemBase } from 'reactstrap';

const DropdownItem = styled(DropdownItemBase)`
  &:active,
  &:focus {
    background-color: ${({ theme }): string => theme.designSystem.bgColors.comboboxToken};
    border: none;
    outline: none;
  }

  &:disabled {
    color: ${({ theme }): string => theme.designSystem.textColors.disabled} !important;
  }
`;

export default DropdownItem;
