import { MediaPurchase, TemperatureValues } from '../enums/plans';
import { PlanType } from '../usePlans';

export const alacartePurchaseOption = {
  billingCode: MediaPurchase.alacarte,
  term: { code: 'media-purchase' },
  name: 'A-la Carte',
  title: 'A-la Carte',
  heroImage: 'purchase-media',
  iconName: 'check-circle',
  temperature: TemperatureValues.vanilla,
} as PlanType;
