import React from 'react';
import styled from 'styled-components';

import { DesignSystemBgColor, DesignSystemSectionBreakColor } from '@minecraft.themes';
import { WindowWidthContext } from '@minecraft.utils';
import { typeography as DesignSystemTypography } from '@minecraft.atoms';

const StyledCardBase = styled.div<{
  backgroundColor?: DesignSystemBgColor;
  borderColor?: DesignSystemSectionBreakColor;
  isMobile?: boolean;
  // Using a transient prop will prevent `loading` from being passed to the element
  $loading?: boolean;
}>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor in theme.designSystem.bgColors ? theme.designSystem.bgColors[backgroundColor] : undefined};
  border-radius: 0.375rem;
  border: ${({ borderColor, theme }) =>
    borderColor in theme.designSystem.sectionBreak
      ? `1px solid ${theme.designSystem.sectionBreak[borderColor]}`
      : undefined};
  box-shadow: ${({ $loading }) =>
    $loading
      ? undefined
      : '0.125rem 0.25rem 0.5rem rgb(0 0 0 / 0.125), 0.125rem 0.25rem 0.5rem rgb(0 0 0 / 0.03125) inset'};
  display: inline-flex;
  flex-direction: ${({ isMobile, $loading }) => (isMobile || $loading ? 'column' : 'row')};
  font-size: ${DesignSystemTypography.fontSize.bodySmall};
  line-height: ${DesignSystemTypography.lineHeight.expanded};
  overflow: hidden;
`;

type BaseProps = React.ComponentProps<typeof StyledCardBase> & {
  // Add additional properties here
  backgroundColor?: DesignSystemBgColor;
  borderColor?: DesignSystemSectionBreakColor;
};

const Base: React.FC<BaseProps> = ({ children, backgroundColor = 'card', borderColor = 'divider', ...props }) => {
  const isMobile = WindowWidthContext.useIsMobile();

  return (
    <StyledCardBase {...props} backgroundColor={backgroundColor} borderColor={borderColor} isMobile={isMobile}>
      {children}
    </StyledCardBase>
  );
};

const LoadingWrapper: React.FC<BaseProps | { loading: true }> = ({ children, ...props }) => {
  const isMobile = WindowWidthContext.useIsMobile();

  if ('loading' in props) {
    return (
      <StyledCardBase isMobile={isMobile} $loading>
        {children}
      </StyledCardBase>
    );
  }

  return <Base {...props}>{children}</Base>;
};

export default LoadingWrapper;
