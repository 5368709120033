import React, { FC } from 'react';
import Icon from '@minecraft.icon';
import { BasicContainer, FlexCol } from '@minecraft.atoms';
import { TemperatureValues } from '../enums/plans';

const loadingItems: { icon: string; temperature: TemperatureValues; id: string }[] = [
  {
    icon: 'paywall-plan-loading',
    temperature: TemperatureValues.cool,
    id: 'plan',
  },
];

export const VerticalPlanLoader: FC = React.memo(() => {
  return (
    <FlexCol gap="2" wrap="nowrap" justifyContent="space-around" w="full" maxW="full" minHeight={8}>
      {loadingItems.map(({ icon, temperature, id }) => (
        <BasicContainer
          borderColor={temperature ? `${temperature}Paywall` : 'vanillaPaywall'}
          borderPlacement="all"
          borderStyle="solid"
          borderRadius="lg"
          borderSize={0.1}
          w="full"
          className="cn_h-full"
          key={id}
        >
          <Icon name={icon} className="cn_w-full cn_max-w-full cn_h-full" />
        </BasicContainer>
      ))}
    </FlexCol>
  );
});
