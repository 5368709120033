import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@minecraft.icon';
import { TooltipText } from './TooltipText';
import { IconSize, iconSizes } from '../components/Icon/Icon';
import UncontrolledTooltip from '../components/UncontrolledTooltip';

// We will use css files in the future
const StyledIcon = styled(Icon)`
  width: ${(props) => (props.size ? iconSizes[props.size] : '1rem')};
  height: ${(props) => (props.size ? iconSizes[props.size] : '1rem')};
  color: ${(props) => props.theme.colors.accent.orange};
  cursor: default;
`;

type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

export type IconWithTooltipProps = {
  /**
   * The icon name, this matches the file names in the /Icon/assets directory
   */
  iconName: string;
  className?: string;
  /**
   * What to display in the tooltip, each (in array) will be displayed on a new line
   */
  tooltipContent: React.ReactNode | string[];
  onHover?: () => void;
  /**
   * @deprecated
   * This will be replaced when we move to React 18
   */
  id: string;
  /**
   * The label to describe the icon
   */
  'aria-label': string;
  qaId?: string;

  /**
   * Placement of the tooltip
   */
  placement?: Placement;

  /**
   * Icon size
   */
  iconSize?: IconSize;
};

/**
 * An icon that displays text when hovered
 * @example
 * <IconWithTooltip iconName="folder-warning" tooltipContent="This is a folder" />
 * <IconWithTooltip iconName="folder-warning" tooltipContent={['This is a folder', 'This is a second line']} />
 */
export const IconWithTooltip: FC<IconWithTooltipProps> = ({
  iconName,
  iconSize,
  'aria-label': ariaLabel,
  className = '',
  tooltipContent,
  onHover,
  id,
  qaId,
  placement = 'top-start',
}) => {
  const content = Array.isArray(tooltipContent) ? tooltipContent : [tooltipContent];

  return (
    <span data-testid="icon-with-tooltip" className={`ts_icon-with-tooltip ${className}`} data-qa-id={`${qaId}-icon`}>
      <span id={id} onMouseEnter={() => onHover?.()}>
        <StyledIcon name={iconName} role="img" aria-label={ariaLabel} size={iconSize} />
      </span>
      <UncontrolledTooltip
        placement={placement}
        target={id}
        // This is a fix to allow for the use of tooltips inside overflow assigned containers
        // see https://github.com/reactstrap/reactstrap/issues/1118
        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
        data-testid={`${id}-tooltipelement`}
      >
        {content.map((contentItem, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TooltipText key={`${index}-contentItem`}>{contentItem}</TooltipText>
        ))}
      </UncontrolledTooltip>
    </span>
  );
};

IconWithTooltip.displayName = 'IconWithTooltip';
