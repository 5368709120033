import React, { FC, createContext, useContext, useCallback, useMemo, useState } from 'react';
import { useUserContext } from '@blocs.auth';
import { captureHeapEvent, HEAP_EVENT_NAMES } from '@blocs.heap';
import { captureExceptionEvent } from '@blocs.sentry';
import { useDeferredPromise } from '@minecraft.utils';
import { PlanType, usePlans } from './usePlans';
import { useBilling as useBillingHook } from './useBilling';
import { usePayment } from './usePayment';
import { PaywallLocation } from './enums/PaywallLocation';

export enum PurchaseResultType {
  Success = 'SUCCESS',
  Canceled = 'CANCEL',
  Error = 'ERROR',
}

export type ResolvedPurchase = {
  type: PurchaseResultType;
  completedSubscriptionPurchase?: ReturnType<typeof usePayment>['completedPurchase'];
  completedPurchaseError?: ReturnType<typeof usePayment>['completePurchaseError'];
};

export type ShowBillingPopupParams = {
  location?: PaywallLocation;
  mediaIds?: number[];
};

export type BillingPopupContextType = {
  visible: boolean;
  location: PaywallLocation;
  show: (data: ShowBillingPopupParams) => { getPurchaseResult: () => Promise<ResolvedPurchase> };
  close: () => void;
  subscription: ReturnType<typeof useUserContext>['subscription'];
  countryCode: ReturnType<typeof useUserContext>['myAccount']['country']['code'];
  plansError: ReturnType<typeof usePlans>['error'];
  plansLoading: ReturnType<typeof usePlans>['loading'];
  walletLoading: ReturnType<typeof useBillingHook>['loading'];
  setSelectedPlan: (plan: PlanType) => void;
  selectedPlan: PlanType;
  mediaIds?: number[];
} & ReturnType<typeof useBillingHook> &
  ReturnType<typeof usePayment> &
  ReturnType<typeof usePlans>;

export interface UseMediaBankPaywallProps {
  location?: PaywallLocation;
}

const EMPTY_CTX_VALUES = (): BillingPopupContextType => ({
  addPaymentError: null,
  addPaymentLoading: false,
  applyCoupon: null,
  applyCouponError: null,
  close: null,
  completedPurchase: null,
  completePurchase: null,
  completePurchaseError: null,
  completePurchaseLoading: false,
  countryCode: null,
  couponLoading: false,
  currencyCode: null,
  currentPlan: null,
  currentPurchase: null,
  error: null,
  isAnnualRenewalDue: false,
  isReactivate: false,
  isTrialEligible: false,
  isWithinTrialPeriod: false,
  loading: false,
  location: null,
  onAddPaymentOption: null,
  onThreeDActionError: null,
  plans: null,
  plansError: null,
  plansLoading: false,
  removeCoupon: null,
  selectedPaymentOption: null,
  selectedPlan: null,
  setCompletedPurchase: null,
  setCompletePurchaseError: null,
  setSelectedPaymentOption: null,
  setSelectedPlan: null,
  setShowAddPaymentMethod: null,
  show: null,
  showAddPaymentMethod: false,
  showThreeDSecurityForm: false,
  subscription: null,
  threeDActionToken: null,
  visible: false,
  wallet: null,
  walletLoading: false,
  mediaIds: null,
});

const BillingPopupContext = createContext<BillingPopupContextType>(EMPTY_CTX_VALUES());

export const useBillingPopup = () => useContext(BillingPopupContext);

export type BillingPopupProviderState = {
  visible: boolean;
  /** pass location as a prop when rendering the paywall as a full-page (signup flow)
   * otherwise location should be passed as a param when calling show()
   */
  location?: PaywallLocation;
  // mediaIds are used to purchase media in paywall
  mediaIds?: number[];
};

const DEFAULT_POPUP_STATE: BillingPopupProviderState = {
  visible: false,
  location: null,
  mediaIds: null,
};

export const BillingPopupProvider: FC<{ location?: PaywallLocation }> = ({
  children,
  location: locationProp = null,
}) => {
  const [state, setState] = useState<BillingPopupProviderState>({ ...DEFAULT_POPUP_STATE });
  const resolvedLocation = useMemo(() => locationProp || state.location, [locationProp, state.location]);

  const [selectedPlan, setSelectedPlan] = useState<PlanType | null>(null);
  const { myAccount, subscription, artistId } = useUserContext();
  const countryCode = myAccount?.country?.code ?? 'US';

  const {
    plans,
    error: plansError,
    loading: plansLoading,
    isAnnualRenewalDue,
    isTrialEligible,
    isWithinTrialPeriod,
    isReactivate,
    currentPlan,
  } = usePlans({ subscription, paywallLocation: resolvedLocation, artistId });

  const {
    wallet,
    selectedPaymentOption,
    setSelectedPaymentOption,
    loading: walletLoading,
    onAddPaymentOption,
    showAddPaymentMethod,
    setShowAddPaymentMethod,
    addPaymentLoading,
    showThreeDSecurityForm,
    threeDActionToken,
    onThreeDActionError,
    addPaymentError,
  } = useBillingHook({ currentActiveSubscription: subscription });

  const {
    currentPurchase,
    currencyCode,
    applyCoupon,
    removeCoupon,
    completePurchase,
    completedPurchase,
    applyCouponError,
    couponLoading,
    completePurchaseLoading,
    completePurchaseError,
    setCompletedPurchase,
    setCompletePurchaseError,
  } = usePayment({
    selectedPlan,
    selectedPaymentOption,
    paywallLocation: resolvedLocation,
    isAnnualRenewalDue,
  });

  // The deferred promise allows us to hold onto a promise until the paywall is closed
  // this allows the code that opens the paywall to await on the result of the paywall
  // and make a decision based on the result
  const { deferRef, defer } = useDeferredPromise<ResolvedPurchase>();

  // when the billing popup is opened, we return a function to the caller (getPurchaseResult)
  // if they invoke that function, we create a promise, store it in a ref and return it
  // the caller can then await on that promise to get the result of the billing popup without
  // needing a useEffect or other mechanism to listen for the result and try to infer what happened
  const showBillingPopup = useCallback(
    (data: ShowBillingPopupParams) => {
      const { location, mediaIds } = data || {};
      setState({ visible: true, location, mediaIds });
      try {
        if (data?.location) {
          captureHeapEvent({
            name: HEAP_EVENT_NAMES.DISPLAY_UPGRADE_MODAL,
            data: {
              location,
            },
          });
        }
      } catch (e) {
        captureExceptionEvent(e, {
          section: 'Talent: heap "Display Upgrade Modal" event',
        });
      }

      return {
        getPurchaseResult: () => defer().promise,
      };
    },
    [defer]
  );

  // when the billing popup is closed, if the deferred promise exists, we resolve it with context of what happened
  // if the user closes the paywall without purchasing we resolve with a canceled status
  // if the user completes the purchase we resolve with a success status and the details of the purchase
  const closeBillingPopup = useCallback(() => {
    setState({ ...DEFAULT_POPUP_STATE });

    if (deferRef?.current) {
      let type = PurchaseResultType.Canceled;

      if (completePurchaseError) {
        type = PurchaseResultType.Error;
      }

      if (completedPurchase) {
        type = PurchaseResultType.Success;
      }

      const purchase: ResolvedPurchase = {
        completedPurchaseError: completePurchaseError,
        completedSubscriptionPurchase: completedPurchase,
        type,
      };
      deferRef.current?.resolve(purchase);
      deferRef.current = null;
    }
  }, [deferRef, completePurchaseError, completedPurchase]);

  const billingContext = useMemo(() => {
    return {
      // billing pop up values
      visible: state.visible,
      mediaIds: state.mediaIds,
      location: resolvedLocation,
      show: showBillingPopup,
      close: closeBillingPopup,
      subscription,
      countryCode,
      // usePlans
      plans,
      isTrialEligible,
      isWithinTrialPeriod,
      isReactivate,
      plansLoading,
      plansError,
      // useBilling
      wallet,
      walletLoading,
      selectedPaymentOption,
      setSelectedPaymentOption,
      onAddPaymentOption,
      showAddPaymentMethod,
      addPaymentLoading,
      setShowAddPaymentMethod,
      showThreeDSecurityForm,
      threeDActionToken,
      onThreeDActionError,
      addPaymentError,
      // usePayment
      currentPurchase,
      currencyCode,
      applyCoupon,
      removeCoupon,
      completePurchase,
      completedPurchase,
      applyCouponError,
      couponLoading,
      completePurchaseLoading,
      completePurchaseError,
      setCompletedPurchase,
      setCompletePurchaseError,
      // aggregate attributes:
      setSelectedPlan,
      selectedPlan,
      // additional properties
      error: plansError,
      loading: plansLoading,
      isAnnualRenewalDue,
      currentPlan,
    };
  }, [
    addPaymentError,
    addPaymentLoading,
    applyCoupon,
    applyCouponError,
    closeBillingPopup,
    completedPurchase,
    completePurchase,
    completePurchaseError,
    completePurchaseLoading,
    countryCode,
    couponLoading,
    currencyCode,
    currentPlan,
    currentPurchase,
    isAnnualRenewalDue,
    isReactivate,
    isTrialEligible,
    isWithinTrialPeriod,
    onAddPaymentOption,
    onThreeDActionError,
    plans,
    plansError,
    plansLoading,
    removeCoupon,
    resolvedLocation,
    selectedPaymentOption,
    selectedPlan,
    setCompletedPurchase,
    setCompletePurchaseError,
    setSelectedPaymentOption,
    setShowAddPaymentMethod,
    showAddPaymentMethod,
    showBillingPopup,
    showThreeDSecurityForm,
    state.visible,
    state.mediaIds,
    subscription,
    threeDActionToken,
    wallet,
    walletLoading,
  ]);

  return <BillingPopupContext.Provider value={billingContext}>{children}</BillingPopupContext.Provider>;
};

export const useMediaBankPaywall = (
  { location }: UseMediaBankPaywallProps = { location: PaywallLocation.MEDIA_BANK }
) => {
  const billing = useBillingPopup();

  return useCallback(
    ({ location: cbLocation }: UseMediaBankPaywallProps = {}) =>
      billing.show({
        location: cbLocation || location,
      }),
    [billing, location]
  );
};
