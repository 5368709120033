import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { TabBoxHeader } from './TabBoxHeader';

const TabContent = styled.div<{ $maxWidth: string }>`
  max-width: ${(props) => (props?.$maxWidth ? props?.$maxWidth : '25rem')};
  width: 100%;
  height: 9rem;
  border: 1px solid ${(props) => props.theme.designSystem.borderColors.default};
  border-radius: 0px 0px 4px 4px;
  overflow-y: scroll;
`;

export const TabBox: FC<{ maxWidth?: string; children?: React.ReactElement<{ title: string; testId?: string }>[] }> = ({
  maxWidth,
  children,
}) => {
  const headerNames = children.map((c: React.ReactElement<{ title: string; testId?: string }>) => ({
    name: c.props.title,
    testId: c.props.testId,
  }));
  const [selectedTab, selectTab] = useState(0);

  return (
    <>
      <TabBoxHeader headerNames={headerNames} selectTab={selectTab} selectedTab={selectedTab} />
      <TabContent className="cn_atom_p-4" $maxWidth={maxWidth}>
        {children[selectedTab]}
      </TabContent>
    </>
  );
};
