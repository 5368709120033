import React, { FC } from 'react';
import styled from 'styled-components';
import { Input as ReactstrapInput, InputProps } from 'reactstrap';
import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';

const StyledReactstrapInput = styled(ReactstrapInput)<{ $error: boolean }>`
  background: ${({ theme }) => theme.designSystem.bgColors.card};
  border: 1px solid
    ${({ theme, $error }) => ($error ? theme.designSystem.borderColors.error : theme.designSystem.borderColors.default)};
  outline: none;

  &&:focus,
  &&:focus-visible {
    box-shadow: none;
    border: 1px solid
      ${({ theme, $error }) =>
        $error ? theme.designSystem.borderColors.error : theme.designSystem.borderColors.select};
    outline: 4px solid
      ${({ theme, $error }) =>
        $error ? theme.designSystem.borderColors.inputFocusError : theme.designSystem.borderColors.inputFocus};
  }

  &&:disabled {
    box-shadow: none;
    background: ${({ theme }) => theme.designSystem.bgColors.inputDisabled};
    border: 1px solid ${({ theme }) => theme.designSystem.borderColors.default};
  }
`;

/** Types */
type ReactstrapInputProps = Pick<
  InputProps,
  'bsSize' | 'valid' | 'invalid' | 'innerRef' | 'plaintext' | 'addon' | 'type' | 'multiple'
>;
type HtmlInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur' | 'value' | 'multiple' | 'id'
>;

export type BaseInputProps = ReactstrapInputProps &
  HtmlInputProps & {
    /**
     * callback invoked with the value of the text field
     */
    onChange?: (newValue: string) => void;
    /**
     * value of this input
     */
    value: string;
    'data-testid'?: string;
    'data-qa-id'?: string;
    name: string;
  };

/**
 * A simple, controlled input
 * @name BaseInput
 * @description this is to be used as a base for other input components, do not import this directly into application code
 * it does not include a label, help text, validation or other form field features
 *
 * @example
 * const [value, setSelectedValue] = useState<string>('');
 * <BaseInput value={value} onChange={setSelectedValue} />
 */
export const BaseInput: FC<BaseInputProps> = ({
  onChange,
  'data-testid': testId,
  'data-qa-id': qaId,
  type = 'text',
  value,
  name,
  ...inputProps
}) => {
  const normalizedType = ['text', 'password', 'number', 'search', 'email', 'tel', 'url'].includes(type) ? type : 'text';

  return (
    <StyledReactstrapInput
      {...inputProps}
      id={name}
      name={name}
      data-testid={testId}
      data-qa-id={qaId}
      onChange={(e) => onChange(e.target.value)}
      type={normalizedType}
      value={value === undefined ? '' : value}
      size={undefined}
      bsSize={undefined}
      $error={inputProps?.invalid}
    />
  );
};

export interface WrappedBaseInputProps {
  wrapperProps?: FieldWrapperProps;
  inputProps?: BaseInputProps;
}

/**
 * A simple, controlled input with the standard form field wrapper
 * @name WrappedBaseInput
 * @description this is to be used as a base for other input components, do not import this directly into application code
 * it should be setup with a formik <Field /> around it which maps value, onChange, name, validation, etc into the props
 *
 * @example
 * const [value, setSelectedValue] = useState<string>('');
 * <WrappedBaseInput
 *    inputProps={{ value, onChange: setSelectedValue, type: 'text', name: 'input' }}
 *    wrapperProps={{ label: 'My Input', helpText: 'Please Fill This Out', id: 'input' }}
    />
 */
export const WrappedBaseInput: FC<WrappedBaseInputProps> = ({ wrapperProps = {}, inputProps }) => {
  return (
    <FieldWrapper {...wrapperProps}>
      <BaseInput {...inputProps} />
    </FieldWrapper>
  );
};
