import React from 'react';

import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { BaseballCardProfileFragment } from '@minecraft.graphql-operations';
import { DetailTextLarge } from '@minecraft.atoms';

import Icon from './Icon';

type WorkingLocationsProps = Pick<BaseballCardProfileFragment, 'workingLocations'>;

export const WorkingLocations = ({ workingLocations }: WorkingLocationsProps) => (
  <>
    {workingLocations?.length > 0 && (
      <DetailTextLarge as="div" testId="workinglocations-text">
        <Icon name="location" />
        {workingLocations?.[0]?.name}
      </DetailTextLarge>
    )}
  </>
);

const StyledLoadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.875rem 1fr;
  gap: 0.25rem;
`;

const LoadingWrapper = (props: WorkingLocationsProps | { loading: true }) => {
  return 'loading' in props ? (
    <StyledLoadingWrapper>
      <Skeleton circle />
      <Skeleton />
    </StyledLoadingWrapper>
  ) : (
    <WorkingLocations {...props} />
  );
};

export default LoadingWrapper;
