import { useUserContext } from '@blocs.auth';
import { WindowWidthContext } from '@minecraft.utils';
import { PaywallLocation } from './enums/PaywallLocation';

export const useMobileRegistration = ({ paywallLocation }: { paywallLocation: PaywallLocation }) => {
  const { myAccount, subscription } = useUserContext();
  const artistCount = myAccount?.artists?.length || 0;
  const isMobile = WindowWidthContext.useIsMobile();

  const isMobileRegistration =
    artistCount <= 1 && !subscription && isMobile && paywallLocation === PaywallLocation.SIGNUP;

  return { isMobileRegistration };
};
