enum dateFormat {
  TIME = 'h:mma',
  MONTH_DAY = 'MMM DD',
  FULL_MONTH_DAY_YEAR = 'MMMM DD, YYYY',
  MONTH_DAY_YEAR = 'MMM DD, YYYY',
  MONTH_DAY_TIME = 'MMM DD h:mma',
  MONTH_DAY_TIME_AT = 'MMM DD [at] h:mma',
  MONTH_DAY_YEAR_TIME = 'MMM DD, YYYY h:mma',
  MONTH_DAY_YEAR_TIME_AT = 'MMM DD, YYYY [at] h:mma',
  SHORT_FORMAT = 'MM/DD/YY',
  TIME_UTIL_FORMAT = 'HH:mm:ss',
  DATE_UTIL_FORMAT = 'YYYY-MM-DD',
  UTIL_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss',
  UTIL_FORMAT_TZ = 'YYYY-MM-DD[T]HH:mm:ssZ',
  MONTH_DAY_YEAR_TIME_MERIDIEM_AT = 'MM-DD-YYYY [at] HH:mm:ss A',
  TIME_12_HOUR = 'hh:mm A',
}

export default dateFormat;
