export enum TemperatureValues {
  spicy = 'spicy',
  cool = 'cool',
  hot = 'hot',
  vanilla = 'vanilla',
}

export enum BillingCodes {
  ultimateAnnual = 'ultimate_annual',
  ultimateMonth = 'ultimate_m2m',
  ultimateComped = 'ultimate_comped',
  premiumAnnual = 'premium_annual',
  premiumMonth = 'premium_m2m',
  premiumComped = 'premium_comped',
  essentialMonth = 'essential_m2m',
  essentialAnnual = 'essential_annual',
  essentialComped = 'essential_comped',
  registration = 'registration',
}

export enum PlanCode {
  Ultimate = 'ULTIMATE',
  Premium = 'PREMIUM',
  Essential = 'ESSENTIAL',
  Free = 'FREE',
}

export enum MediaPurchase {
  alacarte = 'a_la_carte',
}
