import React, { Component, ElementType, ReactNode } from 'react';
import PaginationContext from './PaginationContext';
import GridSwitcher, { Props as SwitcherProps } from './GridSwitcher';
import GridResultText, { Props as ResultTextProps } from './GridResultText';

export type Props = {
  totalRecords: number;
  currentPage: number;
  pageLimit: number;
  defaultResultSizeOptions: number[];
  children?: ReactNode;
  onPageChanged: (pageData: { currentPage: number; pageLimit: number }) => void;
};

class GridPagination extends Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static contextType = PaginationContext;

  static Switcher: ElementType<SwitcherProps>;

  static ResultText: ElementType<ResultTextProps>;

  render(): JSX.Element {
    const { totalRecords, currentPage, pageLimit, defaultResultSizeOptions, children, onPageChanged } = this.props;

    return (
      <PaginationContext.Provider
        value={{
          totalRecords,
          pageLimit,
          currentPage,
          defaultResultSizeOptions,
          onPageChanged,
        }}
      >
        {children}
      </PaginationContext.Provider>
    );
  }
}

GridPagination.Switcher = GridSwitcher;
GridPagination.ResultText = GridResultText;

export default GridPagination;
