import React from 'react';
import { Input as ReactstrapInput, InputProps } from 'reactstrap';
import styled, { css } from 'styled-components';
import { media } from '@minecraft.themes';
import { useTranslation } from '@blocs.i18n';

type MaxLengthInfoProps = {
  isLimitRelativeOnDevice: boolean;
};

const MaxLengthInfo = styled.span<MaxLengthInfoProps>`
  color: ${(props): string => props.theme.typography.body.color};
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1rem;
  margin-top: 0.25rem;
  display: none;
  position: absolute;
  ${media.md`
    position: ${(props) => (props.isLimitRelativeOnDevice ? 'relative' : 'absolute')};
  `}
`;

const InputWithoutSize = ({
  size,
  showLimit,
  value,
  defaultValue,
  component,
  minLength,
  maxLength,
  showLabel,
  isLimitRelativeOnDevice,
  noCustomError,
  label,
  placeholder,
  name,
  'aria-label': ariaLabel,
  ...newProps
}) => {
  const { t } = useTranslation();

  const minLimitReached = (value || '').length >= Number(minLength) || !minLength;
  const maxLimitReached = (value || '').length >= Number(maxLength);

  const isWithinValidLengthRange =
    !maxLimitReached && minLimitReached && (value || '').length < maxLength && !!maxLength;

  return (
    <>
      <ReactstrapInput
        {...newProps}
        aria-label={ariaLabel || label || placeholder}
        placeholder={placeholder}
        name={name}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
      />
      {showLimit && (maxLength || minLength) && (
        <MaxLengthInfo isLimitRelativeOnDevice={isLimitRelativeOnDevice} className="maxLengthInfo">
          {!minLimitReached && t('common:form.info.minLength', { minLength })}
          {isWithinValidLengthRange && t('common:form.info.maxLength', { maxLength })}
          {maxLimitReached && t('common:form.info.maxLengthReached', { maxLength })}
        </MaxLengthInfo>
      )}
    </>
  );
};

type Props = {
  size: 'small' | 'large';
};

const Input = styled(InputWithoutSize)<Props | InputProps>`
  color: ${(props) => props.theme.form.inputTextColor};
  background-color: ${(props) => props.theme.form.backgroundColor};
  border: 1px solid ${(props) => props.theme.form.borderColor};
  border-color: ${(props) =>
    props.error ? `${props.theme.form.state.error.borderColor} !important}` : props.theme.form.borderColor};
  font-size: ${(props) => (props.size === 'large' ? '16px' : '14px')};
  ${(props) =>
    props.size === 'small' &&
    css`
      padding: 4px;
      height: auto;
    `};

  :disabled {
    color: ${(props) => props.theme.form.state.disabled.inputTextColor};
    background-color: ${(props) => props.theme.form.state.disabled.backgroundColor};
  }

  &::placeholder {
    color: ${(props): string => props.theme.form.placeholderColor};
  }

  :focus + .maxLengthInfo {
    display: block;
  }

  &:focus,
  &:active {
    color: ${(props) => props.theme.form.inputTextColor};
    background-color: ${(props) => props.theme.form.backgroundColor};
    border: 1px solid ${(props) => props.theme.form.state.active.borderColor};
  }
`;

Input.defaultProps = {
  size: 'large',
};

export default Input;
