import React, { FC } from 'react';
import Modal from '@minecraft.modal';
import { useTranslation } from '@blocs.i18n';

type Props = {
  isOpen: boolean;
  loader?: boolean;
  handleToggleClick: () => void;
  onSubmit: () => Promise<void>;
};

const DeleteModal: FC<Props> = ({ isOpen, handleToggleClick, onSubmit, loader = false }) => {
  const { t } = useTranslation();

  return (
    isOpen && (
      <Modal
        isOpen
        modalSize="small"
        cancelLabel={t('common:button.cancel')}
        submitLabel={t('common:button.confirm')}
        handleSubmitClick={onSubmit}
        handleToggleClick={handleToggleClick}
        title={t('common:billing.card.delete.title')}
        overlayLoading={loader}
      />
    )
  );
};

export default DeleteModal;
