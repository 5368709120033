import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Icon from '@minecraft.icon';
import { FlexRow, H2Text } from '@minecraft.atoms';
import { PlanTermEnum } from '@minecraft.graphql-operations';
import { useTranslation } from '@blocs.i18n';
import { FEATURE_FLAGS, useFeatureExperiment } from '@blocs.features-experiments';
import { publishBasicMembershipSelectionEvent, publishPurchaseFlowEvent } from '@blocs.marketing-events';
import { WindowWidthContext } from '@minecraft.utils';
import { PAYWALL_STEPS } from './enums/paywall';
import { PlanType } from './usePlans';
import { BillingCodes, MediaPurchase, PlanCode } from './enums/plans';
import { PaywallLocation } from './enums/PaywallLocation';
import { usePaywallQueryParams } from './usePaywallQueryParams';
import { PaywallEvents } from './enums/events';
import { useMobileRegistration } from './useMobileRegistration';

const PREMIUM_ANNUAL_LEVEL = 50;

export type QueryParamsObject = {
  [key: string]: string | number | boolean;
};

export interface UsePaywallProps {
  startingStep?: PAYWALL_STEPS;
  closeBillingPopup: () => void;
  paywallLocation?: PaywallLocation;
  plans: PlanType[];
  selectedPlan?: PlanType;
  setSelectedPlan?: (plan: PlanType) => void;
  mediaIds?: number[];
}

export interface GetTitleParams {
  lessMd?: boolean;
  paywallLocation?: PaywallLocation;
  isHidden?: boolean;
  isReactivate?: boolean;
}

export type UsePaywall = {
  step: PAYWALL_STEPS;
  getTitle: (_: GetTitleParams) => string | React.ReactNode;
  onBack: () => void;
  onNext: () => void;
  selectedPlan: PlanType;
  startingStep: PAYWALL_STEPS;
  resetStep: () => void;
  setSelectedPlan: (plan: PlanType) => void;
  groupedPlans: PlanType[];
  setIsAnnual: (isAnnual: boolean) => void;
  isAnnual: boolean;
};

function isPremiumLocation(paywallLocation?: PaywallLocation) {
  switch (paywallLocation) {
    case PaywallLocation.AUDITIONS:
    case PaywallLocation.SUBMISSION_TRENDS:
    case PaywallLocation.ROLE_TRACKER:
    case PaywallLocation.DEEPLINK:
    case PaywallLocation.CREDITS:
    case PaywallLocation.SKILLS:
    case PaywallLocation.PROFILE_MEDIA:
      return true;
    default:
      return false;
  }
}

function getTerm(isAnnual?: boolean) {
  return isAnnual ? PlanTermEnum.Annual : PlanTermEnum.Monthly;
}

// Group the annual and monthly plans
const groupPlans = ({ plans, isAnnual }: { plans: PlanType[]; isAnnual?: boolean }): PlanType[] => {
  return plans.reduce((acc, plan) => {
    if (plan.term.code === getTerm(isAnnual) || plan.billingCode === BillingCodes.registration) {
      acc.push(plan);
    }

    return acc;
  }, []);
};

interface UsePaywallState {
  step: PAYWALL_STEPS;
  selectedPlan: PlanType;
  isAnnual: boolean;
}

const initialState: UsePaywallState = {
  step: PAYWALL_STEPS.INITIAL,
  selectedPlan: null,
  isAnnual: false,
};

const TitleWrapper: FC<{ title: string }> = ({ title }) => {
  const isMobile = WindowWidthContext.useIsMobile();
  return (
    <FlexRow alignItems="center" gap="3" alignContent="center">
      {!isMobile && (
        <FlexRow justifyContent="flex-end" alignItems="center">
          <Icon name="rocket-outline" />
        </FlexRow>
      )}
      <H2Text className="cn_max-w-md" lineHeight="expanded" fontWeight="semibold">
        {title}
      </H2Text>
    </FlexRow>
  );
};

const getTitleForStep = (
  step: PAYWALL_STEPS,
  location: PaywallLocation,
  isReactivate: boolean,
  t: (key: string) => string
) => {
  if (step === PAYWALL_STEPS.INITIAL) {
    if (isReactivate) return <TitleWrapper title={t('common:paywall.title.reactivate')} />;
    if (location === PaywallLocation.SIGNUP) return t('common:paywall.title.registration');
    if ([PaywallLocation.MEMBERSHIP, PaywallLocation.GLOBAL_NAV].includes(location))
      return t('common:paywall.title.membership');
    if (location === PaywallLocation.TALENT_SCOUT) return t('common:paywall.title.upgradeTalentScout');
    if (location === PaywallLocation.THRIVE_UPSELL) return t('common:paywall.title.upgradeThrive');
    if (
      [
        PaywallLocation.MEDIA_BANK,
        PaywallLocation.MEDIA_LIBRARY_UPLOAD,
        PaywallLocation.MEDIA_REQUEST_UPLOAD,
        PaywallLocation.OVERAGE_BANNER,
        PaywallLocation.EDIT_PROFILE_UPLOAD,
        PaywallLocation.CUSTOMIZE_SUBMISSION_UPLOAD,
      ].includes(location)
    ) {
      return t('common:paywall.title.upgradeUnlimitedMedia');
    }
    if (
      [PaywallLocation.CUSTOM_URL_UPSELL, PaywallLocation.OVERAGE_MODAL, PaywallLocation.OVERAGE_UPSELL_MODAL].includes(
        location
      )
    ) {
      return (
        <TitleWrapper
          title={
            location === PaywallLocation.OVERAGE_MODAL || location === PaywallLocation.OVERAGE_UPSELL_MODAL
              ? t('common:paywall.title.membership')
              : t('common:paywall.title.upgradeCustomURL')
          }
        />
      );
    }
    return <TitleWrapper title={t('common:paywall.title.upgradeMembership')} />;
  }
  if (step === PAYWALL_STEPS.CHECKOUT) return t('common:creditCardForm.completePurchase');
  return '';
};

const useSelectedPlanEffect = ({
  selectedPlan,
  step,
  paywallLocation,
}: {
  selectedPlan: PlanType;
  step: PAYWALL_STEPS;
  paywallLocation: PaywallLocation;
}) => {
  useEffect(() => {
    if (selectedPlan?.price && step !== PAYWALL_STEPS.SUCCESS) {
      if (selectedPlan.billingCode === BillingCodes.registration) {
        publishBasicMembershipSelectionEvent();
      }
      const isMediaPurchase = selectedPlan?.billingCode === MediaPurchase.alacarte;
      const purchaseItems = [selectedPlan].map((plan) => ({
        name: `${plan.title} ${plan.term.code} ${plan.canRenew ? 'Renewal' : ''}`,
        code: `${plan.billingCode}${plan.canRenew ? '_renewal' : ''}`,
        price: Number(plan.price),
      }));

      publishPurchaseFlowEvent(PaywallEvents.selectItem, {
        location: paywallLocation,
        purchase: {
          items: isMediaPurchase ? purchaseItems : [],
          subscriptions: !isMediaPurchase ? purchaseItems : [],
          currency: { code: selectedPlan.currencyCode },
        },
      });
    }
  }, [selectedPlan, paywallLocation, step]);
};

const usePremiumLocationEffect = ({
  locationIsPremium,
  setStep,
  isMobileRegistration,
}: {
  locationIsPremium: boolean;
  isMobileRegistration: boolean;
  setStep: (T: PAYWALL_STEPS) => void;
}) => {
  useEffect(() => {
    if (locationIsPremium || isMobileRegistration) {
      setStep(PAYWALL_STEPS.CHECKOUT);
    }
  }, [locationIsPremium, setStep, isMobileRegistration]);
};

export const usePaywall = ({
  startingStep = PAYWALL_STEPS.INITIAL,
  closeBillingPopup,
  paywallLocation,
  plans,
  selectedPlan,
  setSelectedPlan = () => null,
  mediaIds,
}: UsePaywallProps): UsePaywall => {
  const { t } = useTranslation();
  const { isMobileRegistration } = useMobileRegistration({ paywallLocation });
  const currentPlan = plans?.find((plan) => !!plan?.isCurrent);
  const isAnnualBilling = currentPlan?.term?.code === PlanTermEnum.Annual;
  const [step, setStep] = useState<PAYWALL_STEPS>(startingStep);
  const [isAnnual, setIsAnnual] = useState<boolean>(
    currentPlan?.level < PREMIUM_ANNUAL_LEVEL ? initialState.isAnnual : isAnnualBilling
  );
  const locationIsPremium = isPremiumLocation(paywallLocation);
  const isAlacartePurchaseEnabled = useFeatureExperiment(FEATURE_FLAGS.ALACARTE_MEDIA_PURCHASE);
  const isPremiumPlan = useCallback(
    (plan) => (locationIsPremium ? plan.code === PlanCode.Premium : true),
    [locationIsPremium]
  );
  const isVisiblePlan = useCallback((plan) => !plan.shouldHidePlan, []);
  const orderByOrder = useCallback((a, b) => a.order - b.order, []);

  const availablePlans = useMemo(() => {
    return plans.filter(isPremiumPlan).filter(isVisiblePlan).sort(orderByOrder);
  }, [plans, isPremiumPlan, isVisiblePlan, orderByOrder]);

  const getHighestAvailablePlan = useCallback(() => {
    const termCode = isAnnual ? PlanTermEnum.Annual : PlanTermEnum.Monthly;
    return availablePlans
      ?.filter((plan) => plan.term?.code === termCode)
      .sort((a, b) => b.level - a.level)
      .shift();
  }, [availablePlans, isAnnual]);

  const groupedPlans = useMemo(() => {
    return groupPlans({ plans: availablePlans, isAnnual });
  }, [availablePlans, isAnnual]);

  const updateIsAnnual = useCallback(
    (isAnnualTerm: boolean) => {
      const newTerm = getTerm(isAnnualTerm);
      const newSelectedPlan = availablePlans.find(
        (plan) => plan.code === selectedPlan?.code && newTerm === plan.term.code
      );
      setIsAnnual(isAnnualTerm);
      setSelectedPlan(newSelectedPlan);
    },
    [availablePlans, selectedPlan, setSelectedPlan]
  );

  const onNext = () => {
    const isLastStep = step === PAYWALL_STEPS.SUCCESS;

    if (!isLastStep) {
      const nextStep = Number(step) + 1;
      setStep(nextStep);
    }
  };

  const getTitle = useCallback(
    ({ paywallLocation: location, isHidden = false, isReactivate }: GetTitleParams = {}): string | React.ReactNode => {
      if (isHidden) return '';
      return getTitleForStep(step, location, isReactivate, t);
    },
    [step, t]
  );

  const resetStep = () => {
    setStep(initialState.step);
    setIsAnnual(initialState.isAnnual);
    setSelectedPlan(initialState.selectedPlan);
  };

  const onBack = () => {
    switch (step) {
      case PAYWALL_STEPS.SUCCESS:
        setStep(PAYWALL_STEPS.CHECKOUT);
        break;
      case PAYWALL_STEPS.CHECKOUT:
        setStep(PAYWALL_STEPS.INITIAL);
        break;
      case PAYWALL_STEPS.INITIAL:
      default:
        closeBillingPopup();
        break;
    }
  };

  usePaywallQueryParams({ step, paywallLocation });
  useSelectedPlanEffect({ selectedPlan, step, paywallLocation });
  usePremiumLocationEffect({ locationIsPremium, setStep, isMobileRegistration });
  usePaywallQueryParams({ step, paywallLocation });

  useEffect(() => {
    setIsAnnual(currentPlan?.level < PREMIUM_ANNUAL_LEVEL ? initialState.isAnnual : isAnnualBilling);
  }, [currentPlan, isAnnualBilling]);

  useEffect(() => {
    if (!selectedPlan && (locationIsPremium || isAlacartePurchaseEnabled || isMobileRegistration)) {
      setSelectedPlan(getHighestAvailablePlan());
    }
  }, [
    locationIsPremium,
    availablePlans,
    selectedPlan,
    setSelectedPlan,
    currentPlan,
    startingStep,
    getHighestAvailablePlan,
    isAlacartePurchaseEnabled,
    isMobileRegistration,
  ]);

  useEffect(() => {
    if (isAlacartePurchaseEnabled && mediaIds?.length > 0) {
      setStep(PAYWALL_STEPS.CHECKOUT);
    }
  }, [isAlacartePurchaseEnabled, mediaIds]);

  return {
    step,
    getTitle,
    onBack,
    onNext,
    selectedPlan,
    startingStep,
    resetStep,
    setSelectedPlan,
    isAnnual,
    setIsAnnual: updateIsAnnual,
    groupedPlans,
  };
};
