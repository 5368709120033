import { PlanCode } from './enums/plans';

type getPlanFeaturesParams = {
  code: PlanCode;
};

type PlanFeatureProps = {
  readonly code: string;
  readonly label: string;
  readonly order: number;
  readonly flag?: string;
  readonly value?: string;
  readonly translationKey?: string;
  readonly customLabel?: string;
};

export type PlanFeatureType = {
  code: string;
  label: string;
  order: number;
  flag?: string;
  value?: string;
  translationKey?: string;
  customLabel?: string;
};

type MediaStorageProps = {
  readonly value: string;
  readonly translationKey?: string;
  readonly label?: string;
  readonly customLabel?: string;
};

export const PlanFeature = ({
  code,
  label,
  order,
  flag,
  value,
  translationKey,
  customLabel,
}: PlanFeatureProps): PlanFeatureType => ({
  code,
  label,
  order,
  flag,
  value,
  translationKey,
  customLabel,
});

// Free
export const FreeProfile = PlanFeature({
  code: 'free-profile',
  label: 'common:paywall.features.free.plan.freeProfile',
  order: 0,
});

export const BrowseRole = PlanFeature({
  code: 'browse-role',
  label: 'common:paywall.features.free.browseRole',
  order: 1,
});

export const MediaRequest = PlanFeature({
  code: 'media-request',
  label: 'common:paywall.features.free.plan.mediaRequest',
  order: 2,
});

export const RecieveNotification = PlanFeature({
  code: 'saved-searches',
  label: 'common:paywall.features.free.notification',
  order: 3,
});
// Essential
export const RoleSubmissions = PlanFeature({
  code: 'role-submissions',
  label: 'common:paywall.features.essential.plan.roleSubmissions',
  order: 4,
});

export const Connect = PlanFeature({
  code: 'connect',
  label: 'common:paywall.features.essential.connect',
  order: 5,
});

export const CreateReals = PlanFeature({
  code: 'createReals',
  label: 'common:paywall.features.essential.createReals',
  order: 6,
});

// Premium
export const UnlimitedMediaStorage = PlanFeature({
  code: 'unlimited',
  label: 'common:paywall.features.premium.storageUnlimited',
  order: -1,
});

export const CompletedAuditions = PlanFeature({
  code: 'role-tracker',
  label: 'common:paywall.features.premium.completedAuditions',
  order: 7,
});

export const ViewAll = PlanFeature({
  code: 'view-all',
  label: 'common:paywall.features.premium.plan.viewAll',
  order: 8,
});

export const ThriveBenefits = PlanFeature({
  code: 'thrive-benefits',
  label: 'common:paywall.features.premium.plan.thriveBenefits',
  order: 9,
});

export const RoleTracker = PlanFeature({
  code: 'role-tracker',
  label: 'common:paywall.features.premium.roleTracker',
  order: 10,
});

export const UltimateBenefits = PlanFeature({
  code: 'ultimate-benefits',
  label: 'common:paywall.features.ultimate.ultimateBenefits',
  customLabel: 'common:paywall.features.ultimate.additionalBenefits',
  order: 11,
});

const BASE_FEATURES = [FreeProfile, BrowseRole];

export const FREE_FEATURES = [...BASE_FEATURES, MediaRequest, RecieveNotification];

export const ESSENTIAL_FEATURES = [RoleSubmissions, Connect];
export const ESSENTIAL_WITH_FREE_FEATURES = [
  ...BASE_FEATURES,
  RoleSubmissions,
  MediaRequest,
  RecieveNotification,
  Connect,
];

export const PREMIUM_FEATURES = [UnlimitedMediaStorage, ViewAll, ThriveBenefits];

export const ULTIMATE_FEATURES = [UltimateBenefits];

export const FULL_FREE_FEATURES = [...FREE_FEATURES];

export const FULL_ESSENTIAL_FEATURES = [
  RoleSubmissions,
  Connect,
  ...BASE_FEATURES,
  MediaRequest,
  CreateReals,
  RecieveNotification,
];

export const FULL_PREMIUM_FEATURES = [
  UnlimitedMediaStorage,
  ViewAll,
  ThriveBenefits,
  CompletedAuditions,
  CreateReals,
  Connect,
  RoleSubmissions,
  FreeProfile,
  BrowseRole,
  MediaRequest,
  RecieveNotification,
];

export const FULL_ULTIMATE_FEATURES = [
  UltimateBenefits,
  ThriveBenefits,
  ViewAll,
  RoleSubmissions,
  Connect,
  FreeProfile,
  BrowseRole,
  MediaRequest,
  RecieveNotification,
  CreateReals,
  CompletedAuditions,
];

export const getPlanFeatures = ({
  code,
}: getPlanFeaturesParams): {
  keyFeatures: PlanFeatureType[];
  features: PlanFeatureType[];
  combinedFeatures?: PlanFeatureType[];
} => {
  const featuresMap = {
    [PlanCode.Free]: {
      keyFeatures: FREE_FEATURES,
      features: FULL_FREE_FEATURES,
      combinedFeatures: null,
    },
    [PlanCode.Essential]: {
      keyFeatures: ESSENTIAL_FEATURES,
      features: FULL_ESSENTIAL_FEATURES,
      combinedFeatures: ESSENTIAL_WITH_FREE_FEATURES,
    },
    [PlanCode.Premium]: {
      keyFeatures: PREMIUM_FEATURES,
      features: FULL_PREMIUM_FEATURES,
      combinedFeatures: null,
    },
    [PlanCode.Ultimate]: {
      keyFeatures: ULTIMATE_FEATURES,
      features: FULL_ULTIMATE_FEATURES,
      combinedFeatures: null,
    },
  };

  const planFeatures = featuresMap[code] || {
    keyFeatures: [],
    features: [],
    combinedFeatures: [] || undefined,
  };

  return {
    keyFeatures: planFeatures.keyFeatures || [],
    features: planFeatures.features || [],
    combinedFeatures: planFeatures?.combinedFeatures,
  };
};
