import { gql } from '@apollo/client';

export const GET_MEMBERSHIP_FEATURES = gql`
  query getPlanFeatures($planId: ID!) {
    getPlanFeatures(planId: $planId) {
      itemId
      itemCode
      name
      code
      price
      currency {
        code
        symbol
      }
      credit
    }
  }
`;

export const GET_BILLING_INFO = gql`
  query getBillingInfo($input: GetBillingInfoInput!) {
    wallet: getBillingInfo(input: $input) {
      __typename
      id
      cardType
      isBackupPaymentMethod
      isPrimaryPaymentMethod
      firstName
      lastName
      month
      year
      lastFour
      paymentType
    }
  }
`;

export const ADD_BILLING_INFO = gql`
  mutation addBillingInfo($input: AddBillingInfoInput!) {
    card: addBillingInfo(input: $input) {
      id
      cardType
      isBackupPaymentMethod
      isPrimaryPaymentMethod
      firstName
      lastName
      month
      year
      lastFour
      paymentType
    }
  }
`;

export const EDIT_BILLING_INFO = gql`
  mutation updateBillingInfo($input: UpdateBillingInfoInput!) {
    card: updateBillingInfo(input: $input) {
      __typename
      id
      cardType
      isBackupPaymentMethod
      isPrimaryPaymentMethod
      firstName
      lastName
      month
      year
      lastFour
      paymentType
    }
  }
`;

export const DELETE_BILLING_INFO = gql`
  mutation removeBillingInfo($input: RemoveBillingInfoInput!) {
    removeBillingInfo(input: $input)
  }
`;

export const SET_SUBSCRIPTION_PAYMENT_METHOD = gql`
  mutation setSubscriptionPaymentMethod($input: SetSubscriptionPaymentMethodInput!) {
    setSubscriptionPaymentMethod(input: $input)
  }
`;

export const GET_ARTIST_SUBSCRIPTIONS = gql`
  query artistSubscriptions($artistId: Int!) {
    artistSubscriptions(input: { artistId: $artistId }) {
      __typename
      id
      nextBillingDate
      expirationDate
      status
      billingInfoId
      plan {
        __typename
        code
        name
        id
        billingCode
        isBasePlan
        enabled
        planCurrencyCode
        price
        created
        updated
        sortOrder
        planStatusId
        billingProviderId
        currency {
          __typename
          symbol
          id
          code
          name
        }
        planTerm {
          __typename
          id
          code
          name
        }
        level
        isComped
      }
    }
  }
`;
