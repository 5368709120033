import React, { FC } from 'react';
import { BodyText, FlexCol, FlexRow, H3Text } from '@minecraft.atoms';
import Icon from '@minecraft.icon';
import styled from 'styled-components';
import { media } from '@minecraft.themes';
import { AnimatedProgressBar } from '@minecraft.molecules';
import { useTranslation } from '@blocs.i18n';

const StyledIcon = styled(Icon)`
  width: 4rem;

  ${media.sm`
    width: 5rem;
  `};
`;

const CompletePurchaseLoaderWrapper = styled.div`
  padding: 2rem;
  width: 100%;

  ${media.sm`
    max-width: 72rem;
  `}
`;

export const CompletePurchaseLoader: FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <CompletePurchaseLoaderWrapper>
      <FlexCol gap="8" alignItems="center">
        <H3Text>{t('common:paywall.completePurchaseLoading.header')}</H3Text>
        <FlexRow gap="8">
          <StyledIcon name="essential-plan" />
          <StyledIcon name="cni-logo" />
          <StyledIcon name="premium-plan" />
        </FlexRow>
        <AnimatedProgressBar type="gradient" />
        <BodyText>{t('common:paywall.completePurchaseLoading.body')}</BodyText>
      </FlexCol>
    </CompletePurchaseLoaderWrapper>
  );
});
