// Describes different locations where paywall can be shown
export enum PaywallLocation {
  ADD_TALENT = 'add-talent', // Add new artist flow
  AUDITIONS = 'auditions', // Your Insights -> Your Auditions
  BILLBOARD = 'billboard', // casting billboard page
  CREDITS = 'credits', // Edit Profile -> Credits -> when attaching media to a credit
  CUSTOM_URL = 'custom-url', // Custom URL
  CUSTOM_URL_UPSELL = 'custom-url-upsell', // Custom URL upsell
  CUSTOMIZE_SUBMISSION_UPLOAD = 'customize-submission-upload', // Customize submission upload
  DEEPLINK = 'deeplink', // deeplink flow (marketing emails)
  EDIT_PROFILE_UPLOAD = 'edit-profile-upload', // Edit profile upload
  GLOBAL_NAV = 'global-nav', // global navigation bar
  MEDIA_BANK = 'media-bank', // media bank
  MEDIA_LIBRARY = 'media-library', // Media library
  MEDIA_LIBRARY_UPLOAD = 'media-library-upload', // Media library upload
  MEDIA_REQUEST_UPLOAD = 'media-request-upload', // Media request upload
  MEMBERSHIP = 'membership', // membership and billing page
  MEMBERSHIP_EDIT = 'membership-edit', // membership and billing page edit
  OVERAGE_BANNER = 'overage-banner', // Overage banner
  OVERAGE_MODAL = 'overage-modal', // Overage Modal
  OVERAGE_UPSELL_MODAL = 'overage-upsell-modal',
  PROFILE = 'profile', // profile page
  PROFILE_MEDIA = 'profile-media', // Profile edit media uploaders, audio reel, video reel
  ROLE_TRACKER = 'role-tracker', // Your Insights -> Role Tracker
  SKILLS = 'skills', // Edit Profile -> Edit Skills -> when attaching media to a skill
  SIGNUP = 'signup', // sign up flow
  SUBMISSION_TEMPLATE = 'submission-template', // Submission template
  SUBMISSION_TRENDS = 'submission-trends', // Your Insights -> Submission Trends
  TALENT_SCOUT = 'talent-scout', // Profile settings -> Representatives in Talent Scout
  THRIVE_UPSELL = 'thrive-upsell', // thrive upsell modal on billing and membership page
  UNKNOWN = 'unknown',
}

export enum BillingPath {
  UserRegistration = 'user_registration',
  UserAddArtist = 'user_add_artist',
  UserUpgrade = 'user_upgrade',
}

export const paywallLocationToBillingPath = (location: PaywallLocation): BillingPath | null => {
  const registrationPaths = [PaywallLocation.SIGNUP];
  const addArtistPaths = [PaywallLocation.ADD_TALENT];
  const upgradePaths = [
    PaywallLocation.MEMBERSHIP,
    PaywallLocation.THRIVE_UPSELL,
    PaywallLocation.MEMBERSHIP_EDIT,
    PaywallLocation.BILLBOARD,
    PaywallLocation.PROFILE,
    PaywallLocation.GLOBAL_NAV,
    PaywallLocation.MEDIA_BANK,
    PaywallLocation.AUDITIONS,
    PaywallLocation.SUBMISSION_TRENDS,
    PaywallLocation.TALENT_SCOUT,
    PaywallLocation.DEEPLINK,
    PaywallLocation.ROLE_TRACKER,
    PaywallLocation.MEDIA_LIBRARY,
    PaywallLocation.MEDIA_LIBRARY_UPLOAD,
    PaywallLocation.EDIT_PROFILE_UPLOAD,
    PaywallLocation.MEDIA_REQUEST_UPLOAD,
    PaywallLocation.CUSTOMIZE_SUBMISSION_UPLOAD,
    PaywallLocation.OVERAGE_BANNER,
    PaywallLocation.OVERAGE_MODAL,
    PaywallLocation.OVERAGE_UPSELL_MODAL,
    PaywallLocation.CUSTOM_URL,
    PaywallLocation.CUSTOM_URL_UPSELL,
    PaywallLocation.SUBMISSION_TEMPLATE,
  ];

  if (registrationPaths.includes(location)) return BillingPath.UserRegistration;
  if (addArtistPaths.includes(location)) return BillingPath.UserAddArtist;
  if (upgradePaths.includes(location)) return BillingPath.UserUpgrade;
  return null;
};
