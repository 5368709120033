export enum PAYWALL_STEPS {
  INITIAL = 1,
  CHECKOUT = 2,
  SUCCESS = 3,
}

export enum PAYWALL_QUERY_PARAMS {
  SELECT_MEMBERSHIP = 'select_membership',
  MEMBERSHIP_SUMMARY = 'membership_summary',
  MEMBERSHIP_SUCCESS = 'membership_success',
  DEEPLINK = 'deeplink',
}
