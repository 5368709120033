/* eslint-disable prettier/prettier */
import React, { FC } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { WindowWidthContext } from '@minecraft.utils';
import { BasicContainer, FlexCol } from '@minecraft.atoms';
import Modal from '@minecraft.modal';
import styled from 'styled-components';
import { media } from '@minecraft.themes';
import { PaywallLocation } from './enums/PaywallLocation';
import { useBillingPopup } from './BillingPopupContext';
import { PlanType } from './usePlans';
import { usePaywall } from './usePaywall';
import { PAYWALL_QUERY_PARAMS, PAYWALL_STEPS } from './enums/paywall';
import { PlanSelectionWrapper } from './PlanSelection/PlanSelectionWrapper';
import { CheckoutWrapper } from './Checkout/CheckoutWrapper';
import { OrderSuccessWraper } from './OrderSuccess/OrderSuccessWrapper';
import { usePaywallRenewal } from './usePaywallRenewal';
import { BillingCodes, MediaPurchase, TemperatureValues } from './enums/plans';
import { usePurchaseMedia } from './usePurchaseMedia';
import { Purchase } from './types';

export interface PaywallModalProps {
  temperature?: TemperatureValues | '';
}

const StyledModal = styled(Modal) <PaywallModalProps>`
  max-width: 70rem;
  .modal-body {
    padding: ${(props) => (props.temperature ? '0' : '0 1rem')};
  }
  .modal-content {
    padding-bottom: 2rem;
  }
  .modal-header {
    margin: ${({ temperature }) => (temperature ? '0' : '0.8rem !important')};
    padding: 0;
    background-color: ${(props) => props.theme.designSystem.paywall[props?.temperature]?.bgColor};
    padding-left: 0;
    ${media.sm`
    margin: ${({ temperature }) => (temperature ? '-1px 0' : '0')};
  `}
  }
  .modal-title {
    padding-bottom: 1rem;
    ${media.md`
    padding-bottom: 0;
  `}
  }
`;

export interface PaywallWrapperProps {
  isModal?: boolean;
  startingStep?: PAYWALL_STEPS;
  paywallLocation?: PaywallLocation;
  onPaymentCompleteRedirect?: (_?: string) => void;
  mediaIds?: number[] | null;
}

interface PaywallWrapperWithModalProps {
  isModal: boolean;
  title: string | React.ReactNode;
  visible: boolean;
  children?: React.ReactNode;
  step?: PAYWALL_STEPS;
  selectedPlan?: PlanType;
  onModalClose?: () => void;
}

const PaywallWrapperWithModal: FC<PaywallWrapperWithModalProps> = ({
  isModal,
  title,
  visible,
  children,
  step,
  selectedPlan,
  onModalClose,
}) => {
  const { location } = useBillingPopup();
  const isSuccess = step === PAYWALL_STEPS.SUCCESS;


  if (isModal) {
    return (
      <StyledModal
        title={title}
        isFooterHidden
        handleToggleClick={onModalClose}
        isOpen={visible}
        temperature={isSuccess ? selectedPlan?.temperature : ''}
        data-testid="next-billing-popup-modal-wrapper"
        isHeaderHidden={isSuccess}
      >
        {children}
      </StyledModal>
    );
  }

  return (
    <BasicContainer
      className={`cn_w-full cn_m-auto ${location !== PaywallLocation.MEMBERSHIP ? 'cn_max-w-lg' : ''}`}
      data-testid="next-paywall-wrapper"
    >
      {children}
    </BasicContainer>
  );
};

export const PaywallWrapper: React.FC<PaywallWrapperProps> = React.memo(
  ({
    isModal = false,
    startingStep = PAYWALL_STEPS.INITIAL,
    onPaymentCompleteRedirect = () => null,
    mediaIds: mediaIdFromProps,
  }) => {
    const {
      visible,
      location,
      mediaIds: mediaIdsFromContext,
      close: closeBillingPopup,
      subscription,
      countryCode,
      plans,
      isTrialEligible,
      selectedPaymentOption,
      setCompletedPurchase,
      setCompletePurchaseError,
      isAnnualRenewalDue,
      completedPurchase,
      completePurchaseLoading,
      currencyCode: subscriptionCurrencyCode,
      currentPurchase,
      addPaymentError,
      addPaymentLoading,
      applyCouponError,
      completePurchaseError,
      couponLoading,
      onAddPaymentOption,
      applyCoupon,
      removeCoupon,
      setSelectedPaymentOption,
      onThreeDActionError,
      plansError,
      plansLoading,
      setShowAddPaymentMethod,
      showAddPaymentMethod,
      showThreeDSecurityForm,
      threeDActionToken,
      wallet,
      walletLoading,
      isWithinTrialPeriod,
      completePurchase,
      isReactivate,
      selectedPlan,
      setSelectedPlan,
    } = useBillingPopup();

    const mediaIds = mediaIdFromProps || mediaIdsFromContext;
    const urlLocation = useLocation();
    const searchParams = new URLSearchParams(urlLocation.search);
    const isDeeplink = searchParams.get(PAYWALL_QUERY_PARAMS.DEEPLINK)?.toLocaleLowerCase() === 'true';
    const isMobile = WindowWidthContext.useIsMobile();
    const startPaywallStep = isDeeplink ? PAYWALL_STEPS.CHECKOUT : startingStep;

    const {
      previewMediaPurchase,
      previewMediaPurchaseLoading,
      previewMediaPurchaseError,
      completePurchaseMedia,
      applyPurchaseMediaCoupon,
      removePurchaseMediaCoupon,
      applyPurchaseMediaCouponError,
      purchaseMediaCouponLoading,
      currencyCode: mediaPurchaseCurrencyCode,
    } = usePurchaseMedia({
      selectedPlan,
      selectedPaymentOption,
      paywallLocation: location,
      setCompletedPurchase,
      setCompletePurchaseError,
      mediaIds
    });

    const { step, getTitle, onNext, onBack, resetStep, groupedPlans, setIsAnnual, isAnnual } = usePaywall({
      startingStep: startPaywallStep,
      selectedPlan,
      setSelectedPlan,
      closeBillingPopup,
      paywallLocation: isModal && !visible ? undefined : location,
      plans,
      mediaIds,
    });

    const { currentRenewalPurchase, completeRenewalPurchase, renewalCurrencyCode, enableAnnualRenewal } =
      usePaywallRenewal({
        selectedPlan,
        selectedPaymentOption,
        paywallLocation: location,
        setCompletedPurchase,
        setCompletePurchaseError,
        isAnnualRenewalDue,
        isAnnual,
      });

    const isMediaPurchase = selectedPlan?.billingCode === MediaPurchase.alacarte;
    const purchasePreview = (isMediaPurchase ? previewMediaPurchase : currentPurchase) as Purchase
    const completePurchaseMutation = (isMediaPurchase ? completePurchaseMedia : completePurchase);
    const onApplyCoupon = (isMediaPurchase ? applyPurchaseMediaCoupon : applyCoupon);
    const applyCouponErr = (isMediaPurchase ? applyPurchaseMediaCouponError : applyCouponError);
    const onRemoveCoupon = (isMediaPurchase ? removePurchaseMediaCoupon : removeCoupon);
    const isCouponLoading = (isMediaPurchase ? purchaseMediaCouponLoading : couponLoading);
    const currencyCode = (isMediaPurchase ? mediaPurchaseCurrencyCode : subscriptionCurrencyCode);

    const onModalClose = () => {
      resetStep();
      closeBillingPopup();
    };

    const onCompleteRedirect = (url?: string) => {
      onPaymentCompleteRedirect(url);
      resetStep();
    }


    return (
      <PaywallWrapperWithModal
        isModal={isModal}
        title={getTitle({ paywallLocation: location, isHidden: !(isMobile && isModal), isReactivate })}
        visible={visible}
        step={step}
        selectedPlan={selectedPlan}
        onModalClose={onModalClose}
      >
        <FlexCol
          className={classNames('cn_w-full', { 'cn_mt-2': !isModal })}
          justifyContent="center"
          alignItems="center"
        >
          {PAYWALL_STEPS.SUCCESS === step && (
            <OrderSuccessWraper
              isModal={isModal}
              selectedPlan={selectedPlan}
              completedPurchase={completedPurchase}
              lessMd={isMobile}
              completePurchaseLoading={completePurchaseLoading}
              paywallLocation={location}
              onPaymentCompleteRedirect={onCompleteRedirect}
              subscription={subscription}
              countryCode={countryCode}
              currencyCode={currencyCode}
              closeBillingPopup={onModalClose}
            />
          )}
          {PAYWALL_STEPS.CHECKOUT === step && (
            <CheckoutWrapper
              addPaymentError={addPaymentError}
              addPaymentLoading={addPaymentLoading}
              applyCouponError={applyCouponErr}
              completePurchaseError={completePurchaseError}
              completedPurchase={completedPurchase}
              completePurchaseLoading={completePurchaseLoading}
              countryCode={countryCode}
              couponLoading={isCouponLoading}
              currencyCode={enableAnnualRenewal ? renewalCurrencyCode : currencyCode}
              currentPlanCode={subscription?.plan?.billingCode as BillingCodes}
              currentPurchase={enableAnnualRenewal ? currentRenewalPurchase : purchasePreview}
              enableAnnualRenewal={enableAnnualRenewal}
              getTitle={getTitle}
              isAnnual={isAnnual}
              isModal={isModal}
              lessMd={isMobile}
              onAddPaymentOption={onAddPaymentOption}
              onApplyCoupon={onApplyCoupon}
              onBack={onBack}
              onCompletePurchase={enableAnnualRenewal ? completeRenewalPurchase : completePurchaseMutation}
              onNext={onNext}
              onRemoveCoupon={onRemoveCoupon}
              onSelectPaymentOption={setSelectedPaymentOption}
              onThreeDActionError={onThreeDActionError}
              paywallLocation={location}
              plans={groupedPlans}
              plansError={plansError}
              plansLoading={plansLoading}
              selectedPaymentOption={selectedPaymentOption}
              selectedPlan={selectedPlan}
              selectedPlanTerm={isAnnual ? 'annual' : 'monthly'}
              setIsAnnual={setIsAnnual}
              setSelectedPlan={setSelectedPlan}
              setShowAddPaymentMethod={setShowAddPaymentMethod}
              showAddPaymentMethod={showAddPaymentMethod}
              showThreeDSecurityForm={showThreeDSecurityForm}
              startingStep={startPaywallStep}
              threeDActionToken={threeDActionToken}
              wallet={wallet}
              walletLoading={walletLoading}
              isWithinTrialPeriod={isWithinTrialPeriod}
              previewMediaPurchase={previewMediaPurchase}
              previewMediaPurchaseLoading={previewMediaPurchaseLoading}
              previewMediaPurchaseError={previewMediaPurchaseError}
              closeBillingPopup={onModalClose}
            />
          )}
          {![PAYWALL_STEPS.CHECKOUT, PAYWALL_STEPS.SUCCESS].includes(step) && (
            <PlanSelectionWrapper
              onNext={onNext}
              isAnnual={isAnnual}
              plans={groupedPlans}
              plansError={plansError}
              plansLoading={plansLoading}
              setIsAnnual={setIsAnnual}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              countryCode={countryCode}
              getTitle={getTitle}
              lessMd={isMobile}
              onBack={onBack}
              startingStep={startPaywallStep}
              paywallLocation={location}
              isModal={isModal}
              onCompletePurchase={completePurchase}
              completePurchaseLoading={completePurchaseLoading}
              completePurchaseError={completePurchaseError}
              onPaymentCompleteRedirect={onCompleteRedirect}
              enableAnnualRenewal={enableAnnualRenewal}
              isTrialEligible={isTrialEligible}
              isReactivate={isReactivate}
            />
          )}
        </FlexCol>
      </PaywallWrapperWithModal>
    );
  }
);
