import React, { FC } from 'react';
import styled from 'styled-components';
import { Field, FieldProps } from 'formik';

type SizeValue = 'xl' | 'lg' | 'sm';

type LabelProps = {
  $size: SizeValue;
  disabled: boolean;
};

/** Styled Components */
/* Customize the label (the container) */
const Label = styled.label<LabelProps>`
  color: ${(props): string =>
    props.disabled ? props.theme.colors.primary.grey1 : props.theme.colors.primary.darkGrey};
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  font-size: 1rem;
  line-height: ${(props): string => (props.$size === 'lg' ? '1.25' : '1')};
  min-height: ${(props): string => (props.$size === 'lg' ? '1.25rem' : '1rem')};
  position: relative;
  transition: color 0.3s ease;
  user-select: none;
  display: grid;
  grid-template-columns: 1em auto;
  gap: ${(props): string => {
    if (props.$size === 'xl') return '1em';
    if (props.$size === 'lg') return '0.75em';
    return '0.5em';
  }};
  align-items: center;
`;

type RadioProps = {
  $size: SizeValue;
  disabled?: boolean;
};

const StyledRadio = styled.input<RadioProps>`
  appearance: none;
  background-color: ${(props): string => props.theme.designSystem.bgColors.card};
  margin: 0;
  font: inherit;
  color: ${(props): string => props.theme.colors.primary.grey1};
  border: 1px solid
    ${({ theme, disabled }): string => (disabled ? theme.designSystem.bgColors.disabled : theme.colors.primary.grey1)};
  border-radius: 50%;
  height: ${(props): string => {
    if (props.$size === 'xl') return '1.25rem';
    if (props.$size === 'lg') return '1.125rem';
    return '.875rem';
  }};
  width: ${(props): string => {
    if (props.$size === 'xl') return '1.25rem';
    if (props.$size === 'lg') return '1.125rem';
    return '.875rem';
  }};
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 0.35rem;
    height: 0.35rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 0.35rem 0.35rem ${(props): string => props.theme.designSystem.bgColors.card};
  }

  &:checked {
    background-color: ${({ theme, disabled }): string =>
      disabled ? theme.designSystem.bgColors.disabled : theme.colors.primary.grey1};
  }

  &:checked::before {
    transform: scale(1);
  }

  &:focus {
    outline: max(2px, 0.15em) solid
      ${({ theme, disabled }): string => (disabled ? theme.designSystem.bgColors.disabled : theme.colors.primary.grey1)};
    outline-offset: max(2px, 0.15em);
  }
`;

/** Types */
type HtmlInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'onBlur' | 'value' | 'multiple' | 'type' | 'id'
>;

export type RadioInputProps = Omit<HtmlInputProps, 'size'> & {
  /**
   * callback invoked with the value of the selected radio
   */
  onChange?: (newValue: string) => void;
  /**
   * value of this radio option
   */
  value: string;
  /**
   * is this option currently selected
   */
  checked: boolean;
  testId?: string;
  qaId?: string;
  /**
   * label to display next to the radio
   */
  label?: string;
  /**
   * whether to render a small or large radio (default: "sm")
   */
  size?: SizeValue;
  id?: string;
};

/**
 * A simple, controlled radio button input with label
 * @example
 * const [selectedValue, setSelectedValue] = useState<string>('');
 * <RadioInput
      value="myValue"
      onChange={() => setSelectedValue('myValue')}
      checked={selectedValue === 'myValue'}
      label="My Value"
    />
 */
export const RadioInput: FC<RadioInputProps> = ({
  className,
  onChange,
  testId,
  qaId,
  label,
  size = 'sm' as SizeValue,
  ...inputProps
}) => {
  return (
    <Label className={className} $size={size} disabled={inputProps?.disabled}>
      <StyledRadio
        {...inputProps}
        $size={size}
        data-testid={testId}
        data-qa-id={qaId}
        onChange={(e) => onChange(e.target.value)}
        type="radio"
      />
      {label}
    </Label>
  );
};

export interface FormikRadioInputProps {
  inputProps: Omit<RadioInputProps, 'onChange' | 'onBlur' | 'name' | 'isValid' | 'isInvalid' | 'checked'>;
}

export const ConnectedRadioInput: FC<FormikRadioInputProps & { name: string }> = ({ name, inputProps }) => (
  <Field name={name}>
    {({ field: { value: formikValue }, form: { errors, setFieldValue } }: FieldProps<string>) => {
      const hasError = errors?.[name];
      const fieldValidation = hasError ? { invalid: true } : {};

      const onChange = (newVal: string) => {
        setFieldValue(name, newVal);
      };

      // this prevents the console warning about switching between
      // controlled and uncontrolled input types
      let inputValue = formikValue;

      if (inputValue === undefined) {
        inputValue = null;
      }

      return (
        <RadioInput
          value={inputProps.value}
          name={name}
          onChange={onChange}
          checked={inputValue === inputProps.value}
          {...inputProps}
          {...fieldValidation}
        />
      );
    }}
  </Field>
);
