import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { ButtonProps } from './types';
import { typeography } from '../Typography';
import { getFontSize, getLineHeight } from '../Typography/utils';

export type LinkButtonProps = Pick<ButtonProps, 'disabled' | 'className' | 'label' | 'onClick' | 'isSmall'>;

const StyledLinkAsButton = styled.span<{ $disabled: boolean; $isSmall: boolean }>`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: ${({ theme, $disabled }) => {
    return $disabled ? theme.designSystem.textColors.disabled : theme.designSystem.textColors.link;
  }};
  font-weight: 500;
  text-decoration: none;
  line-height: ${({ $isSmall }): string =>
    `${typeography.lineHeight[getLineHeight($isSmall ? 'condensed' : 'default')]}rem`};
  font-size: ${({ $isSmall }): string => typeography.fontSize[getFontSize($isSmall ? 'bodySmall' : 'bodyRegular')]};

  @media (any-pointer: fine) {
    &:hover {
      color: ${({ theme, $disabled }) => {
        return $disabled ? theme.designSystem.textColors.disabled : theme.designSystem.textColors.linkHover;
      }};
      text-decoration: ${({ $disabled }) => {
        return $disabled ? 'none' : 'underline';
      }};
    }
  }
`;

/**
 * It looks like a link but behaves like a button. AKA it looks like a link, but has an onClick event like a button.
 * [Figma](https://www.figma.com/file/AiDAD1WpprgfbZI2XV6hU1/Casting-Networks-Design-System?type=design&node-id=271-1&mode=dev)
 * See the "Links" section
 *
 * Use this when you are simply navigating from page to view/page but the design commands a button look.
 *
 * This is a customized component that would replace a `<Link as="Button" onClick={() => {}} />` component
 * that would LOOK like a link but have an onClick handler and not a "to" prop.
 *
 * @example
 * <LinkAsButton onClick={() => {}} label="Profile" />
 * <LinkAsButton onClick={() => {}}>Profile</LinkAsButton>
 */
export const LinkAsButton: FC<PropsWithChildren<LinkButtonProps>> = ({
  label,
  children,
  disabled = false,
  className,
  onClick,
  isSmall,
  ...rest
}) => {
  return (
    <StyledLinkAsButton
      {...rest}
      role={disabled ? undefined : 'button'}
      $isSmall={isSmall}
      $disabled={disabled}
      className={classNames(className, { disabled })}
      onClick={disabled ? () => undefined : onClick}
    >
      {label || children}
    </StyledLinkAsButton>
  );
};
