import React from 'react';
import styled from 'styled-components';
import { FlexRow, DetailTextLarge } from '@minecraft.atoms';
import { TileSchemaValue } from '@minecraft.graphql-operations';

const SelectionButton = styled.button<{ $selected: boolean }>`
  user-select: none;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0; // this makes sure the text is centered vertically in the "circle" button
  border-radius: 50%;
  margin: 0;
  padding: 0;
  outline: none;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.designSystem.bgColors.selectionTagSelected : theme.designSystem.bgColors.selectionTag};
  border: 0.0625rem solid
    ${({ $selected, theme }) =>
      $selected
        ? theme.designSystem.borderColors.statusSelectionTagSelected
        : theme.designSystem.borderColors.statusSelectionTagNotSelected};

  &&&:focus {
    outline: 2px solid
      ${({ theme, $selected }) =>
        $selected
          ? theme.designSystem.borderColors.statusSelectionTagSelectedFocus
          : theme.designSystem.borderColors.statusSelectionTagNotSelectedFocus};
  }

  @media (any-pointer: fine) {
    &:hover {
      background-color: ${({ $selected, theme }) =>
        $selected
          ? theme.designSystem.bgColors.selectionTagSelectedHover
          : theme.designSystem.bgColors.selectionTagHover};
    }
  }

  &:active {
    background-color: ${({ $selected, theme }) =>
      $selected
        ? theme.designSystem.bgColors.selectionTagSelectedActive
        : theme.designSystem.bgColors.selectionTagActive};
  }
`;

type TileSchemaCode = TileSchemaValue['code'];

const SELECTION_TAGS: TileSchemaCode[] = ['1', '2', '3', '4', '5', '6', 'CB'];

export interface SelectionTagsProps {
  selectedValue: TileSchemaCode;
  'data-qa-id'?: string;
  onSelected: (newValue: TileSchemaCode) => void;
  disabled?: boolean;
}

export const SelectionTags = ({ selectedValue, 'data-qa-id': dataQaId, onSelected, disabled }: SelectionTagsProps) => {
  return (
    <FlexRow
      alignItems="center"
      className="cn_w-full cn_my-2"
      data-qa-id={dataQaId}
      data-testid="selection-tag-container"
      gap="1"
      justifyContent="space-evenly"
      wrap="nowrap"
      grow={0}
    >
      {SELECTION_TAGS.map((tag) => {
        const isSelected = selectedValue === tag;

        return (
          <SelectionButton
            $selected={isSelected}
            aria-selected={isSelected}
            disabled={typeof onSelected !== 'function' || disabled}
            key={tag}
            onClick={() => onSelected(tag)}
            type="button"
          >
            <DetailTextLarge color={isSelected ? 'inverted' : 'link'} fontWeight="semibold" lineHeight="ultraCondensed">
              {tag}
            </DetailTextLarge>
          </SelectionButton>
        );
      })}
    </FlexRow>
  );
};
