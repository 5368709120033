import React, { FC, useState } from 'react';
import {
  BasicContainer,
  BodyText,
  BodyTextLarge,
  ClickableIcon,
  FlexCol,
  FlexRow,
  H1Text,
  LinkAsButton,
  Loader,
  PrimaryButton,
} from '@minecraft.atoms';
import FormikInput from '@minecraft.formik-input';
import { Form, Formik } from 'formik';
import { useGlobalNotification } from '@minecraft.notification-provider';
import { Yup } from '@minecraft.utils';
import Skeleton from 'react-loading-skeleton';
import { Trans, useTranslation } from '@blocs.i18n';
import { priceFormatter } from '../helpers/priceFormatter';
import { generateUniqueId } from '../helpers/functions';
import { AcceptTrialCheckbox, TrialPlanLegalese, TrialTermLink } from './Legal';
import { PaymentSummaryProps } from './types';
import { MediaPurchase } from '../enums/plans';

const couponSchema = Yup.object().shape({});

export const PaymentSummary: FC<PaymentSummaryProps> = ({
  currentPurchase,
  currencyCode,
  countryCode,
  onApplyCoupon,
  applyCouponError,
  couponLoading,
  onRemoveCoupon,
  onCompletePurchase,
  completePurchaseLoading,
  completePurchaseError,
  selectedPaymentOption,
  selectedPlan,
  allowPurchase,
  onNext = () => null,
  enableAnnualRenewal,
  disableCoupon = false,
  paywallLocation,
  purchaseableItems,
  closeBillingPopup,
}) => {
  const [acceptedTrialTerms, setAcceptedTrialTerms] = useState<boolean>(false);
  const { t } = useTranslation();
  const { showMessage } = useGlobalNotification();
  const onSubmitCoupon = (e: { promoCode?: string }) => {
    return onApplyCoupon({ couponCode: e.promoCode });
  };

  const chargeableItems = purchaseableItems?.filter((item) => item?.price > 0);
  const hasCreditsApplied = currentPurchase?.creditsApplied > 0;
  const hasDiscount = currentPurchase?.discount > 0;
  const isTrialPlan = selectedPlan?.isTrialPeriodEnabled;
  const price = priceFormatter({
    price: currentPurchase?.total ?? 0,
    currencyCode,
    countryCode,
    t,
  });
  const completePurchaseCta = isTrialPlan ? selectedPlan?.cta : t('common:paywall.summary.pay', { price });
  const billingCode = selectedPlan?.billingCode ?? currentPurchase?.subscription?.plan?.billingCode;
  const uniqueId = generateUniqueId({ plan: selectedPlan, paywallLocation, customPrefix: 'complete-purchase-button' });

  const handleCompletePurchase = async () => {
    try {
      await onCompletePurchase(acceptedTrialTerms);
      showMessage({
        message: t('common:paywall.payment.success'),
        color: 'success',
      });
      onNext();
    } catch (_) {
      showMessage({
        message:
          selectedPlan?.billingCode === MediaPurchase.alacarte
            ? t('common:paywall.errors.MEDIA_PURCHASE_ERROR')
            : t('common:paywall.payment.error'),
        color: 'paywallError',
      });
    }
  };

  return (
    <FlexCol gap="4">
      <BasicContainer w="full" bgColor="listItem" padding={6} borderRadius="sm">
        {!(enableAnnualRenewal || isTrialPlan) && (
          <>
            <Formik<{ promoCode?: string }>
              initialValues={{ promoCode: '' }}
              validationSchema={couponSchema}
              onSubmit={onSubmitCoupon}
            >
              {({ values, errors }) => (
                <Form>
                  <FlexRow w="full" gap="4" justifyContent="center" wrap="nowrap" data-testid="coupon-entry-container">
                    <BasicContainer w="full">
                      <FormikInput
                        name="promoCode"
                        type="text"
                        placeholder={t('common:paywall.summary.couponLabel')}
                        disabled={disableCoupon}
                        withOnChange={(e) => {
                          if (e.target.value.length === 0) {
                            onSubmitCoupon({});
                          }
                        }}
                      />
                      {/* Forced to do this since initialErrors doesn't render properly in FormikInput, also padding is infused */}
                      {applyCouponError && !errors.promoCode && (
                        <BasicContainer className="cn_mt-1">
                          <BodyText color="error">{applyCouponError}</BodyText>
                        </BasicContainer>
                      )}
                    </BasicContainer>
                    <PrimaryButton
                      full={false}
                      type="submit"
                      label={t('common:paywall.summary.apply')}
                      isSmall
                      disabled={!values?.promoCode || disableCoupon}
                    />
                  </FlexRow>
                </Form>
              )}
            </Formik>
            <hr />
          </>
        )}
        <FlexCol data-qa-id="paywall-payment-summary" gap="3">
          {currentPurchase && (
            <>
              <FlexRow data-qa-id="paywall-membership-summary" w="full" justifyContent="space-between">
                <FlexCol>
                  <BodyTextLarge color="emphasis" fontWeight="semibold">
                    <Trans
                      i18nKey={`common:paywall.success.orderSummary.${billingCode}`}
                      components={[<br />]}
                      values={{ count: chargeableItems?.length ?? 0 }}
                    />
                  </BodyTextLarge>
                </FlexCol>
                <Loader loading={couponLoading} LoaderComponent={<Skeleton width={100} height="1.2rem" />}>
                  {purchaseableItems?.length > 0 ? (
                    <BodyTextLarge color="emphasis" fontWeight="semibold">
                      {priceFormatter({
                        price: Number(currentPurchase?.total ?? 0) + Number(currentPurchase?.discount ?? 0),
                        currencyCode,
                        countryCode,
                        t,
                      })}
                    </BodyTextLarge>
                  ) : (
                    <BodyTextLarge color="emphasis" fontWeight="semibold">
                      {priceFormatter({
                        price: Number(
                          isTrialPlan
                            ? currentPurchase?.total
                            : currentPurchase?.subscription?.plan.price ?? currentPurchase?.total ?? 0
                        ),
                        currencyCode,
                        countryCode,
                        t,
                      })}
                    </BodyTextLarge>
                  )}
                </Loader>
              </FlexRow>
              {hasCreditsApplied && (
                <FlexRow data-qa-id="paywall-payment-credits-applied" w="full" justifyContent="space-between">
                  <BodyTextLarge color="emphasis" fontWeight="semibold">
                    {t('common:membership.subscription.creditsApplied')}
                  </BodyTextLarge>

                  <Loader loading={couponLoading} LoaderComponent={<Skeleton width={100} height="1.2rem" />}>
                    <BodyTextLarge color="emphasis" fontWeight="semibold">
                      {priceFormatter({ price: -(currentPurchase?.creditsApplied ?? 0), currencyCode, countryCode, t })}
                    </BodyTextLarge>
                  </Loader>
                </FlexRow>
              )}
            </>
          )}
          <Loader loading={couponLoading} LoaderComponent={<Skeleton containerClassName="cn_w-full" />}>
            {currentPurchase?.coupon && (
              <FlexRow
                w="full"
                justifyContent="space-between"
                data-testid="coupon-container"
                alignItems="center"
                wrap="nowrap"
              >
                <FlexRow alignItems="center">
                  <FlexCol grow={0}>
                    <BodyTextLarge data-qa-id="applied-coupon-code" color="emphasis" fontWeight="semibold">
                      {currentPurchase?.coupon?.code}
                    </BodyTextLarge>
                    {currentPurchase?.coupon?.description && (
                      <BodyTextLarge data-qa-id="applied-coupon-code-description" color="emphasis">
                        {currentPurchase?.coupon?.description}
                      </BodyTextLarge>
                    )}
                  </FlexCol>
                  <ClickableIcon
                    name="close"
                    isSmall
                    aria-label="Remove coupon"
                    onClick={() => onRemoveCoupon()}
                    className="cn_ml-4"
                  />
                </FlexRow>

                {hasDiscount && (
                  <BodyTextLarge data-qa-id="applied-discount" color="emphasis" fontWeight="semibold">
                    {priceFormatter({ price: -(currentPurchase?.discount ?? 0), currencyCode, countryCode, t })}
                  </BodyTextLarge>
                )}
              </FlexRow>
            )}
          </Loader>
        </FlexCol>
        <hr />
        <FlexRow w="full" justifyContent="space-between">
          <BodyTextLarge color="emphasis" fontWeight="semibold">
            {t('common:paywall.summary.total')}
          </BodyTextLarge>
          <Loader loading={couponLoading} LoaderComponent={<Skeleton width={100} height="1.2rem" />}>
            <H1Text data-qa-id="payment-total" color="emphasis" as="p">
              {priceFormatter({ price: currentPurchase?.total ?? 0, currencyCode, countryCode, t })}
            </H1Text>
          </Loader>
        </FlexRow>
      </BasicContainer>
      <BodyText>
        <Trans i18nKey="common:paywall.summary.terms" components={{ a: <TrialTermLink /> }} />
      </BodyText>
      {isTrialPlan && (
        <>
          <TrialPlanLegalese billingCode={billingCode} selectedPlan={selectedPlan} countryCode={countryCode} />
          <AcceptTrialCheckbox checked={acceptedTrialTerms} onChange={setAcceptedTrialTerms} />
        </>
      )}
      <Loader loading={!currentPurchase} LoaderComponent={<Skeleton containerClassName="cn_w-full" height="3rem" />}>
        <PrimaryButton
          full
          onClick={handleCompletePurchase}
          label={completePurchaseCta}
          disabled={
            completePurchaseLoading || !selectedPaymentOption || !allowPurchase || (isTrialPlan && !acceptedTrialTerms)
          }
          data-unique-id={uniqueId}
          data-qa-id="complete-purchase-button"
        />
        {purchaseableItems?.length > 0 && (
          <LinkAsButton
            className="cn_w-full cn_text-center"
            label={t('common:paywall.purchaseMedia.keepMediaLock')}
            onClick={() => closeBillingPopup?.()}
          />
        )}
        {completePurchaseError && <BodyText color="error">{completePurchaseError}</BodyText>}
      </Loader>
    </FlexCol>
  );
};
