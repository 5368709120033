import React, { ReactNode, FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';
import _debounce from 'lodash/debounce';
import Dropdown from '@minecraft.dropdown';
import DropdownMenu from '@minecraft.dropdown-menu';
import DropdownItem from '@minecraft.dropdown-item';
import DropdownToggle from '@minecraft.dropdown-toggle';
import Icon from '@minecraft.icon';
import { FlexCol, FlexRow, ClickableIcon, BodyText } from '@minecraft.atoms';
import Checkbox from '@minecraft.checkbox';
import { RadioInput } from './RadioInput';

interface OptionalProps {
  className?: string;
  qaId?: string;
  testId?: string;
}

export type DefaultOptionType = Record<string, any> | string;

export type LabelKey<T = DefaultOptionType> = string | ((val: T) => string);

const StyledToggleIcon = styled(Icon)`
  background: transparent;
  border: 0;
  bottom: 0;
  position: absolute;
  right: 1.5rem;
  top: 0.35rem;
  width: 1.25rem;
  height: 1.5rem;
`;

const buildOptionalProps = ({ testId, qaId, className }: OptionalProps) => {
  return {
    ...(typeof testId === 'string' && testId ? { 'data-testid': testId } : {}),
    ...(typeof qaId === 'string' && qaId ? { 'data-qa-id': qaId } : {}),
    ...(typeof className === 'string' && className ? { className } : {}),
  };
};

const resolveLabelValue = <T extends DefaultOptionType>(value: T, labelKey?: LabelKey<T>): string => {
  if (typeof value !== 'string' && typeof labelKey === 'function') {
    return labelKey(value);
  }

  if (typeof value !== 'string' && typeof labelKey === 'string') {
    return value?.[labelKey];
  }

  return typeof value === 'string' ? value : '';
};

const resolveIdValue = <T extends DefaultOptionType>(value: T): string => {
  return typeof value === 'string' ? value : value?.id;
};

interface TypeaheadMenuItemProps<T> {
  value: T;
  selectedVals: string[];
  labelKey?: LabelKey<T>;
  multiple?: boolean;
}

const TypeaheadMenuItem = <T extends DefaultOptionType>({
  value,
  labelKey = 'label',
  selectedVals,
  multiple,
}: TypeaheadMenuItemProps<T>) => {
  const labelVal = resolveLabelValue(value, labelKey);
  const fieldVal = resolveIdValue(value);
  const checked = selectedVals.includes(fieldVal);

  if (multiple) {
    return (
      <Checkbox
        isChecked={checked}
        onChange={(e) => e.preventDefault()}
        label={labelVal}
        tabIndex={-1}
        noMargin
        className="cn_my-1"
      />
    );
  }

  return (
    <RadioInput
      tabIndex={-1}
      value={fieldVal}
      onChange={() => undefined}
      checked={checked}
      label={labelVal}
      className="cn_my-1"
      size="lg"
    />
  );
};

export interface AsyncTypeaheadInputProps<T = DefaultOptionType> {
  /**
   * Unique id to associate the menu with the toggle
   */
  id: string;
  /**
   * flag to indicate if the search is loading options asynchronously
   */
  isLoading?: boolean;
  /**
   * disables the search input
   */
  disabled?: boolean;
  testId?: string;
  qaId?: string;
  className?: string;
  /**
   * array of options that are currently selected (these appear as the pills beneath the input)
   */
  selected: T[];
  /**
   * array of options to populate the menu for selection
   */
  options: readonly T[];
  /**
   * used when options are an array of objects to determine the label to display for each
   */
  labelKey?: string | ((value: T) => string);
  /**
   * whether to allow multiple selections (checkboxes) or a single selection (radio buttons)
   */
  multiple?: boolean;
  /**
   * callback invoked when an option is selected or cleared with the new list of selected item
   */
  onChange?: (newVals: T[]) => void;
  /**
   * callback invoked when the search input value changes (used to trigger a search for options)
   */
  onSearch?: (newSearch: string) => void;
  /**
   * text to display in the menu when no options were found for a search (default: "No Results")
   */
  emptyLabel?: string;
  /**
   * text to display in the menu isLoading is true (default: "Loading...")
   */
  searchText?: string;
  /**
   * placeholder text in the search input (default: "Search")
   */
  placeholder?: string;
  /**
   * callback to use if a user wants to add the current search value as a new option
   * (this is useful if the user is allowed to create new options)
   *  - if this is not provided, the option entry to add a new item will not be displayed
   */
  onAddNew?: (newVal: string) => Promise<T>;
  /**
   * text to display in the option to add a new item (default: "Add New")
   */
  addNewLabel?: string | ((currentSearchVal: string) => string);
  /**
   * error message to display if an error has occurred
   * - if this is not provided, the error message will not be displayed
   * - this is most useful if the async fetch fails with an error, or the add new option fails with an error
   */
  errorMessage?: string;
  /**
   * maximum length of the search input value
   * - if this is not provided, the search input will not have a max length
   * - this is most useful when using the onAddNew callback to prevent the user from entering a very long string
   */
  maxSearchLength?: number;
  /**
   * whether to show the pills in the search input
   * - this is useful so that the form field stays the same size as other regular inputs
   */
  showPillsInSearch?: boolean;
  /**
   * a custom react component that will render the menu items
   * instead of the default menu items
   * this component will be passed the data for the item, the current list of selected items,
   * whether it's a multi-select and the labelKey to use to display the label for the item
   */
  MenuItemComponent?: FC<TypeaheadMenuItemProps<T>>;
  /**
   * name of an icon to display in the search input (default is a caret)
   */
  iconName?: string;
  /**
   * delay in milliseconds to wait after the user stops typing before firing the onSearch callback
   */
  debounceDelay?: number;
  /**
   * flag to indicate if the menu should close when an option is selected
   * this value is ignored if multiple is true
   * default behavior is to keep the menu open after a selection
   */
  closeOnSelect?: boolean;
  showSelectedPills?: boolean;
}

interface SelectedItemPillProps<T = DefaultOptionType> {
  item: T;
  labelKey?: LabelKey<T>;
  multiple: boolean;
  handleChange: (newVal: T, e?: React.MouseEvent | React.TouchEvent) => void;
}

const SelectedItemPill = <T extends DefaultOptionType>({
  item,
  labelKey,
  multiple,
  handleChange,
}: SelectedItemPillProps<T>) => {
  const { t } = useTranslation();
  const selectedLabel = resolveLabelValue(item, labelKey);

  return (
    <FlexRow
      justifyContent="space-between"
      alignItems="center"
      bgColor="comboboxToken"
      gap="2"
      className="cn_atom_px-2 cn_atom_py-1"
      key={resolveIdValue(item)}
      grow={0}
      borderRadius="sm"
    >
      {selectedLabel}
      <ClickableIcon
        aria-label={`${t('common:button.removeItem', { itemName: selectedLabel })}`}
        isSecondary
        onClick={() => handleChange(multiple ? item : null)}
        isSmall
        name="close"
      />
    </FlexRow>
  );
};

type ToggleInputInheritedProps =
  | 'disabled'
  | 'labelKey'
  | 'maxSearchLength'
  | 'onSearch'
  | 'placeholder'
  | 'selected'
  | 'iconName'
  | 'showPillsInSearch';

interface ToggleInputProps<T> extends Pick<AsyncTypeaheadInputProps<T>, ToggleInputInheritedProps> {
  value: string;
  testId: string;
  qaId: string;
  className: string;
  name: string;
  // this ref is injected by the DropdownToggle that renders this
  // and is needed for the menu to be correctly placed
  innerRef: ReturnType<typeof React.createRef<HTMLDivElement>>;
}

const ToggleInput = <T extends DefaultOptionType>({
  value,
  onSearch,
  testId,
  qaId,
  className,
  placeholder,
  children,
  innerRef,
  name,
  disabled,
  maxSearchLength,
  showPillsInSearch = false,
  selected,
  labelKey,
  iconName = 'caret-1',
  ...toggleProps // includes aria & onclick props passed from DropdownToggle
}: ToggleInputProps<T> & { children?: ReactNode }) => {
  const selectedItems = useMemo(() => {
    return selected.map((s) => resolveLabelValue(s, labelKey)).join(', ');
  }, [selected, labelKey]);

  return (
    <div
      ref={innerRef}
      {...toggleProps}
      {...buildOptionalProps({ testId: `${testId}-wrapper`, qaId: `${qaId}-wrapper`, className })}
    >
      <FlexRow gap="2" className={['cn_w-full', 'form-control', 'cn_atom_grow-1'].join(' ')}>
        {showPillsInSearch && selectedItems}
        <input
          autoComplete="off"
          name={name}
          type="text"
          value={value}
          onChange={(e) => onSearch(e.target.value?.slice(0, maxSearchLength))}
          placeholder={showPillsInSearch && selectedItems ? '' : placeholder}
          disabled={disabled}
          aria-label={placeholder}
          {...buildOptionalProps({ testId: `${testId}-input`, qaId: `${qaId}-input` })}
          className="cn_p-0 cn_border-none cn_outline-none cn_w-full"
        />
        {children}
        <StyledToggleIcon name={iconName} />
      </FlexRow>
    </div>
  );
};

/**
 * A typeahead dropdown that allows for asynchronous search to populate the dropdown menu
 * [Figma](https://www.figma.com/file/JqmKbNBNaxfddEwMAIJgTa/JIRA---CICN-14---Phase-1---Creatives---Lists---Ability-to-View-contents-of-List-%26-take-actions?node-id=2%3A8&t=Suep50SfzN5YqCOY-0)
 * See "Copy talent to another list" modal for example with multiple=true
 * See "Move talent to another list" modal for example with multiple=false
 * @example
 * const [selectedCopyToLists, setSelectedLists] = useState<gqlListGqlModel[]>([]);
 * const [allProjectListsSearchVal, setAllProjectListsSearchVal] = useState<string>('');
 * const { loading: setAllProjectListsLoading, data: listsData } = useQuery<GetListsOutput, GetListsInput>(GET_LISTS, {
    variables: {
      filter: { name: allProjectListsSearchVal },
      pageSize: 50,
      pageNumber: 1,
    },
  });
  const searchListsOptions = useMemo(() => (listsData?.getLists?.data || []).concat([]), [listsData]);

 * <AsyncTypeaheadInput<gqlListGqlModel>
      id="unique-descriptive-id"
      options={searchListsOptions}
      isLoading={setAllProjectListsLoading}
      selected={selectedCopyToLists}
      onSearch={setAllProjectListsSearchVal}
      onChange={setSelectedLists}
      labelKey={(list) => `${list?.name} (${list?.project?.name})`}
      multiple
      placeholder={t('common:button.searchItem', { itemName: t('casting:project.label.lists') })}
    />
 */
export const AsyncTypeaheadInput = <T extends DefaultOptionType>({
  addNewLabel,
  className,
  closeOnSelect = false,
  debounceDelay = 250,
  disabled = false,
  emptyLabel,
  errorMessage,
  iconName,
  id,
  isLoading = false,
  labelKey = 'label',
  maxSearchLength,
  MenuItemComponent = TypeaheadMenuItem,
  multiple,
  onAddNew,
  onChange,
  onSearch = (_) => null,
  options = [],
  placeholder,
  qaId,
  searchText,
  selected = [],
  showPillsInSearch = false,
  testId,
  showSelectedPills = true,
}: AsyncTypeaheadInputProps<T>) => {
  const { t } = useTranslation();
  const qaIdBase = qaId || 'async-typeahead';
  const testIdBase = testId || 'async-typeahead';
  const [searchVal, setSearchVal] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const selectedVals = useMemo((): string[] => {
    return selected.map((s) => resolveIdValue(s));
  }, [selected]);
  const allOpts = useMemo(() => {
    const optionVals = options.map((o) => resolveIdValue(o));
    const selectedWithoutOptions = selected.filter((s) => !optionVals.includes(resolveIdValue(s)));

    return selectedWithoutOptions.concat(options);
  }, [options, selected]);
  const handleChange = useCallback(
    (newVal: T, e?: React.MouseEvent | React.TouchEvent) => {
      if (showPillsInSearch) {
        setSearchVal('');
      }

      if (!multiple) {
        onChange(newVal ? [newVal] : []);

        if (closeOnSelect) {
          setOpen(false);
        }

        return;
      }

      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      const idVal = resolveIdValue(newVal);

      if (selectedVals.includes(idVal)) {
        onChange(selected.filter((v) => resolveIdValue(v) !== idVal));
      } else {
        onChange(selected.concat([newVal]));
      }
    },
    [onChange, selectedVals, selected, multiple, showPillsInSearch, closeOnSelect]
  );

  const debouncedOnSearch = useMemo(() => _debounce(onSearch, debounceDelay), [onSearch, debounceDelay]);

  const handleSearch = useCallback(
    (newVal: string) => {
      debouncedOnSearch(newVal);
      setSearchVal(newVal);
    },
    [debouncedOnSearch]
  );
  const addLabel = useMemo(() => {
    if (typeof addNewLabel === 'function') {
      return addNewLabel(searchVal);
    }

    if (typeof addNewLabel === 'string') {
      return addNewLabel;
    }

    return t('common:label.addNew');
  }, [addNewLabel, searchVal, t]);

  const searchValExists = useMemo(() => {
    return allOpts.some((o) => resolveLabelValue(o, labelKey) === searchVal);
  }, [searchVal, allOpts, labelKey]);

  useEffect(() => {
    return () => {
      debouncedOnSearch.cancel();
    };
  }, [debouncedOnSearch]);

  return (
    <FlexCol
      data-testid={`${testIdBase}-wrapper`}
      qaId={`${qaIdBase}-wrapper`}
      className={['cn_w-full', className].join(' ')}
    >
      <Dropdown
        id={id}
        isOpen={open}
        disabled={disabled}
        toggle={() => setOpen(!open)}
        {...buildOptionalProps({
          testId: `${testIdBase}-dropdown`,
          qaId: `${qaIdBase}-dropdown`,
          className: 'cn_w-full',
        })}
      >
        <DropdownToggle
          tag={ToggleInput}
          name={`${testIdBase}-search-input`}
          value={searchVal}
          testId={`${testIdBase}-toggle`}
          qaId={`${qaIdBase}-toggle`}
          onSearch={(newSearch: string) => {
            if (!open) {
              setOpen(true);
            }

            handleSearch(newSearch);
          }}
          iconName={iconName}
          disabled={disabled}
          placeholder={placeholder || t('common:button.search')}
          maxSearchLength={maxSearchLength}
          showPillsInSearch={showPillsInSearch}
          selected={selected}
          labelKey={labelKey}
        />
        <DropdownMenu
          {...buildOptionalProps({ testId: `${testIdBase}-menu`, qaId: `${qaIdBase}-menu` })}
          style={{ right: '0px', maxHeight: '14rem', overflowX: 'hidden', overflowY: 'scroll' }}
        >
          {errorMessage && (
            <DropdownItem disabled>
              <BodyText fontWeight="semibold" color="error">
                {errorMessage}
              </BodyText>
            </DropdownItem>
          )}
          {allOpts.map((option) => {
            const idVal = resolveIdValue(option);

            return (
              <DropdownItem
                key={idVal}
                onClick={(e) => {
                  handleChange(option, e);
                }}
                toggle={false}
              >
                <MenuItemComponent value={option} labelKey={labelKey} selectedVals={selectedVals} multiple={multiple} />
              </DropdownItem>
            );
          })}
          {onAddNew && searchVal && !searchValExists && (
            <DropdownItem
              key="add-new"
              onClick={async (e) => {
                try {
                  const newVal = await onAddNew(searchVal);

                  if (newVal) {
                    handleChange(newVal, e);
                  }
                } catch (err) {
                  console.error('failed to add new item', { err });
                }
              }}
              toggle={false}
              disabled={disabled}
            >
              {addLabel}
            </DropdownItem>
          )}
          {isLoading && <DropdownItem disabled>{searchText || t('common:loading.loadingMessage')}</DropdownItem>}
          {!isLoading && options.length === 0 && (
            <DropdownItem disabled>{emptyLabel || t('common:filter.noResults')}</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      {!showPillsInSearch && selected.length > 0 && showSelectedPills && (
        <FlexRow
          data-testid={`${testIdBase}-pills-wrapper`}
          qaId={`${qaIdBase}-pills-wrapper`}
          gap="2"
          className={['cn_w-full cn_my-2', className].join(' ')}
        >
          {selected.map((s) => (
            <SelectedItemPill
              key={resolveIdValue(s)}
              item={s}
              multiple={multiple}
              handleChange={handleChange}
              labelKey={labelKey}
            />
          ))}
        </FlexRow>
      )}
    </FlexCol>
  );
};
