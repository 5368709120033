import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface AnimatedProgressBarProps {
  type?: 'gradient' | 'solid';
}

const loadingAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const gradientAnimation = keyframes`
  0%   { background-position: 0 0; }
  100% { background-position: -200% 0; }
`;

const ProgressBarContainer = styled.div`
  background-color: ${({ theme }) => theme.designSystem.progressIndicator.background};
  height: 1.5rem;
  width: 100%;
  border-radius: 48px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 48px;
  animation: ${loadingAnimation} 3s ease infinite;
  background-color: ${({ theme }) => theme.designSystem.progressIndicator.foreground};
`;

const ProgressBarGradient = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 48px;
  background: ${({ theme }) => {
    const { progressIndicator } = theme.designSystem;

    return `repeating-linear-gradient(to right, ${progressIndicator.background} 0%, ${progressIndicator.foreground} 50%, ${progressIndicator.background} 100%);`;
  }};
  background-size: 200% auto;

  animation: ${gradientAnimation} 2s infinite;
`;

const AnimationByType = {
  solid: ProgressBar,
  gradient: ProgressBarGradient,
};

function animationByType(type: AnimatedProgressBarProps['type']) {
  return AnimationByType[type];
}

export const AnimatedProgressBar: FC<AnimatedProgressBarProps> = React.memo(({ type = 'solid' }) => {
  const ProgressBar = animationByType(type);

  return (
    <ProgressBarContainer>
      <ProgressBar />
    </ProgressBarContainer>
  );
});
