import React, { PropsWithChildren } from 'react';
import { FlexRow, BodyText } from '@minecraft.atoms';

interface Props {
  label: string;
  wrapped?: boolean;
}

export const LabelValue = ({ label, wrapped = false, children }: PropsWithChildren<Props>) => {
  return (
    <FlexRow className="cn_mb-2" wrap={wrapped ? 'wrap' : 'nowrap'} gap="2">
      <BodyText fontWeight="bold">{label}:</BodyText>
      {children}
    </FlexRow>
  );
};
