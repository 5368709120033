import { useEffect, useMemo, useState } from 'react';
import { useRecurly } from '@recurly/react-recurly';
import { CountryEnum, CurrencyEnum } from '@minecraft.graphql-operations';

// Copied from Apple docs + Recurly docs
// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypayment
// https://recurly.com/developers/reference/recurly-js/#apple-pay:~:text=simplify%20the%20integration.-,%5Boptions.callbacks.onPaymentAuthorized%5D,-Function
export type ApplePayPayment = {
  // This is the recurly addition:
  payment: {
    recurlyToken: {
      id: string;
      type: 'credit_card';
    };
  };
  // This is the stock Apple callback structure:
  token: {
    paymentMethod: {
      displayName?: string;
      network?: string;
      type?: 'credit' | 'debit' | 'prepaid' | 'store';
      paymentPass?: {
        primaryAccountIdentifier: string;
        primaryAccountNumberSuffix: string;
        deviceAccountIdentifier?: string;
        deviceAccountNumberSuffix?: string;
        activationState: 'activated' | 'requiresActivation' | 'activating' | 'suspended' | 'deactivated';
      };
      billingContact: {
        phoneNumber?: string;
        emailAddress?: string;
        givenName?: string;
        familyName?: string;
        phoneticGivenName?: string;
        phoneticFamilyName?: string;
        addressLines?: string[];
        subLocality?: string;
        locality?: string;
        postalCode?: string;
        subAdministrativeArea?: string;
        administrativeArea?: string;
        country?: string;
        countryCode?: string;
      };
    };
    transactionIdentifier?: string;
    paymentData: never;
  };
};

export type UseApplePayProps = {
  onPaymentAuthorized: (data: any) => void;
  onError: (error: any) => void;
  amountToCharge: number;
  currency: CurrencyEnum;
  country: CountryEnum;
};

export const useApplePay = ({
  onPaymentAuthorized,
  amountToCharge,
  currency = 'USD' as CurrencyEnum,
  country = 'US' as CountryEnum,
  onError,
}: UseApplePayProps) => {
  const [showApplePayButton, setShowApplePayButton] = useState(false);
  const recurly = useRecurly();

  const canApplePay: boolean = useMemo(() => {
    return !!(window as any).ApplePaySession && !!country;
  }, [country]);

  const applePay = useMemo(() => {
    if (canApplePay) {
      return recurly.ApplePay({
        country,
        currency,
        total: String(amountToCharge),
        label: '',
        callbacks: {
          onPaymentAuthorized,
        },
      });
    }

    return undefined;
  }, [amountToCharge, canApplePay, country, currency, onPaymentAuthorized, recurly]);

  useEffect(() => {
    if (applePay) {
      applePay.on('ready', () => {
        setShowApplePayButton(true);
      });

      applePay.on('error', (err: any) => {
        onError(err);
      });
    }
  }, [applePay, onError]);

  const onClick = () => {
    applePay && applePay?.begin();
  };

  return {
    showApplePayButton,
    onClick,
  };
};
