import React, { useCallback, InputHTMLAttributes } from 'react';
import { Field, FieldProps } from 'formik';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import _size from 'lodash/size';
import { withErrorMessage } from '@minecraft.utils';
import Input from '@minecraft.input';
import { BodyText } from '@minecraft.atoms';

const AUTO_DISPLAY_LIMIT_LABEL_THRESHOLD = 150;

/** Types */
export interface FormikInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name: string;
  label?: string;
  showLimit?: boolean;
  showLabel?: boolean;
  maxLength?: number;
  type?: string;
  noCustomError?: boolean;
  onBlur?: () => void;
  withOnChange?: (event?: any) => void;
  isValid?: boolean;
}

/** Help Components */
const CustomInputComponent = ({
  field,
  form,
  label,
  value,
  isValid = true,
  showLabel = false,
  showLimit,
  minLength,
  maxLength,
  withOnChange,
  ...fieldProps
}): JSX.Element => {
  const inputValue = value || _get(form.values, field.name);
  const shouldDisplayLimit = _isUndefined(showLimit)
    ? maxLength > AUTO_DISPLAY_LIMIT_LABEL_THRESHOLD || _size(inputValue) >= maxLength
    : !!showLimit;

  const handleOnChange = useCallback(
    (e) => {
      field.onChange(e);

      if (withOnChange) {
        withOnChange(e);
      }
    },
    [field, withOnChange]
  );

  return (
    <>
      {showLabel && label && (
        <BodyText fontWeight="bold" as="label" className="cn_atom_pb-2" htmlFor={fieldProps.id || field.name}>
          {label}
        </BodyText>
      )}
      <Input
        id={fieldProps.id || field.name}
        name={field.name}
        data-testid={`input-${field.name}`}
        className={!isValid ? 'is-invalid' : ''}
        showLimit={shouldDisplayLimit && isValid}
        value={inputValue}
        minLength={minLength}
        maxLength={maxLength}
        {...field}
        {...fieldProps}
        onChange={handleOnChange}
      />
    </>
  );
};

/** Main Component */
export default React.memo<FormikInputProps>((props) => (
  <Field {...props}>
    {(fieldProps: FieldProps): JSX.Element =>
      withErrorMessage(CustomInputComponent)({ ...props, ...fieldProps, v: props.isValid })
    }
  </Field>
));
