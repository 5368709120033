import React, { FC } from 'react';
import styled from 'styled-components';
import { FlexRow, BodyText } from '../../atoms';

interface TabBoxHeaderProps {
  headerNames: { name: string; testId?: string }[];
  selectTab: (index: number) => void;
  selectedTab: number;
}

const Container = styled(FlexRow)`
  width: 95%;
`;

const BoxTab = styled.div<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  max-width: 6rem;
  height: 100%;
  padding: 0.25rem 0.75rem;
  margin-right: 1px;
  ${(props) => (!props.$isSelected ? `background-color: ${props.theme.colors.admin.nav.default};` : '')}
  border: 1px solid ${(props) => props.theme.designSystem.borderColors.default};
  border-bottom: 0;
  border-radius: 4px 4px 0px 0px;
`;

export const TabBoxHeader: FC<TabBoxHeaderProps> = ({ headerNames, selectTab, selectedTab }) => (
  <Container wrap="nowrap" role="tablist">
    {headerNames.map((val, i) => (
      <BoxTab
        role="tab"
        data-testid={val.testId}
        key={val.name}
        $isSelected={selectedTab === i}
        aria-selected={selectedTab === i}
        type="button"
        onClick={() => selectTab(i)}
      >
        <BodyText fontWeight="medium" truncatableNoWrap title={val.name}>
          {val.name}
        </BodyText>
      </BoxTab>
    ))}
  </Container>
);
