import React from 'react';
import Loader from '@minecraft.loader';
import styled from 'styled-components';

const LoaderWrap = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullLoader = (): JSX.Element => {
  return (
    <LoaderWrap>
      <Loader />
    </LoaderWrap>
  );
};

export default FullLoader;
