import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { DesignSystemBgColor } from '@minecraft.themes';
import { typeography as DesignSystemTypography } from '@minecraft.atoms';

const StyledBanner = styled.div<{ backgroundColor?: DesignSystemBgColor }>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor in theme.designSystem.bgColors ? theme.designSystem.bgColors[backgroundColor] : undefined};
  height: 1rem;
  text-align: center;
  line-height: ${DesignSystemTypography.lineHeight.ultraCondensed};
`;

type BannerProps = React.ComponentProps<typeof StyledBanner> & { backgroundColor?: DesignSystemBgColor };

export const Banner: React.FC<BannerProps> = ({ backgroundColor, children, ...props }) => {
  return (
    <StyledBanner {...props} backgroundColor={backgroundColor} className={classNames('cn_w-full', props.className)}>
      {children}
    </StyledBanner>
  );
};
