import React, { useState, useRef, FC, useEffect } from 'react';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { useQuery } from '@apollo/client';
import { useTranslation, Trans, TranslateFn } from '@blocs.i18n';
import { WindowWidthContext, useExternalScript } from '@minecraft.utils';
import { getEnvironment } from '@minecraft.environment';
import Modal, { useModal } from '@minecraft.modal';
import Container from '@minecraft.container';
import Row from '@minecraft.row';
import Col from '@minecraft.col';
import Loader from '@minecraft.loader';
import ErrorMessage from '@minecraft.error-message';
import SCREEN_NAMES from '../../common/screenNames';
import { AddCard, CreditCard } from '../CreditCard';
import { GET_BILLING_INFO } from '../graphqls';
import MainForm from './MainForm';
import { RECURLY_PUBLIC_KEY, BILLING_PLATFORM_SCRIPT_URL } from './constants';
import {
  FormFieldsWrapper,
  StyledElementsContainer,
  StyledSecondaryBtn,
  StyledBackBtnContainer,
  StyledFormSectionHeader,
  StyledInnerContainer,
  SummaryHeader,
} from './styles';

type WidthData = WindowWidthContext.WidthData;

export type CreditCardFormProps = {
  creditCardHeaderText?: string;
  summaryHeaderText?: string;
  artistId?: number | string;
  goBack?: () => void;
  handlePurchaseCancelation?: () => void;
  title?: string;
  isUpdateBillingInfo?: boolean;
  isAddModal?: boolean;
  checkout?: boolean;
  completeButtonText?: string;
  className?: string;
};

const getCancelModalText = (isUpdateBillingInfo: boolean, t: TranslateFn): { label: string; body: string } => {
  return {
    label: isUpdateBillingInfo ? t('common:creditCardForm.cancelEdit') : t('common:creditCardForm.cancelPurchase'),
    body: isUpdateBillingInfo
      ? t('common:creditCardForm.cancelEditBody')
      : t('common:creditCardForm.cancelPurchaseBody'),
  };
};

const addNewState = 'addNewState';

export const CreditCardForm: FC<CreditCardFormProps> = ({
  creditCardHeaderText,
  summaryHeaderText,
  artistId,
  goBack,
  handlePurchaseCancelation,
  title,
  isUpdateBillingInfo = false,
  isAddModal = false,
  checkout = false,
  completeButtonText,
  className,
}): JSX.Element => {
  const { t } = useTranslation();
  const config = getEnvironment();
  const billingPlatformPublicKey = config[RECURLY_PUBLIC_KEY];
  const [externalScriptLoaded, error] = useExternalScript(BILLING_PLATFORM_SCRIPT_URL);
  const [modalOpen, setModalOpen] = useState(false);
  const { isOpen: addModalIsOpen, toggleModal: toggleAddModal } = useModal();
  const [selectedCard, setSelectedCard] = useState(null);
  const formRef = useRef();
  const addFormRef = useRef();

  const widthData: WidthData = WindowWidthContext.useWidthData();
  const completeBtnTranslated = completeButtonText || t('common:creditCardForm.completePurchase');
  const editButtonText = selectedCard?.id ? t('common:button.update') : t('common:button.add');
  const buttonText = isUpdateBillingInfo ? editButtonText : completeBtnTranslated;

  const {
    data: billingData,
    error: billingError,
    loading: billingLoading,
  } = useQuery(GET_BILLING_INFO, {
    variables: { input: {} },
    fetchPolicy: 'network-only',
    skip: isAddModal,
  });

  useEffect(() => {
    if (billingData?.wallet[0] && !selectedCard) {
      setSelectedCard(billingData?.wallet.find((card: { isPrimaryPaymentMethod: any }) => card.isPrimaryPaymentMethod));
    }
  }, [billingData?.wallet, selectedCard]);

  const handleCancelButtonClick = (): void => {
    setModalOpen(true);
  };

  const getBackButton = (): JSX.Element => {
    return (
      <StyledBackBtnContainer>
        <StyledSecondaryBtn type="button" onClick={goBack} id={`${SCREEN_NAMES.PROFILE_CHECKOUT}-go-back`}>
          <Trans i18nKey="common:button.backWArrow">&lt;</Trans>
        </StyledSecondaryBtn>
      </StyledBackBtnContainer>
    );
  };

  const proceedToSuccessPage = (): void => {
    setSelectedCard(null);
  };

  const cancelModalText = getCancelModalText(isUpdateBillingInfo, t);
  const showCardList = (checkout && !!billingData?.wallet?.length) || (!checkout && !isAddModal);

  if ((!externalScriptLoaded && !error) || billingLoading) return <Loader />;

  if (!externalScriptLoaded || error || billingError) return <ErrorMessage error={error || billingError} />;

  return (
    <FormFieldsWrapper className={className}>
      {widthData.moreMd && title && <SummaryHeader>{title}</SummaryHeader>}
      <Container data-testid="wallet-container">
        <Row className={`${checkout ? 'flex-row-reverse' : ''}`}>
          {showCardList && (
            <Col lg={{ size: 5, offset: !checkout ? 1 : 0 }}>
              {isUpdateBillingInfo && <h3>{t('common:billing.wallet')}</h3>}
              {billingData?.wallet.map((card) => (
                <CreditCard
                  isSelected={card.id === selectedCard?.id}
                  isPrimary={card.isPrimaryPaymentMethod}
                  isBackup={card.isBackupPaymentMethod}
                  cardType={card.cardType}
                  paymentType={card.paymentType}
                  lastFour={card.lastFour}
                  onSelect={() => setSelectedCard(card)}
                  hideArrow={checkout}
                  key={card.id}
                />
              ))}
              <AddCard
                isSelected={selectedCard === addNewState}
                onSelect={checkout ? toggleAddModal : () => setSelectedCard(addNewState)}
              />
            </Col>
          )}
          <Col>
            {isUpdateBillingInfo && (
              <h3>{selectedCard?.id ? t('common:billing.card.edit') : t('common:billing.card.add')}</h3>
            )}
            <RecurlyProvider publicKey={billingPlatformPublicKey} required={['cvv', 'postal_code']}>
              <Elements>
                <StyledElementsContainer hideBillingSummary>
                  <StyledInnerContainer>
                    {widthData.lessMd && (
                      <>
                        {goBack && getBackButton()}
                        {!isUpdateBillingInfo && <SummaryHeader>{t('common:creditCardForm.checkout')}</SummaryHeader>}
                      </>
                    )}

                    <StyledFormSectionHeader>{creditCardHeaderText}</StyledFormSectionHeader>
                    <MainForm
                      formRef={formRef}
                      widthData={widthData}
                      showBillingSummary={!isUpdateBillingInfo}
                      summaryHeaderText={summaryHeaderText}
                      getBackButton={goBack && getBackButton}
                      handleCancelButtonClick={handleCancelButtonClick}
                      artistId={artistId}
                      completeButtonText={buttonText}
                      isUpdateBillingInfo={isUpdateBillingInfo}
                      proceedToSuccessPage={proceedToSuccessPage}
                      selectedCard={selectedCard}
                      hideForm={checkout && showCardList}
                      handlePurchaseCancelation={handlePurchaseCancelation}
                    />
                  </StyledInnerContainer>
                  <Modal
                    isOpen={modalOpen}
                    title={cancelModalText.label}
                    submitLabel={cancelModalText.label}
                    cancelLabel={t('common:button.continue')}
                    handleToggleClick={(): void => {
                      setModalOpen(false);
                    }}
                    handleSubmitClick={handlePurchaseCancelation}
                    data-qa-id={`${SCREEN_NAMES.PROFILE_CHECKOUT}-cancel`}
                  >
                    {cancelModalText.body}
                  </Modal>
                  <Modal
                    title={t('common:billing.card.add')}
                    isOpen={addModalIsOpen}
                    modalSize="small"
                    isFooterHidden
                    handleToggleClick={toggleAddModal}
                  >
                    <MainForm
                      isUpdateBillingInfo
                      formRef={addFormRef}
                      widthData={widthData}
                      artistId={artistId}
                      completeButtonText={t('common:button.add')}
                      proceedToSuccessPage={proceedToSuccessPage}
                      handlePurchaseCancelation={handlePurchaseCancelation}
                    />
                  </Modal>
                </StyledElementsContainer>
              </Elements>
            </RecurlyProvider>
          </Col>
        </Row>
      </Container>
    </FormFieldsWrapper>
  );
};
