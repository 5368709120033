import React, { FC } from 'react';
import { EnterpriseTheme as theme } from '@minecraft.themes';
import { useTranslation } from '@blocs.i18n';
import { BodyText, FlexRow } from '@minecraft.atoms';
import { StyledAddCard, StyledAddIcon } from './styles';

type Props = {
  onSelect: () => void;
  isSelected?: boolean;
};

const AddCard: FC<Props> = ({ onSelect, isSelected = false }) => {
  const { t } = useTranslation();

  return (
    <StyledAddCard
      role="button"
      $isSelected={isSelected}
      className="d-flex flex-column justify-content-center"
      onClick={onSelect}
    >
      <FlexRow alignItems="center">
        <StyledAddIcon name="add" color={theme.colors.buttons.primary.active} />
        <BodyText fontWeight="semibold" color="link">
          {t('common:billing.card.add')}
        </BodyText>
      </FlexRow>
    </StyledAddCard>
  );
};

export default AddCard;
