import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@blocs.i18n';
import { media } from '@minecraft.themes';
import Select from '@minecraft.select';
import Icon from '@minecraft.icon';

import { DropdownHeightStyle } from '../PaginationCommon';

const StyledSelectWrapper = styled.div`
  margin-right: 1rem;

  width: 100%;

  ${media.sm`
    width: auto;
  `};
`;

const StyledArrowButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledArrowButton = styled.button`
  background: none;
  width: 2rem;
  height: 2rem;
  padding: 0;
  left: auto;
  right: 0;
  top: 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1rem;
    left: 0;
    right: auto;
    border-right: 0.0625rem solid ${(props) => props.theme.colors.accent.ltGrey4};
    border-left: 0 solid ${(props) => props.theme.colors.accent.ltGrey4};
  }

  border: 0;
  background: ${(props) => (props.disabled ? props.theme.colors.primary.ltGrey1 : props.theme.colors.primary.grey1)};
  border-radius: ${(props) => props.theme.button.borderRadius};
  position: static;

  &:not(:last-child) {
    border: 0;
  }
`;

const StyledSelectPage = styled(Select)<any>`
  min-width: 3.3125rem;
  padding: 0 0.25rem;

  ${DropdownHeightStyle}
`;

const StyledPaginationWrapper = styled.div`
  position: relative;
  display: flex;
  border-radius: 0.3125rem;
  justify-content: center;
  align-items: center;

  height: auto;
  font-weight: normal;
  border: 0;

  width: 100%;

  ${media.sm`
    width: auto;
  `}
`;

const directions = {
  prev: 'prev',
  next: 'next',
};

const calculateTotalPages = (totalRecords, pageLimit) => Math.ceil(totalRecords / pageLimit);

export type Props = {
  currentPage: number;
  pageLimit: number;
  totalRecords: number;
  onChange: (event, currentPage, pageLimit) => void;
};

const PagePagination: FC<Props> = ({ currentPage, pageLimit, totalRecords, onChange }) => {
  const { prev, next } = directions;
  const { t } = useTranslation();

  const handleClick = (event) => {
    const actionDirection = event.currentTarget.value;

    switch (actionDirection) {
      case prev:
        onChange(event, Number(currentPage) - 1, pageLimit);
        break;
      case next:
        onChange(event, Number(currentPage) + 1, pageLimit);
        break;
      default:
        break;
    }
  };

  const totalPages = calculateTotalPages(totalRecords, pageLimit);

  const pageOptions = Array.from({ length: totalPages }, (v, index) => ({
    value: index + 1,
    label: t('common:filter.page', { page: index + 1, totalPages }),
  }));

  return (
    <StyledPaginationWrapper>
      <StyledSelectWrapper>
        <StyledSelectPage
          data-qa-id="pagination-page-number-list"
          data-testid="pagination-page-number-list"
          value={currentPage}
          options={pageOptions}
          size="small"
          onChange={onChange}
          aria-label={t('common:button.choosePage')}
        />
      </StyledSelectWrapper>
      <StyledArrowButtonWrapper>
        <StyledArrowButton
          data-qa-id="pagination-previous-button"
          data-testid="pagination-previous-button"
          disabled={currentPage <= 1}
          onClick={handleClick}
          type="button"
          aria-label={t('common:button.previousPage')}
          value={prev}
        >
          <Icon name="chevron-left-thin" color="#fff" />
        </StyledArrowButton>
        <StyledArrowButton
          data-qa-id="pagination-next-button"
          data-testid="pagination-next-button"
          disabled={currentPage >= totalPages}
          onClick={handleClick}
          type="button"
          aria-label={t('common:button.nextPage')}
          value={next}
        >
          <Icon name="chevron-right-thin" color="#fff" />
        </StyledArrowButton>
      </StyledArrowButtonWrapper>
    </StyledPaginationWrapper>
  );
};

export default PagePagination;
