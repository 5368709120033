import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export type CNIGraphQlError = GraphQLError & {
  errorCode: number;
  name: string;
  data?: {
    errorData?: string[] | { message?: string };
  };
};

enum PaymentErrors {
  PaymentsMaxRedemptions = 'MAX_REDEMPTIONS',
  PaymentsInvalidCardDetails = 'INVALID_CARD_DETAILS',
  PaymentsExpiredCard = 'EXPIRED_CARD',
  PaymentsInsufficientFunds = 'INSUFFICIENT_FUNDS',
  PaymentsTransactionError = 'TRANSACTION_ERROR',
  PaymentsInvalidCouponCode = 'INVALID_COUPON_CODE',
}

export function parsePaymentsError(error: ApolloError) {
  const graphqlErrors = error.graphQLErrors as CNIGraphQlError[];
  for (let i = 0; i < graphqlErrors.length; i += 1) {
    const graphQLError = graphqlErrors[i];
    const additionalData = graphQLError.data?.errorData ?? [];
    let couponError = false;
    if (Array.isArray(additionalData)) {
      couponError = !!additionalData.find((data) => data === 'has reached max redemptions for this account');
    } else {
      couponError = additionalData?.message === 'has reached max redemptions for this account';
    }

    if (couponError) {
      return PaymentErrors.PaymentsMaxRedemptions;
    }

    const errorName = graphQLError.extensions?.errorName as string;
    if (Object.keys(PaymentErrors).includes(errorName)) {
      return PaymentErrors[errorName];
    }
  }

  return PaymentErrors.PaymentsTransactionError;
}
