import React, { FC } from 'react';
import Modal from '@minecraft.modal';
import styled from 'styled-components';
import { gqlBillingInfo } from '@minecraft.graphql-types';
import CreditCardForm, { creditCardFormStyles } from '../CreditCardForm';

const StyledCreditCardForm = styled(CreditCardForm)`
  ${creditCardFormStyles.StyledElementsContainer} {
    padding: 0;
  }
`;

type Props = {
  isOpen: boolean;
  handleToggleClick: () => void;
  callback: (paymentMethod: gqlBillingInfo) => Promise<void>;
};

const AddCardModal: FC<Props> = ({ isOpen, handleToggleClick, callback }) => {
  return (
    isOpen && (
      <Modal isOpen handleToggleClick={handleToggleClick} modalSize="small" isFooterHidden>
        <StyledCreditCardForm isUpdateBillingInfo goToSuccessPage={callback} isAddModal />
      </Modal>
    )
  );
};

export default AddCardModal;
