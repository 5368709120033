import React, { FC } from 'react';
import styled from 'styled-components';

const StyledApplePayButton = styled.button`
  border: none;
  border-radius: 0.4rem;
  width: 100%;
  height: 50px;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  padding: 10px 20px;
  min-width: 150px;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: black;
`;

export interface ApplePayButtonProps {
  onClick?: () => void;
}

export const ApplePayButton: FC<ApplePayButtonProps> = ({ onClick }) => {
  return <StyledApplePayButton onClick={() => onClick()} type="button" aria-label="Apple Pay" />;
};
