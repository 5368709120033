import React, { ReactText } from 'react';
import styled from 'styled-components';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import Input from '@minecraft.input';
import { BodyText, FlexDynamic, FlexProps } from '@minecraft.atoms';
import { InputProps } from 'reactstrap';

const StyledInput = styled(Input)<any>`
  background-color: ${(props): string => props.theme.designSystem.bgColors.default};
  border-color: ${(props): string => props.theme.designSystem.borderColors.default};
`;

const StyledWrapper = styled.div`
  position: relative;

  & > select {
    appearance: none;
    padding-right: 1.6875rem;
  }
`;

const FullWidthWrapper = styled.div`
  position: relative;
  width: 100%;

  & > select {
    appearance: none;
    padding-right: 1.6875rem;
  }
`;

export type Option = { label: ReactText; value: string; count?: number; disabled?: boolean };

type WrapperProps = {
  className?: string;
};

type Props = {
  options: Option[];
  defaultValue?: string | number;
  size?: 'small' | 'large';
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  label?: string;
  wrapperProps?: WrapperProps;
  showLabel?: boolean;
  direction?: 'vertical' | 'horizontal';
  grow?: FlexProps['grow'];
  useFullWidth?: boolean;
};

const SVGArrow = ({ size, ...props }): JSX.Element => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg" {...props}>
    <polygon fill="#3A3A3A" points="0,6 12,18 24,6" />
  </svg>
);

const StyledSVGArrow = styled(SVGArrow)`
  height: 0.75rem;
  width: 0.75rem;
  margin-left: 0.3125rem;
  cursor: pointer;
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Select = ({
  options,
  defaultValue,
  size = 'large',
  showLabel = false,
  label,
  wrapperProps,
  name,
  id,
  direction = 'vertical',
  grow = 0,
  useFullWidth = false,
  ...props
}: InputProps | Props): JSX.Element => {
  const DynamicWrapper = useFullWidth ? FullWidthWrapper : StyledWrapper;
  return (
    <FlexDynamic
      className={useFullWidth ? 'cn_w-full' : ''}
      direction={direction === 'horizontal' ? 'row' : 'column'}
      alignItems={direction === 'horizontal' ? 'center' : 'flex-start'}
      gap={direction === 'horizontal' ? '2' : '1'}
      grow={grow}
    >
      {showLabel && label && (
        <BodyText as="label" fontWeight="bold" htmlFor={id || name} id={`${id || name}-select-label`}>
          {label}
        </BodyText>
      )}
      <DynamicWrapper {...wrapperProps}>
        <StyledInput
          type="select"
          size={size}
          defaultValue={defaultValue}
          aria-label={label}
          {...props}
          id={id || name}
        >
          {_map(options, (option) => {
            const withCount = !_isUndefined(option?.count);

            return (
              <option
                key={option.value}
                value={option.value}
                disabled={option?.disabled || (withCount && option?.count <= 0)}
              >
                {`${option?.label || ''}${withCount ? ` (${option?.count})` : ''}`}
              </option>
            );
          })}
        </StyledInput>
        <StyledSVGArrow size={24} />
      </DynamicWrapper>
    </FlexDynamic>
  );
};

export default Select;
