import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import Skeleton from 'react-loading-skeleton';
import { CloudinaryPhotoWithFallback } from '@minecraft.cloudinary-photo';
import { BaseballCardMediaFragment } from '@minecraft.graphql-operations';
import { FlexCol } from '@minecraft.atoms';
import { WindowWidthContext } from '@minecraft.utils';

export const StyledCoverPhotoContent = styled.div<{ isMobile?: boolean }>`
  border-radius: 0.125rem;
  overflow: hidden;
  width: ${({ isMobile }) => (isMobile ? '4rem' : '9rem')};

  img {
    object-position: center;
  }
`;

type CoverPhotoProps = { cover?: BaseballCardMediaFragment };
const CoverPhoto: React.FC<CoverPhotoProps> = ({ cover, children }) => {
  const isMobile = WindowWidthContext.useIsMobile();

  return (
    <FlexCol grow={0} shrink={0} alignItems="center" className={classNames(!isMobile && 'cn_w-full')}>
      <StyledCoverPhotoContent isMobile={isMobile}>
        {children}
        <CloudinaryPhotoWithFallback showThumbnail {...(cover as any)} className="cn_w-full" />
      </StyledCoverPhotoContent>
    </FlexCol>
  );
};

const StyledLoadingCoverPhoto = styled.div<{ isMobile?: boolean }>`
  display: grid;
  justify-content: center;
  height: ${({ isMobile }) => (isMobile ? '5rem' : '11.25rem')};
`;

const LoadingCoverPhoto = () => {
  const isMobile = WindowWidthContext.useIsMobile();

  return (
    <StyledLoadingCoverPhoto isMobile={isMobile} className={classNames(!isMobile && 'cn_w-full')}>
      <StyledCoverPhotoContent isMobile={isMobile} className="cn_w-full">
        <Skeleton height="100%" width="100%" />
      </StyledCoverPhotoContent>
    </StyledLoadingCoverPhoto>
  );
};

const CoverPhotoWithLoader = (props: CoverPhotoProps | { loading: true }) => {
  return 'loading' in props ? <LoadingCoverPhoto /> : <CoverPhoto {...props} />;
};

export default CoverPhotoWithLoader;
