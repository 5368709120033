import React, { useState, useCallback, useEffect } from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import { useRecurly } from '@recurly/react-recurly';
import { useMutation } from '@apollo/client';
import { useTranslation } from '@blocs.i18n';
import { WindowWidthContext } from '@minecraft.utils';
import { BodyTextSmall, DividerWithLabel, FlexRow, H4Text } from '@minecraft.atoms';
import Icon from '@minecraft.icon';
import Alert from '@minecraft.alert';
import Checkbox from '@minecraft.checkbox';
import { useModal } from '@minecraft.modal';
import { useGlobalNotification } from '@minecraft.notification-provider';
import { gqlBillingInfo } from '@minecraft.graphql-types';
import { useUserContext } from '@blocs.auth';
import { captureExceptionEvent } from '@blocs.sentry';
import { CountryEnum, CurrencyEnum } from '@minecraft.graphql-operations';
import SCREEN_NAMES from '../../common/screenNames';
import { CreditCard } from '../CreditCard';
import { ADD_BILLING_INFO, DELETE_BILLING_INFO, EDIT_BILLING_INFO } from '../graphqls';
import { ApplePayButton } from '../../ApplePay';
import { ApplePayPayment, useApplePay } from '../../ApplePay/useApplePay';
import { APPLE_PAY, cardTypes, paymentTypes } from '../CreditCard/CreditCard';
import AuthorizeMessage from './AuthorizeMessage';
import FullLoader from './FullLoader';

import {
  StyledButton,
  StyledButtonsContainer,
  StyledCancelButtonMobile,
  StyledCardCvvElement,
  StyledCardMonthElement,
  StyledCardNumberElement,
  StyledCardTypesContainer,
  StyledCardYearElement,
  StyledCheckboxWrapper,
  StyledCTAContainer,
  StyledCVVIcon,
  StyledCVVIconsWrapper,
  StyledCVVSection,
  StyledDeleteButton,
  StyledErrorArea,
  StyledExpDateSection,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledMainForm,
  StyledZipSection,
} from './styles';
import { PAYMENT_CARD } from './constants';
import DeleteModal from './DeleteModal';

const ID: Record<string, string> = {
  cancel: `${SCREEN_NAMES.PROFILE_CHECKOUT}-cancel`,
  submitForm: `${SCREEN_NAMES.PROFILE_CHECKOUT}-form`,
  submitButton: `${SCREEN_NAMES.PROFILE_CHECKOUT}-submit-button`,
};

export type MainFormProps = {
  formRef: React.RefObject<HTMLFormElement>;
  widthData: WindowWidthContext.WidthData;
  getBackButton?: () => JSX.Element;
  handleCancelButtonClick?: () => void;
  proceedToSuccessPage: () => void;
  completeButtonText: string;
  isUpdateBillingInfo: boolean;
  hideForm?: boolean;
  selectedCard?: gqlBillingInfo;
  handlePurchaseCancelation?: () => void;
};

const formFieldsInitial = {
  // eslint-disable-next-line camelcase
  first_name: '',
  // eslint-disable-next-line camelcase
  last_name: '',
  number: '',
  month: '',
  year: '',
  cvv: '',
  // eslint-disable-next-line camelcase
  postal_code: '',
};

const billingInfoStatusDefault = {
  isPrimaryPaymentMethod: false,
  isBackupPaymentMethod: false,
};

const hasThreeDErrorMessage = (errorData: string | string[]) => {
  if (!errorData) return false;

  if (Array.isArray(errorData)) {
    return errorData.some((message) => message.includes('3-D'));
  }

  return errorData.includes('3-D');
};

const getIconForCardType = (cardType: 'MasterCard' | 'Visa' | 'American Express') => {
  if (cardType === 'MasterCard') {
    return <Icon name="master-card" />;
  }

  if (cardType === 'Visa') {
    return <Icon name="visa" />;
  }

  if (cardType === 'American Express') {
    return <Icon name="amex" />;
  }

  return (
    <>
      <Icon name="master-card" />
      <Icon name="visa" />
      <Icon name="amex" />
    </>
  );
};

const MainForm = ({
  formRef,
  widthData,
  getBackButton,
  handleCancelButtonClick = () => undefined,
  proceedToSuccessPage,
  completeButtonText,
  isUpdateBillingInfo,
  hideForm,
  selectedCard,
  handlePurchaseCancelation,
}: MainFormProps) => {
  const { t } = useTranslation();
  const { showMessage } = useGlobalNotification();
  const { refreshTokens, myAccount } = useUserContext();

  const [addBillingInfo, { loading: addBillingLoading }] = useMutation(ADD_BILLING_INFO, {
    refetchQueries: ['getBillingInfo'],
    awaitRefetchQueries: true,
  });
  const [deleteBillingInfo, { loading: deleteBillingInfoLoading }] = useMutation(DELETE_BILLING_INFO, {
    refetchQueries: ['getBillingInfo'],
    awaitRefetchQueries: true,
  });
  const [editBillingInfo, { loading: editBillingInfoLoading }] = useMutation(EDIT_BILLING_INFO, {
    refetchQueries: ['getBillingInfo'],
    awaitRefetchQueries: true,
  });

  // recurly hook can only be init within <Element />
  const { isOpen, toggleModal } = useModal();
  const recurly = useRecurly();
  const [formFiledErrors, setFormFieldErrors] = useState(formFieldsInitial);
  const [cardToUse, setCardToUse] = useState(PAYMENT_CARD.NEW);
  const [errors, setErrors] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [actionTokenId, setActionTokenId] = useState('');
  const [recurlyToken, setRecurlyToken] = useState('');
  const [billingInfoStatus, setBillingInfoStatus] = useState<typeof billingInfoStatusDefault>(billingInfoStatusDefault);

  const isApplePaySelected = selectedCard?.paymentType === paymentTypes.ApplePay;

  const clearForm = () => {
    setFormFieldErrors(formFieldsInitial);
    setErrors(null);
    setIsSubmitting(false);
    setActionTokenId('');
    setRecurlyToken('');
    setBillingInfoStatus(billingInfoStatusDefault);
  };

  useEffect(() => {
    if (selectedCard) {
      clearForm();

      if (selectedCard?.id) {
        setBillingInfoStatus({
          isPrimaryPaymentMethod: selectedCard.isPrimaryPaymentMethod,
          isBackupPaymentMethod: selectedCard.isBackupPaymentMethod,
        });
      }

      !isUpdateBillingInfo && setCardToUse(PAYMENT_CARD.CARD_ON_FILE);
    }
  }, [selectedCard]);

  const setFieldError = (element: string, message: string): void => {
    setFormFieldErrors((prevState) => ({ ...prevState, [element]: message }));
  };

  const checkFor3DSecurityActionToken = (purchaseErrors): boolean => {
    const error = _head(purchaseErrors);
    const threeDToken =
      _get(error['data'].errorData, 'threeDSecureActionTokenId') ||
      _get(error['data'].errorData, 'publicData.threeDSecureActionTokenId');

    if (threeDToken) {
      setActionTokenId(threeDToken);

      return true;
    }

    return false;
  };

  const onChange = useCallback(
    (element) => {
      if (!element.focus && element.length) {
        if (!element.valid) {
          switch (element.type) {
            case 'number':
              setFieldError(element.type, t('common:validation.cardInvalid'));
              break;
            case 'month':
              setFieldError(element.type, t('common:validation.cardExpMonthInvalid'));
              break;
            case 'year':
              setFieldError(element.type, t('common:validation.cardExpYearInvalid'));
              break;
            case 'cardCvv':
              setFieldError(element.type, t('common:validation.cardCvcInvalid'));
              break;
            default:
              setFieldError(element.type, '');
              break;
          }
        } else {
          setFieldError(element.type, '');
        }
      } else if (!element.focus && !element.length) {
        setFieldError(element.type, '');
      }
    },
    [t]
  );

  const hasFormErrors = (): boolean => !!Object.values(formFiledErrors).filter(Boolean).length;

  const addBillingInfoHandler = async (token: string, is3D = false): Promise<void> => {
    try {
      const input = {
        tokenId: token,
      };
      const threeDInput = {
        threeDSecureActionResultTokenId: token,
        tokenId: recurlyToken,
      };

      const result = await addBillingInfo({
        variables: {
          input: {
            ...(is3D ? threeDInput : input),
            primaryPaymentMethod: billingInfoStatus.isPrimaryPaymentMethod,
            backupPaymentMethod: billingInfoStatus.isBackupPaymentMethod,
          },
        },
      });

      if (result.errors) {
        if (checkFor3DSecurityActionToken(result.errors)) return;

        throw Error();
      }

      await refreshTokens();
      proceedToSuccessPage();
      showMessage({
        message: t('common:billing.card.added.success'),
        color: 'success',
      });
      clearForm();
    } catch (e) {
      showMessage({
        message: t('common:billing.card.added.failure'),
        color: 'danger',
      });
      clearForm();
    }
  };

  const editBillingInfoHandler = async ({
    status,
    card,
    token,
    is3D = false,
  }: {
    status: typeof billingInfoStatusDefault;
    card: gqlBillingInfo;
    token?: string;
    is3D?: boolean;
  }): Promise<void> => {
    try {
      const input = {
        tokenId: token,
      };
      const threeDInput = {
        threeDSecureActionResultTokenId: token,
        tokenId: recurlyToken,
      };

      const result = await editBillingInfo({
        variables: {
          input: {
            ...(is3D ? threeDInput : input),
            billingInfoId: card.id,
            primaryPaymentMethod: status.isPrimaryPaymentMethod,
            backupPaymentMethod: status.isBackupPaymentMethod,
          },
        },
      });

      if (result.errors) {
        if (checkFor3DSecurityActionToken(result.errors)) return;

        throw Error();
      }

      proceedToSuccessPage();
      showMessage({
        message: t('common:billing.card.added.success'),
        color: 'success',
      });
    } catch (e) {
      showMessage({
        message: t('common:billing.card.added.failure'),
        color: 'danger',
      });

      setBillingInfoStatus({
        isPrimaryPaymentMethod: card.isPrimaryPaymentMethod,
        isBackupPaymentMethod: card.isBackupPaymentMethod,
      });
    }
  };

  const updateBillingStatus = useCallback(
    _debounce<(status: typeof billingInfoStatusDefault) => Promise<void>>(
      (status) => editBillingInfoHandler({ status, card: selectedCard }),
      600
    ),
    [selectedCard]
  );

  const removeBillingInfo = async () => {
    try {
      await deleteBillingInfo({ variables: { input: { billingInfoId: selectedCard.id } } });

      showMessage({
        message: t('common:billing.card.added.success'),
        color: 'success',
      });
    } catch (e) {
      showMessage({
        message: t('common:billing.card.added.failure'),
        color: 'danger',
      });
    } finally {
      proceedToSuccessPage();
      toggleModal();
    }
  };

  const finishPurchase = (token?: string, is3D = false) => {
    if (isUpdateBillingInfo && selectedCard?.id) {
      return editBillingInfoHandler({ status: billingInfoStatus, card: selectedCard, token, is3D });
    }

    return addBillingInfoHandler(token, is3D);
  };

  const handleSubmit = (event) => {
    setIsSubmitting(true);

    if (event.preventDefault) event.preventDefault();

    if (cardToUse === PAYMENT_CARD.CARD_ON_FILE) {
      finishPurchase()?.catch((e) => console.error(e));

      return;
    }

    recurly.token(formRef.current, (err, token) => {
      if (err) {
        console.error(err);

        if (['validation', 'invalid-parameter'].includes(err.code) && Array.isArray(err['details'])) {
          const errorMessage =
            err.code === 'invalid-parameter' ? err.message : t('common:validation.invalidFieldValue');

          err['details'].forEach((e) => setFieldError(e.field, errorMessage));
        }

        setIsSubmitting(false);
        setRecurlyToken('');
      } else {
        setRecurlyToken(token.id);
        finishPurchase(token.id)?.catch((e) => console.error(e));
      }
    });
  };

  const { showApplePayButton, onClick: onClickApplePay } = useApplePay({
    amountToCharge: 0,
    country: myAccount?.country?.code as CountryEnum,
    currency: CurrencyEnum.Usd,
    onPaymentAuthorized: async ({ payment }: ApplePayPayment) => {
      try {
        await finishPurchase(payment?.recurlyToken?.id);
      } catch (err) {
        captureExceptionEvent(err);
        console.error(err);
      }
    },
    onError: (error) => {
      captureExceptionEvent(error);
      console.error(error);
      // Silently fail
    },
  });

  const renderCreditCardOnFileSection = (): JSX.Element => {
    if (!selectedCard) return null;

    return (
      <div className="d-flex flex-row align-items-center justify-content-between cn_mb-3">
        <h4>{t('common:billing.card.card')}</h4>
        <div>
          <CreditCard
            isSelected
            isPrimary={selectedCard.isPrimaryPaymentMethod}
            isBackup={selectedCard.isBackupPaymentMethod}
            cardType={selectedCard.cardType}
            lastFour={selectedCard.lastFour}
            paymentType={selectedCard.paymentType}
            hideArrow
          />
        </div>
      </div>
    );
  };

  const controlButtons = widthData.lessSm ? (
    <>
      <div className="d-flex flex-column w-100">
        <StyledButton id={ID.submitButton} disabled={hasFormErrors() || isSubmitting} type="submit" className="w-100">
          {completeButtonText}
        </StyledButton>
        {handlePurchaseCancelation && (
          <StyledCancelButtonMobile id={ID.cancel} type="button" onClick={handleCancelButtonClick} className="w-100">
            {t('common:button.cancel')}
          </StyledCancelButtonMobile>
        )}
      </div>
      {isUpdateBillingInfo && selectedCard?.id && !selectedCard?.isPrimaryPaymentMethod && (
        <StyledDeleteButton type="button" onClick={toggleModal}>
          {t('common:button.delete')}
        </StyledDeleteButton>
      )}
    </>
  ) : (
    <>
      {isUpdateBillingInfo && selectedCard?.id && !selectedCard?.isPrimaryPaymentMethod && (
        <StyledDeleteButton type="button" onClick={toggleModal}>
          {t('common:button.delete')}
        </StyledDeleteButton>
      )}
      <div>
        {!(isApplePaySelected && isUpdateBillingInfo) && (
          <StyledButton id={ID.submitButton} disabled={hasFormErrors() || isSubmitting} type="submit">
            {completeButtonText}
          </StyledButton>
        )}
      </div>
    </>
  );

  return (
    <>
      {!isUpdateBillingInfo && !actionTokenId && renderCreditCardOnFileSection()}
      {(addBillingLoading || editBillingInfoLoading) && <FullLoader />}

      {!actionTokenId && (
        <StyledForm key={`${cardToUse}-${selectedCard?.id}`} onSubmit={handleSubmit} ref={formRef} id={ID.submitForm}>
          {cardToUse === PAYMENT_CARD.NEW && !hideForm && (
            <>
              {isUpdateBillingInfo && (
                <div className="d-flex">
                  <StyledCheckboxWrapper>
                    <Checkbox
                      isChecked={billingInfoStatus.isPrimaryPaymentMethod}
                      onChange={async (e) => {
                        const state = { ...billingInfoStatus, isPrimaryPaymentMethod: e.target.checked };

                        setBillingInfoStatus(state);
                        selectedCard?.id && (await updateBillingStatus(state));
                      }}
                      name="isPrimaryPaymentMethod"
                      label={t('common:billing.card.primary.set')}
                    />
                  </StyledCheckboxWrapper>
                  <StyledCheckboxWrapper>
                    <Checkbox
                      isChecked={billingInfoStatus.isBackupPaymentMethod}
                      onChange={async (e) => {
                        const state = { ...billingInfoStatus, isBackupPaymentMethod: e.target.checked };

                        setBillingInfoStatus(state);
                        selectedCard?.id && (await updateBillingStatus(state));
                      }}
                      name="isBackupPaymentMethod"
                      label={t('common:billing.card.backup.set')}
                    />
                  </StyledCheckboxWrapper>
                </div>
              )}
              {showApplePayButton && !selectedCard?.id && (
                <>
                  <ApplePayButton onClick={onClickApplePay} />
                  <DividerWithLabel label={t('talent:billing.card.or')} />
                </>
              )}
              {!isApplePaySelected && (
                <StyledMainForm>
                  <StyledLabel htmlFor="first_name">
                    {t('common:form.label.firstNameRequired')}
                    <StyledInput
                      $err={!!formFiledErrors.first_name}
                      data-recurly="first_name"
                      id="first_name"
                      name="first_name"
                      placeholder={selectedCard?.firstName}
                      onBlur={(e) => {
                        if (!e.target.value) setFieldError('first_name', t('common:validation.required'));
                        else setFieldError('first_name', '');
                      }}
                    />
                    <StyledErrorArea>{formFiledErrors.first_name}</StyledErrorArea>
                  </StyledLabel>
                  <StyledLabel htmlFor="last_name">
                    {t('common:form.label.lastNameRequired')}
                    <StyledInput
                      $err={!!formFiledErrors.last_name}
                      data-recurly="last_name"
                      name="last_name"
                      placeholder={selectedCard?.lastName}
                      id="last_name"
                      onBlur={(e) => {
                        if (!e.target.value) setFieldError('last_name', t('common:validation.required'));
                        else setFieldError('last_name', '');
                      }}
                    />
                    <StyledErrorArea>{formFiledErrors.last_name}</StyledErrorArea>
                  </StyledLabel>
                  <StyledLabel htmlFor="number">
                    {t('common:form.label.cardNumberRequired')}
                    <StyledCardNumberElement
                      $err={!!formFiledErrors.number}
                      onChange={(element) => onChange({ ...element, type: 'number' })}
                      id="number"
                      style={{
                        padding: '0.375rem 0.75rem',
                        placeholder: {
                          content: selectedCard?.lastFour ? `**** **** **** ${selectedCard?.lastFour}` : '',
                        },
                      }}
                    />
                    <StyledErrorArea>{formFiledErrors.number}</StyledErrorArea>
                  </StyledLabel>
                  <StyledCardTypesContainer>
                    {getIconForCardType(selectedCard?.cardType as any)}
                  </StyledCardTypesContainer>
                  <StyledExpDateSection>
                    <StyledLabel htmlFor="month">
                      {t('common:form.label.expMonthRequired')}
                      <StyledCardMonthElement
                        $err={!!formFiledErrors.month}
                        onChange={(element) => onChange({ ...element, type: 'month' })}
                        id="month"
                        style={{
                          placeholder: {
                            content: selectedCard?.month?.toString() || t('common:form.placeholder.expMonth'),
                          },
                          padding: '0.375rem 0.75rem',
                        }}
                      />
                      <StyledErrorArea>{formFiledErrors.month}</StyledErrorArea>
                    </StyledLabel>
                    <StyledLabel htmlFor="year">
                      {t('common:form.label.expYearRequired')}
                      <StyledCardYearElement
                        $err={!!formFiledErrors.year}
                        onChange={(element) => onChange({ ...element, type: 'year' })}
                        id="year"
                        style={{
                          placeholder: {
                            content: selectedCard?.year?.toString() || t('common:form.placeholder.expYear'),
                          },
                          padding: '0.375rem 0.75rem',
                        }}
                      />
                      <StyledErrorArea>{formFiledErrors.year}</StyledErrorArea>
                    </StyledLabel>
                  </StyledExpDateSection>
                  <StyledCVVSection>
                    <StyledLabel htmlFor="CVV">
                      {t('common:form.label.cvvRequired')}
                      <StyledCardCvvElement
                        $err={!!formFiledErrors.cvv}
                        onChange={(element) => onChange({ ...element, type: 'cvv' })}
                        id="CVV"
                        style={{
                          padding: '0.375rem 0.75rem',
                        }}
                      />
                      <StyledErrorArea>{formFiledErrors.cvv}</StyledErrorArea>
                    </StyledLabel>
                    <StyledCVVIconsWrapper>
                      <StyledCVVIcon name="visa-mc-cvv" />
                      <StyledCVVIcon name="amex-cvv" />
                    </StyledCVVIconsWrapper>
                  </StyledCVVSection>

                  <StyledZipSection htmlFor="postal_code">
                    {t('common:form.label.zipRequired')}
                    <StyledInput
                      $err={!!formFiledErrors.postal_code}
                      data-recurly="postal_code"
                      id="postal_code"
                      name="postal_code"
                      maxLength={10}
                      onBlur={(e) => {
                        if (!e.target.value) setFieldError('postal_code', t('common:validation.required'));
                        else setFieldError('postal_code', '');
                      }}
                    />
                    <StyledErrorArea>{formFiledErrors.postal_code}</StyledErrorArea>
                  </StyledZipSection>
                </StyledMainForm>
              )}
              {isApplePaySelected && (
                <StyledMainForm>
                  <FlexRow className="cn_w_full cn_atom_gap-6" alignItems="center">
                    <Icon height={3.4} name={cardTypes.ApplePay} />
                    <H4Text fontWeight="semibold">{APPLE_PAY}</H4Text>
                  </FlexRow>
                  <FlexRow className="cn_w_full cn_atom_gap-6" alignItems="center">
                    <BodyTextSmall>
                      {' '}
                      {t('talent:billing.membership.expiresOn', {
                        date: `${selectedCard.month}/${selectedCard.year}`,
                      })}
                    </BodyTextSmall>
                  </FlexRow>
                </StyledMainForm>
              )}
            </>
          )}
          <AuthorizeMessage />
          <StyledButtonsContainer>
            {widthData.moreMd && getBackButton && getBackButton()}
            <StyledCTAContainer
              $withDelete={isUpdateBillingInfo && selectedCard?.id && !selectedCard?.isPrimaryPaymentMethod}
            >
              {controlButtons}
            </StyledCTAContainer>
          </StyledButtonsContainer>
        </StyledForm>
      )}
      {errors && !hasThreeDErrorMessage(errors) && (
        <Alert
          size="fullWidth"
          onClose={(): void => {
            setErrors(null);
          }}
          type="error"
          sticky
        >
          {errors}
        </Alert>
      )}
      <DeleteModal
        isOpen={isOpen}
        handleToggleClick={toggleModal}
        onSubmit={removeBillingInfo}
        loader={deleteBillingInfoLoading}
      />
    </>
  );
};

export default MainForm;
