import { css } from 'styled-components';

export const handlePageLimitChange = (event, onPageChanged): void => {
  event.preventDefault();

  const paginationData = {
    currentPage: 1,
    pageLimit: Number(event.target.value),
  };

  onPageChanged(paginationData);
};

// eslint-disable-next-line import/prefer-default-export
export const DropdownHeightStyle = css`
  height: 2rem;
`;
