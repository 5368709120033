import { useState, useEffect } from 'react';

const useExternalScript = (src) => {
  const cachedScripts = [];
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  // Script event listener callbacks for load and error
  const onScriptLoad = () => {
    setState({
      loaded: true,
      error: false,
    });
  };

  const onScriptError = (script) => {
    // Remove from cachedScripts we can try loading again
    const index = cachedScripts.indexOf(src);

    if (index >= 0) cachedScripts.splice(index, 1);

    script.remove();

    setState({
      loaded: true,
      error: true,
    });
  };

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        // Check to see if this script is already in the header to prevent loading more scripts than necessary
        const previousScript = document.querySelector(`script[src="${src}"]`);

        if (previousScript) {
          // Since it already exists, we simply say it's ready
          onScriptLoad();

          return () => {
            previousScript.removeEventListener('load', onScriptLoad);
            previousScript.removeEventListener('error', () => onScriptError(previousScript));
          };
        }

        cachedScripts.push(src);

        // Create script
        const script = document.createElement('script');
        script.src = src;
        script.async = true;

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', () => onScriptError(script));

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad);
          script.removeEventListener('error', () => onScriptError(script));
        };
      }

      return () => undefined;
    },
    [src] // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
};

export default useExternalScript;
