import React, { useContext } from 'react';
import { WindowWidthContext, arrayToSelectOptions } from '@minecraft.utils';
import Skeleton from 'react-loading-skeleton';
import { BodyText, FlexCol, FlexRow } from '@minecraft.atoms';
import Select from '@minecraft.select';
import { useTranslation } from '@blocs.i18n';
import PaginationContext from './PaginationContext';
import PagePagination from './PagePagination';
import { handlePageLimitChange } from './PaginationCommon';

export type Props = {
  className?: string;
  hidePageSize?: boolean;
  loading?: boolean;
  pageSizeOnMobile?: boolean;
};

const Switcher = ({ loading, pageSizeOnMobile }) => {
  const { totalRecords, currentPage, pageLimit, onPageChanged, defaultResultSizeOptions } =
    useContext(PaginationContext);

  const isMobile = WindowWidthContext.useIsMobile();

  const { t } = useTranslation();

  const mobileOptions = defaultResultSizeOptions.map((option) => {
    return {
      value: option,
      label: `${option} ${t('common:filter.perPage')} (${t('common:count.itemResults', { count: option })})`,
    };
  });

  const handlePageChange = (event, currPage: number, pgLimit: number) => {
    event.preventDefault();

    const paginationData: { currentPage: number; pageLimit: number } = {
      currentPage: undefined,
      pageLimit: undefined,
    };

    switch (event.type) {
      case 'change':
        paginationData.currentPage = Number(event.target.value);
        paginationData.pageLimit = pgLimit ?? pageLimit;
        break;
      case 'click':
        paginationData.currentPage = currPage;
        paginationData.pageLimit = pgLimit ?? pageLimit;
        break;
      default:
        break;
    }

    onPageChanged(paginationData);
  };

  return loading ? (
    <FlexRow justifyContent="flex-end">
      <Skeleton width="6rem" height="1.5rem" className="cn_inline-flex" />
    </FlexRow>
  ) : (
    <>
      {isMobile && (
        <FlexCol gap="3">
          {pageSizeOnMobile && (
            <FlexRow justifyContent="flex-end" className="cn_w-full">
              <Select
                data-qa-id="pagination-page-size-list"
                data-testid="pagination-page-size-list"
                aria-label={t('common:filter.pageSize')}
                value={pageLimit}
                options={mobileOptions}
                size="large"
                onChange={(e) => handlePageLimitChange(e, onPageChanged)}
                grow={1}
                useFullWidth
              />
            </FlexRow>
          )}
          <PagePagination
            currentPage={currentPage}
            pageLimit={pageLimit}
            totalRecords={totalRecords}
            onChange={handlePageChange}
          />
        </FlexCol>
      )}
      {!isMobile && (
        <FlexRow justifyContent="flex-end" gap="4">
          <FlexRow alignItems="center" justifyContent="flex-end" gap="2">
            <Select
              data-qa-id="pagination-page-size-list"
              data-testid="pagination-page-size-list"
              aria-label={t('common:filter.pageSize')}
              value={pageLimit}
              options={arrayToSelectOptions(defaultResultSizeOptions)}
              size="small"
              onChange={(e) => handlePageLimitChange(e, onPageChanged)}
            />
            <BodyText>{t('common:filter.perPage')}</BodyText>
          </FlexRow>
          <PagePagination
            currentPage={currentPage}
            pageLimit={pageLimit}
            totalRecords={totalRecords}
            onChange={handlePageChange}
          />
        </FlexRow>
      )}
    </>
  );
};

export default WindowWidthContext.withWidthData(Switcher);
