import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '@minecraft.themes';
import { WindowWidthContext } from '@minecraft.utils';
import Skeleton from 'react-loading-skeleton';
import { FlexCol } from '@minecraft.atoms';
import PaginationContext from '../PaginationContext';
import PageResultsSize from './PageResultsSize';
import PagePagination from './PagePagination';

const StyledPageControl = styled.div`
  padding: 0.5rem 0 0.375rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
`;

const StyledPageControlItem = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  ${media.sm`
    width: auto;
  `}
`;

export type Props = {
  className?: string;
  widthData?: any;
  hidePageSize?: boolean;
  loading?: boolean;
  pageSizeOnMobile?: boolean;
};

class Switcher extends Component<Props, null> {
  handlePageChange = (event, currentPage, pageLimit) => {
    event.preventDefault();

    const paginationData = {
      currentPage: undefined,
      pageLimit: undefined,
    };

    switch (event.type) {
      case 'change':
        paginationData.currentPage = Number(event.target.value);
        paginationData.pageLimit = this.context.pageLimit;
        break;
      case 'click':
        paginationData.currentPage = currentPage;
        paginationData.pageLimit = pageLimit;
        break;
      default:
        break;
    }

    this.context.onPageChanged(paginationData);
  };

  render() {
    const { totalRecords, pageLimit, currentPage } = this.context;

    const {
      className,
      widthData: { moreSm },
      hidePageSize = false,
      loading,
      pageSizeOnMobile,
    } = this.props;

    if (loading) return <Skeleton width="4rem" height="1.5rem" />;

    return (
      <>
        {!moreSm && pageSizeOnMobile ? (
          <FlexCol gap="2" className="cn_mt-2">
            <StyledPageControlItem>
              <PageResultsSize />
            </StyledPageControlItem>
            <StyledPageControlItem>
              <PagePagination
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalRecords={totalRecords}
                onChange={this.handlePageChange}
              />
            </StyledPageControlItem>
          </FlexCol>
        ) : (
          <StyledPageControl className={className}>
            {moreSm && !hidePageSize && (
              <StyledPageControlItem>
                <PageResultsSize />
              </StyledPageControlItem>
            )}
            <StyledPageControlItem>
              <PagePagination
                currentPage={currentPage}
                pageLimit={pageLimit}
                totalRecords={totalRecords}
                onChange={this.handlePageChange}
              />
            </StyledPageControlItem>
          </StyledPageControl>
        )}
      </>
    );
  }
}

Switcher.contextType = PaginationContext;

export default WindowWidthContext.withWidthData(Switcher);
