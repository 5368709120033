import React, { FC, useState } from 'react';
import { BasicContainer, LinkAsButton, FlexCol, H2Text, Loader, PrimaryButton } from '@minecraft.atoms';
import styled from 'styled-components';
import { atBreakpoint } from '@minecraft.themes';
import { useTranslation } from '@blocs.i18n';
import { WindowWidthContext } from '@minecraft.utils';
import { PAYWALL_STEPS } from '../enums/paywall';
import { CompletePurchaseLoader } from './CompletePurchaseLoader';
import { PaymentSummary } from './PaymentSummary';
import { PaymentMethods } from './PaymentMethods';
import { WalletLoader } from './WalletLoader';
import { CheckoutPlans } from './CheckoutPlans';
import { PaymentLoader } from './PaymentLoader';
import { PaywallLocation } from '../enums/PaywallLocation';
import { AddPaymentProps, CheckoutProps, GridContainerProps } from './types';
import { Purchase } from '../types';
import { UnlimitedMediaUpsell } from './UnlimitedMediaUpsell';
import { AddPaymentMethod } from './AddPaymentMethod';
import { PaymentAndPlanDetails } from './PaymentAndPlanDetails';
import { useMobileRegistration } from '../useMobileRegistration';

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  gap: 2rem;
  padding: ${(props) => (props.isModal ? '.5rem 2rem' : '2rem')};
  ${atBreakpoint.md`
    display: flex;
    gap: 0;
    flex-direction: column;
    padding: ${(props) => (props.isModal ? '0' : '1rem .6rem')};
  `}
`;

export const AddPayment: FC<AddPaymentProps> = React.memo(
  ({
    addPaymentError,
    addPaymentLoading,
    onAddPaymentOption,
    showThreeDSecurityForm,
    threeDActionToken,
    onThreeDActionError,
    currencyCode,
    countryCode,
    paywallLocation,
    allowCancel,
    onCompletePurchase,
    selectedPlan,
    currentPurchase,
    completedPurchase,
    completePurchaseLoading,
    completePurchaseError,
    selectedPaymentOption,
    wallet,
    onSelectPaymentOption,
    onNext,
    onCancel,
    setIsAnnual,
    isAnnual,
  }) => {
    const { isMobileRegistration } = useMobileRegistration({ paywallLocation });

    if (isMobileRegistration) {
      return (
        <PaymentAndPlanDetails
          onCancel={onCancel}
          addPaymentError={addPaymentError}
          addPaymentLoading={addPaymentLoading}
          wallet={wallet}
          allowCancel={allowCancel}
          onAddPaymentOption={onAddPaymentOption}
          showThreeDSecurityForm={showThreeDSecurityForm}
          threeDActionToken={threeDActionToken}
          onThreeDActionError={onThreeDActionError}
          currencyCode={currencyCode}
          countryCode={countryCode}
          paywallLocation={paywallLocation}
          onCompletePurchase={onCompletePurchase}
          selectedPlan={selectedPlan}
          currentPurchase={currentPurchase as Purchase}
          completePurchaseError={completePurchaseError}
          selectedPaymentOption={selectedPaymentOption}
          onSelectPaymentOption={onSelectPaymentOption}
          onNext={onNext}
          completedPurchase={completedPurchase}
          completePurchaseLoading={completePurchaseLoading}
          setIsAnnual={setIsAnnual}
          isAnnual={isAnnual}
        />
      );
    }
    return (
      <AddPaymentMethod
        onCancel={onCancel}
        addPaymentError={addPaymentError}
        addPaymentLoading={addPaymentLoading}
        allowCancel={allowCancel}
        onAddPaymentOption={onAddPaymentOption}
        showThreeDSecurityForm={showThreeDSecurityForm}
        threeDActionToken={threeDActionToken}
        onThreeDActionError={onThreeDActionError}
        currencyCode={currencyCode}
        countryCode={countryCode}
        paywallLocation={paywallLocation}
      />
    );
  }
);

export const CheckoutWrapper: FC<CheckoutProps> = ({
  onNext,
  currentPurchase,
  onCompletePurchase,
  currencyCode,
  onApplyCoupon,
  couponLoading,
  applyCouponError,
  onRemoveCoupon,
  completePurchaseLoading,
  completePurchaseError,
  wallet,
  walletLoading,
  selectedPaymentOption,
  onSelectPaymentOption,
  addPaymentError,
  isAnnual,
  plans,
  plansError,
  plansLoading,
  setIsAnnual,
  selectedPlan,
  setSelectedPlan,
  countryCode,
  onBack,
  startingStep,
  onAddPaymentOption,
  setShowAddPaymentMethod = () => null,
  showAddPaymentMethod,
  addPaymentLoading,
  showThreeDSecurityForm,
  threeDActionToken,
  onThreeDActionError,
  isModal,
  getTitle = () => null,
  paywallLocation,
  currentPlanCode,
  enableAnnualRenewal,
  isWithinTrialPeriod,
  previewMediaPurchaseLoading,
  previewMediaPurchase,
  closeBillingPopup,
}) => {
  const { t } = useTranslation();
  const isMobile = WindowWidthContext.useIsMobile();
  const [showPaymentMethods, setShowPaymentMethods] = useState<boolean>(false);
  const { isMobileRegistration } = useMobileRegistration({ paywallLocation });
  const startsAtCheckoutMobile = startingStep === PAYWALL_STEPS.CHECKOUT && isMobile;
  const handleBack = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (startsAtCheckoutMobile) {
      setShowPaymentMethods(false);
    } else {
      onBack && onBack();
    }
  };
  const purchaseableItems = previewMediaPurchase?.items;

  return (
    <BasicContainer mx={isModal ? '0' : '2'} w="full">
      <Loader loading={completePurchaseLoading} LoaderComponent={<CompletePurchaseLoader />}>
        <BasicContainer data-testid="paywall-checkout-wrapper" bgColor="card" borderRadius="md" w="full">
          {paywallLocation === PaywallLocation.SIGNUP && !isMobileRegistration && (
            <LinkAsButton onClick={handleBack} label={`< ${t('common:button.back')}`} className="cn_m-6" />
          )}
          {(!isMobile || !isModal) && !isMobileRegistration && (
            <BasicContainer mx="1" my="2" className="cn_atom_px-8">
              <H2Text fontWeight="medium" as="h3" className="cn_w-full cn_md:w-auto mb-2">
                {getTitle()}
              </H2Text>
            </BasicContainer>
          )}
          <UnlimitedMediaUpsell
            show={isMobile && purchaseableItems?.length > 0}
            plans={plans}
            countryCode={countryCode}
            currencyCode={currencyCode}
          />
          <GridContainer isModal={isModal || isMobileRegistration}>
            {!isMobileRegistration && (
              <CheckoutPlans
                onNext={onNext}
                isAnnual={isAnnual}
                plans={plans}
                previewMediaPurchase={previewMediaPurchase}
                previewMediaPurchaseLoading={previewMediaPurchaseLoading}
                plansError={plansError}
                plansLoading={plansLoading}
                setIsAnnual={setIsAnnual}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                countryCode={countryCode}
                isPlanVisible={!isMobile || !!purchaseableItems}
                startingStep={startingStep}
                isModal={isModal}
                getTitle={getTitle}
                showPaymentMethods={showPaymentMethods}
                setShowPaymentMethods={setShowPaymentMethods}
                startsAtCheckoutMobile={startsAtCheckoutMobile}
                handleBack={handleBack}
                isHeaderHidden={isMobile && (showPaymentMethods || !startsAtCheckoutMobile)}
                currentPlanCode={currentPlanCode}
                paywallLocation={paywallLocation}
                isWithinTrialPeriod={isWithinTrialPeriod}
              />
            )}
            {(showPaymentMethods || !isMobile || !startsAtCheckoutMobile) && (
              <Loader loading={completePurchaseLoading} LoaderComponent={<PaymentLoader />}>
                <FlexCol gap="4">
                  <Loader loading={walletLoading} LoaderComponent={<WalletLoader />}>
                    {showAddPaymentMethod || isMobileRegistration ? (
                      <AddPayment
                        setIsAnnual={setIsAnnual}
                        isAnnual={isAnnual}
                        onCancel={() => setShowAddPaymentMethod(false)}
                        addPaymentError={addPaymentError}
                        addPaymentLoading={addPaymentLoading}
                        allowCancel={!!wallet?.length}
                        onAddPaymentOption={onAddPaymentOption}
                        showThreeDSecurityForm={showThreeDSecurityForm}
                        threeDActionToken={threeDActionToken}
                        onThreeDActionError={onThreeDActionError}
                        currencyCode={currencyCode}
                        countryCode={countryCode}
                        paywallLocation={paywallLocation}
                        onCompletePurchase={onCompletePurchase}
                        selectedPlan={selectedPlan}
                        currentPurchase={currentPurchase as Purchase}
                        completePurchaseError={completePurchaseError}
                        selectedPaymentOption={selectedPaymentOption}
                        wallet={wallet}
                        onSelectPaymentOption={onSelectPaymentOption}
                        onNext={onNext}
                      />
                    ) : (
                      <PaymentMethods
                        wallet={wallet}
                        onAddPaymentMethod={() => setShowAddPaymentMethod(true)}
                        onSelectPaymentOption={onSelectPaymentOption}
                        selectedPaymentOption={selectedPaymentOption}
                      />
                    )}
                  </Loader>
                  {!isMobileRegistration && (
                    <PaymentSummary
                      onNext={onNext}
                      selectedPlan={selectedPlan}
                      onCompletePurchase={onCompletePurchase}
                      currentPurchase={currentPurchase as Purchase}
                      currencyCode={currencyCode}
                      onApplyCoupon={onApplyCoupon}
                      onRemoveCoupon={onRemoveCoupon}
                      applyCouponError={applyCouponError}
                      couponLoading={couponLoading}
                      completePurchaseError={completePurchaseError}
                      completePurchaseLoading={completePurchaseLoading}
                      selectedPaymentOption={selectedPaymentOption}
                      allowPurchase={!showAddPaymentMethod}
                      enableAnnualRenewal={enableAnnualRenewal}
                      disableCoupon={
                        !!(currentPurchase as Purchase)?.coupon || completePurchaseLoading || couponLoading
                      }
                      paywallLocation={paywallLocation}
                      countryCode={countryCode}
                      purchaseableItems={purchaseableItems}
                      closeBillingPopup={closeBillingPopup}
                    />
                  )}
                  {isMobile &&
                    (showPaymentMethods || !startsAtCheckoutMobile) &&
                    !isMobileRegistration &&
                    !purchaseableItems && (
                      <PrimaryButton
                        variant="secondary"
                        label={t('button.back')}
                        className="cn_w-full mt-2"
                        onClick={handleBack}
                      />
                    )}
                </FlexCol>
              </Loader>
            )}
          </GridContainer>
        </BasicContainer>
      </Loader>
    </BasicContainer>
  );
};
