import React from 'react';
import ErrorMessage from '@minecraft.formik-error-message';

export const withErrorMessage = (FieldComponent) => (props) =>
  (
    <>
      <FieldComponent
        {...props}
        isValid={props.form.touched[props.field.name] && !props.form.errors[props.field.name]}
      />
      {!props.noCustomError && (
        <ErrorMessage
          name={props.field.name}
          data-qa-id={props.field.name}
          errors={props.form.errors}
          touched={props.form.touched}
        />
      )}
    </>
  );
