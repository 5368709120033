import React from 'react';
import styled, { css } from 'styled-components';

import { WindowWidthContext } from '@minecraft.utils';

import { StyledDividedSection } from './Section';

const DEFAULT_CARD_WIDTH = '13.5rem';

const StyledGrid = styled.div<{
  cardWidth: React.CSSProperties['width'];
  columnWidth: React.CSSProperties['width'];
  isMobile: boolean;
}>`
  display: inline-grid;
  gap: 1rem;
  padding-block: 2rem;

  ${({ columnWidth, isMobile }) =>
    isMobile
      ? css`
          // Styles when the page is smaller than the breakpoint
          align-content: center;
          grid-template-columns: 1fr;
          justify-content: center;
        `
      : css`
          // Styles when the page is larger than the breakpoint
          align-content: inherit;
          grid-template-columns: repeat(auto-fit, ${columnWidth});
          justify-content: inherit;
        `}
  ${StyledDividedSection} {
    // Styles the card section within this grid
    width: ${({ cardWidth, isMobile }) => (isMobile ? '100%' : cardWidth)};
  }
`;

type GridProps = {
  // Add additional properties here
  cardWidth?: React.CSSProperties['width'];
  numberOfDividedSections?: number;
};

export const Grid: React.FC<React.ComponentProps<typeof StyledGrid> & GridProps> = ({
  cardWidth = DEFAULT_CARD_WIDTH,
  children,
  numberOfDividedSections,
  ...props
}) => {
  const isMobile = WindowWidthContext.useIsMobile();
  const columnWidth = React.useMemo(() => {
    if (isMobile || !numberOfDividedSections) {
      return cardWidth;
    }

    return `calc(${numberOfDividedSections} * ${cardWidth})`;
  }, [isMobile, numberOfDividedSections, cardWidth]);

  return (
    <StyledGrid {...props} cardWidth={cardWidth} columnWidth={columnWidth} isMobile={isMobile}>
      {children}
    </StyledGrid>
  );
};
