import styled, { css } from 'styled-components';
import { media } from '@minecraft.themes';
import Icon from '@minecraft.icon';
import Button from '@minecraft.button';
import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  ThreeDSecureAction,
} from '@recurly/react-recurly';

type InputErrorProps = {
  $err?: boolean;
};

export const FormFieldsWrapper = styled.div`
  width: 100%;

  ${media.sm`
    padding-top: 0;
  `}
`;

export const SummaryHeader = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 1rem;
  border-bottom: solid 1px ${(props): string => props.theme.colors.primary.ltGrey1};
  grid-area: mainHeader;

  ${media.md`
    font-size: 1.875rem;
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  `}
`;

const billingSummaryAreaCSS = (props: { hideBillingSummary: boolean }) => {
  if (props.hideBillingSummary) {
    return css`
      justify-content: center;
      grid-area: formOnly;
    `;
  }

  return css`
    grid-area: formOnly billingSummary;
  `;
};

export const StyledElementsContainer = styled.div`
  display: grid;
  grid-template-columns: fit-content(auto);
  grid-template-rows: repeat(7, auto);
  grid-template-areas:
    'mainHeader'
    'header'
    'selectCard'
    'mainForm'
    'billingSummary'
    'smallPrint'
    'buttons';

  /* Apply the billingSummaryAreaCSS function */

  ${media.md`
    padding-top: 2.75rem;
    grid-template-columns: minmax(15rem, 31.25rem);
    grid-template-rows: auto 1fr;
    grid-template-areas: 'mainHeader mainHeader'
                         'formOnly billingSummary';
                         ${billingSummaryAreaCSS}
  `}
`;

export const StyledForm = styled.form``;

export const StyledLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;

  input {
    display: block;
    border-radius: 0.25rem;
    border: 1px solid ${(props) => props.theme.colors.primary.ltGrey1};
    padding: 0.375rem 0.75rem;
    height: 2.5rem;
    margin-top: 0.625rem;
    width: 100%;
  }
`;

export const StyledCardNumberElement = styled(CardNumberElement)<InputErrorProps>`
  & > div {
    height: 2.5rem;
    width: 100%;
    border: 1px solid ${(props) => (props.$err ? props.theme.colors.accent.red : props.theme.colors.primary.ltGrey1)};
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.colors.primary.white};
  }
`;

export const StyledCardMonthElement = styled(CardMonthElement)<InputErrorProps>`
  & > div {
    height: 2.5rem;
    width: 100%;
    border: 1px solid ${(props) => (props.$err ? props.theme.colors.accent.red : props.theme.colors.primary.ltGrey1)};
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.colors.primary.white};
  }
`;

export const StyledCardYearElement = styled(CardYearElement)<InputErrorProps>`
  & > div {
    height: 2.5rem;
    width: 100%;
    border: 1px solid ${(props) => (props.$err ? props.theme.colors.accent.red : props.theme.colors.primary.ltGrey1)};
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.colors.primary.white};
  }
`;

export const StyledCardCvvElement = styled(CardCvvElement)<InputErrorProps>`
  & > div {
    height: 2.5rem;
    width: 75%;
    border: 1px solid ${(props) => (props.$err ? props.theme.colors.accent.red : props.theme.colors.primary.ltGrey1)};
    border-radius: 0.25rem;
    background-color: ${(props) => props.theme.colors.primary.white};
  }
`;

export const StyledExpDateSection = styled.div`
  display: flex;
  width: 100%;

  & label:first-of-type {
    margin-right: 1rem;
  }
`;

export const StyledCVVSection = styled.div`
  display: flex;
  width: 80%;

  ${media.md`
  width: 100%;
`}
`;

export const StyledZipSection = styled(StyledLabel)`
  width: 100%;

  & input {
    width: 100%;
  }
`;

export const StyledCVVIconsWrapper = styled.div`
  display: flex;
  ${media.md`
    justify-content: flex-end;
  `}
`;

export const StyledCardTypesContainer = styled.div`
  margin-bottom: 1rem;
  & * {
    margin-right: 0.313rem;
  }
`;

export const StyledButton = styled(Button)`
  height: 2.5rem;
  font-size: 1rem;

  ${media.sm`
    font-size: 0.875rem;
    padding: 0.275rem;
  `}
`;

export const StyledCancelButtonMobile = styled(StyledButton)`
  background: ${(props) => props.theme.colors.primary.white};
  color: ${(props) => props.theme.colors.primary.primary};
  border: 1px solid ${(props) => props.theme.colors.primary.primary};
  margin-top: 1rem;
  font-size: 1rem;
`;

export const StyledSecondaryBtn = styled.button`
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
  color: ${(props) => props.theme.colors.primary.primary};
  width: auto;
  border: none;
  font-weight: 400;
  text-decoration: none;
  && {
    margin-right: 0;
  }

  ${media.sm`
    flex-direction: row;
    font-size: 0.875rem;
  `}
`;

export const StyledDeleteButton = styled(StyledSecondaryBtn)`
  color: ${(props) => props.theme.colors.accent.red};
`;

export const StyledBackBtnContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.ltGrey1};
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 0 0;

  & button {
    padding-left: 0;
  }

  ${media.sm`
    border-top: none;
    border-bottom: none;
    padding: 0;
  `}
`;

export const StyledCVVIcon = styled(Icon)`
  width: 4rem;
  height: 2.5rem;
  align-self: center;
`;

export const StyledAuthorizeMessage = styled.p`
  font-size: 0.875rem;
  margin-bottom: 2rem;
  grid-area: smallPrint;
`;

export const StyledExternalLink = styled.a`
  font-size: 0.875rem;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  grid-area: buttons;

  ${media.sm`
    flex-direction: row;
  `}
`;

export const StyledCTAContainer = styled.div<{ $withDelete: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.5rem;

  ${media.sm`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: ${(props) => (props.$withDelete ? 'space-between' : 'flex-end')};
    padding: 0 1.5rem;
  `};
`;

export const StyledMainForm = styled.div`
  grid-area: mainForm;
`;

export const StyledBillingSummary = styled.div`
  grid-area: billingSummary;
  margin: 0 auto;
  margin-bottom: 1rem;

  ${media.md`
    min-width: 23.25rem;
  `}

  ${media.lg`
    min-width: 31.25rem;
  `}
`;

export const StyledPaymentMethodHeader = styled.h6`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;

  ${media.md`
    font-size: 1.125rem;
  `}
`;

export const StyledFormSectionHeader = styled.h6`
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  grid-area: header;

  ${media.md`
    font-size: 1.25rem;
  `}
`;

export const StyledSelectPaymentMethodContainer = styled.div`
  grid-area: selectCard;
`;

export const StyledInnerContainer = styled.div`
  grid-area: formOnly;
`;

export const StyledInput = styled.input<InputErrorProps>`
  &&& {
    border: 1px solid ${(props) => (props.$err ? props.theme.colors.accent.red : 'inherit')};
  }
`;

export const StyledErrorArea = styled.i`
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.accent.red};
`;

export const StyledHelpIcon = styled(Icon)`
  height: 1rem;
  width: 1rem;
`;

export const StyledLabelSpan = styled.span`
  margin-right: 0.5rem;
`;

export const Styled3DSecurityAction = styled(ThreeDSecureAction)`
  height: 25rem;
  min-width: 15.625rem;
`;

export const StyledCheckboxWrapper = styled.div`
  margin-right: 1rem;
  background: ${(props) => props.theme.colors.primary.white};
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.primary.ltGrey1};

  label {
    margin-bottom: 0;
  }
`;
