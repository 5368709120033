import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import Icon from '@minecraft.icon';
import UncontrolledTooltip from '@minecraft.uncontrolled-tooltip';
import { BaseballCardProfileFragment } from '@minecraft.graphql-operations';
import { BodyText, BodyTextSmall, FlexRow } from '@minecraft.atoms';
import { WindowWidthContext } from '@minecraft.utils';

export const PLACEHOLDER_STAGENAME = 'Untitled Actor';

export const getAge = (birthDate: Date, today = new Date()) => {
  if (birthDate && today) {
    const age = new Date(today).getFullYear() - new Date(birthDate).getFullYear();
    const isMinor = age < 18;

    return { age, isMinor };
  }

  return { age: undefined, isMinor: false };
};

const StyledStageName = styled.div<{ isMinor: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMinor }) => (isMinor ? '1fr 2.5rem' : '1fr')};
  line-height: 1rem;
  gap: 0.25rem;

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

type StageNameProps = React.ComponentProps<typeof StyledStageName> &
  Pick<BaseballCardProfileFragment, 'dateOfBirth' | 'profileId' | 'stageName'>;

export const StageName = ({ dateOfBirth, profileId, stageName = PLACEHOLDER_STAGENAME, ...props }: StageNameProps) => {
  const isMobile = WindowWidthContext.useIsMobile();
  const { age, isMinor } = getAge(dateOfBirth);

  const shouldShowSmallText =
    (isMobile && isMinor && stageName.length > 10) ||
    (isMobile && !isMinor && stageName.length > 14) ||
    (!isMobile && isMinor && stageName.length > 14) ||
    (!isMobile && !isMinor && stageName.length > 18);
  const Text = shouldShowSmallText ? BodyTextSmall : BodyText;

  return (
    <>
      <StyledStageName isMinor={isMinor} {...props}>
        <Text
          as="span"
          fontWeight="medium"
          id={`StageName-${profileId}`}
          testId={shouldShowSmallText ? 'stagename-smalltext' : 'stagename-largetext'}
        >
          {stageName}
        </Text>
        {isMinor ? (
          <FlexRow alignItems="baseline">
            <Icon size="sm" name="spicerack-age" />
            <Text as="span" id={`StageName-${profileId}`} testId="stagename-age">
              {age}
            </Text>
          </FlexRow>
        ) : null}
      </StyledStageName>
      <UncontrolledTooltip data-testid="stagename-tooltip" placement="top" target={`StageName-${profileId}`}>
        {stageName}
      </UncontrolledTooltip>
    </>
  );
};

const LoadingWrapper = (props: StageNameProps | { loading: true }) => {
  return 'loading' in props ? (
    <BodyText>
      <Skeleton />
    </BodyText>
  ) : (
    <StageName {...props} />
  );
};

export default LoadingWrapper;
