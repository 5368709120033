/* eslint-disable sonarjs/no-duplicate-string */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import { addUserProperties } from '@blocs.heap';
import { gqlBillingPlan, gqlCountry, gqlSubscription } from '@minecraft.graphql-types';
import { useQuery } from '@apollo/client';
import { ArtistSubscriptionFragment, BillingPlansDoc, PlanTermEnum } from '@minecraft.graphql-operations';
import { useUserContext } from '@blocs.auth';
import { TranslateFn, useTranslation } from '@blocs.i18n';
import { FEATURE_FLAGS, useFeatureExperiment } from '@blocs.features-experiments';
import moment, { MomentInput } from 'moment';
import {
  PlanFeatureType,
  getPlanFeatures,
  ESSENTIAL_FEATURES,
  ESSENTIAL_WITH_FREE_FEATURES,
  FREE_FEATURES,
  FULL_ESSENTIAL_FEATURES,
  FULL_FREE_FEATURES,
  FULL_PREMIUM_FEATURES,
  PREMIUM_FEATURES,
  FULL_ULTIMATE_FEATURES,
  ULTIMATE_FEATURES,
} from './features';
import { PaywallLocation, paywallLocationToBillingPath } from './enums/PaywallLocation';
import { priceFormatter } from './helpers/priceFormatter';
import { getTrialTimeLeft } from './helpers/trialDuration';
import { PlanCode, TemperatureValues, BillingCodes } from './enums/plans';
import { captureExceptionEvent } from '../Sentry/Sentry';

const NON_REACTIVATE_LOCATIONS = [
  PaywallLocation.SIGNUP,
  PaywallLocation.MEMBERSHIP,
  PaywallLocation.OVERAGE_UPSELL_MODAL,
  PaywallLocation.OVERAGE_MODAL,
  PaywallLocation.THRIVE_UPSELL,
];

export interface UsePlansProps {
  subscription?: ArtistSubscriptionFragment | gqlSubscription;
  paywallLocation?: PaywallLocation;
  artistId?: string | number;
}

export type PlanType = {
  name: string;
  code: PlanCode;
  title: string;
  description: string;
  billingCode: gqlBillingPlan['billingCode'];
  icon?: string;
  heroImage: string;
  including?: string;
  billed?: string;
  month?: string;
  temperature: TemperatureValues;
  keyFeatures: PlanFeatureType[];
  features: PlanFeatureType[];
  combinedFeatures?: PlanFeatureType[];
  featuresTitle?: string;
  price: string | number;
  discountedPrice?: string | number;
  strikeThroughPrice?: string | number;
  cta?: string;
  order?: number;
  canUpgrade?: boolean;
  isCurrent?: boolean;
  level: number;
  term: {
    code: string;
    name?: string;
  };
  currencyCode?: string;
  canRenew?: boolean;
  iconName?: string;
  isSecondary?: boolean;
  titleWithBillingInterval?: string;
  trialLengthDays?: number;
  isTrialPeriodEnabled?: boolean;
  isUpgradeDisabled?: boolean;
  shouldHidePlan?: boolean;
  isUpgradePath?: boolean;
  customCTA?: string;
  handleCustomCTA?: () => void;
  isReactivation?: boolean;
  gradientColor?: string;
  isEssentialTrialPlan?: boolean;
};

export interface PlanTemperatureProps {
  temperature?: TemperatureValues;
  isTrialPeriodEnabled?: boolean;
  isUpgradeDisabled?: boolean;
}

export const PLANS: PlanType[] = [
  {
    code: PlanCode.Ultimate,
    name: 'ultimate',
    gradientColor: 'blue',
    billingCode: BillingCodes.ultimateAnnual,
    title: 'common:paywall.plan.ultimate_annual.title',
    description: 'common:paywall.plan.ultimate_annual.description',
    icon: 'ultimate',
    iconName: 'ultimate-check',
    heroImage: 'ultimate-hero',
    including: 'common:paywall.plan.ultimate_annual.including',
    billed: 'common:paywall.plan.billed_yearly',
    month: 'common:paywall.plan.month',
    temperature: TemperatureValues.spicy,
    keyFeatures: ULTIMATE_FEATURES,
    features: FULL_ULTIMATE_FEATURES,
    featuresTitle: 'common:paywall.plan.features.ultimate_annual.title',
    price: 0,
    discountedPrice: 0,
    cta: '',
    order: 3,
    level: 7,
    term: {
      code: PlanTermEnum.Annual,
    },
  },
  {
    code: PlanCode.Ultimate,
    name: 'ultimate',
    gradientColor: 'blue',
    billingCode: BillingCodes.ultimateMonth,
    title: 'common:paywall.plan.ultimate_m2m.title',
    description: 'common:paywall.plan.ultimate_m2m.description',
    icon: 'ultimate',
    iconName: 'ultimate-check',
    heroImage: 'ultimate-hero',
    including: 'common:paywall.plan.ultimate_m2m.including',
    billed: 'common:paywall.plan.billed_monthly',
    month: 'common:paywall.plan.month',
    temperature: TemperatureValues.spicy,
    keyFeatures: ULTIMATE_FEATURES,
    features: FULL_ULTIMATE_FEATURES,
    featuresTitle: 'common:paywall.plan.features.ultimate_m2m.title',
    price: 0,
    discountedPrice: 0,
    cta: '',
    order: 3,
    level: 6,
    term: {
      code: PlanTermEnum.Monthly,
    },
  },
  {
    code: PlanCode.Premium,
    name: 'Premium',
    billingCode: BillingCodes.premiumAnnual,
    title: 'common:paywall.plan.premium_annual.title',
    description: 'common:paywall.plan.premium_annual.description',
    icon: 'diamondoutline',
    iconName: 'check-circle',
    heroImage: 'premium-plan',
    including: 'common:paywall.plan.premium_annual.including',
    billed: 'common:paywall.plan.billed_yearly',
    month: 'common:paywall.plan.month',
    temperature: TemperatureValues.cool,
    keyFeatures: PREMIUM_FEATURES,
    features: FULL_PREMIUM_FEATURES,
    featuresTitle: 'common:paywall.plan.features.premium_annual.title',
    price: 0,
    discountedPrice: 0,
    cta: '',
    order: 2,
    level: 5,
    term: {
      code: PlanTermEnum.Annual,
    },
  },
  {
    code: PlanCode.Premium,
    name: 'Premium',
    billingCode: BillingCodes.premiumMonth,
    title: 'common:paywall.plan.premium_m2m.title',
    description: 'common:paywall.plan.premium_m2m.description',
    icon: 'diamondoutline',
    iconName: 'check-circle',
    heroImage: 'premium-plan',
    including: 'common:paywall.plan.premium_m2m.including',
    billed: 'common:paywall.plan.billed_monthly',
    month: 'common:paywall.plan.month',
    temperature: TemperatureValues.cool,
    keyFeatures: PREMIUM_FEATURES,
    features: FULL_PREMIUM_FEATURES,
    featuresTitle: 'common:paywall.plan.features.premium_m2m.title',
    price: 0,
    discountedPrice: 0,
    cta: '',
    order: 2,
    level: 4,
    term: {
      code: PlanTermEnum.Monthly,
    },
  },
  {
    code: PlanCode.Essential,
    name: 'Essential',
    billingCode: BillingCodes.essentialAnnual,
    title: 'common:paywall.plan.essential_annual.title',
    description: 'common:paywall.plan.essential_annual.description',
    icon: 'documenttext',
    iconName: 'check-circle',
    heroImage: 'essential-plan',
    including: 'common:paywall.plan.essential_annual.including',
    billed: 'common:paywall.plan.billed_yearly',
    month: 'common:paywall.plan.month',
    temperature: TemperatureValues.hot,
    featuresTitle: 'common:paywall.plan.features.essential_annual.title',
    keyFeatures: ESSENTIAL_FEATURES,
    features: FULL_ESSENTIAL_FEATURES,
    combinedFeatures: ESSENTIAL_WITH_FREE_FEATURES,
    price: 0,
    discountedPrice: 0,
    cta: '',
    order: 1,
    level: 3,
    term: {
      code: PlanTermEnum.Annual,
    },
  },
  {
    code: PlanCode.Essential,
    name: 'Essential',
    billingCode: BillingCodes.essentialMonth,
    title: 'common:paywall.plan.essential_m2m.title',
    description: 'common:paywall.plan.essential_m2m.description',
    icon: 'documenttext',
    iconName: 'check-circle',
    heroImage: 'essential-plan',
    including: 'common:paywall.plan.essential_m2m.including',
    billed: 'common:paywall.plan.billed_monthly',
    month: 'common:paywall.plan.month',
    temperature: TemperatureValues.hot,
    featuresTitle: 'common:paywall.plan.features.essential_m2m.title',
    keyFeatures: ESSENTIAL_FEATURES,
    features: FULL_ESSENTIAL_FEATURES,
    combinedFeatures: ESSENTIAL_WITH_FREE_FEATURES,
    price: 0,
    discountedPrice: 0,
    cta: '',
    order: 1,
    level: 2,
    term: {
      code: PlanTermEnum.Monthly,
    },
  },

  {
    code: PlanCode.Free,
    name: 'Free',
    billingCode: BillingCodes.registration,
    title: 'common:paywall.plan.registration.title',
    description: 'common:paywall.plan.registration.description',
    heroImage: 'name-of-hero',
    iconName: 'check-circle',
    billed: 'common:paywall.plan.billed_monthly',
    month: 'common:paywall.plan.month',
    temperature: TemperatureValues.vanilla,
    keyFeatures: FREE_FEATURES,
    features: FULL_FREE_FEATURES,
    price: 0,
    order: 0,
    level: 1,
    term: {
      code: PlanTermEnum.Monthly,
    },
  },
];

export type DeterminePlanVisibilityArgs = {
  plan: Partial<PlanType>;
  isTrialEligible: boolean;
  hasUltimatePlan: boolean;
  paywallLocation?: PaywallLocation;
  canUpgrade: boolean;
  isAnnualPlanForCurrentM2m: boolean;
  showPremiumOnly: boolean;
  showEssentialPlan: boolean;
};

export const determinePlanVisibility = ({
  plan,
  paywallLocation,
  isAnnualPlanForCurrentM2m,
  showPremiumOnly,
  canUpgrade,
  showEssentialPlan,
}: DeterminePlanVisibilityArgs): boolean => {
  const overagePlanHidden =
    paywallLocation === PaywallLocation.OVERAGE_MODAL && (!canUpgrade || isAnnualPlanForCurrentM2m);
  const hideOnUpsellModal = paywallLocation === PaywallLocation.CUSTOM_URL_UPSELL && plan.code !== PlanCode.Premium;
  const thriveUpsellModal =
    paywallLocation === PaywallLocation.THRIVE_UPSELL &&
    (plan.billingCode === BillingCodes.registration || plan.code === PlanCode.Essential);

  return (
    plan.billingCode === BillingCodes.registration ||
    ((paywallLocation === PaywallLocation.OVERAGE_MODAL || paywallLocation === PaywallLocation.OVERAGE_UPSELL_MODAL) &&
      (!canUpgrade || isAnnualPlanForCurrentM2m)) ||
    overagePlanHidden ||
    hideOnUpsellModal ||
    thriveUpsellModal ||
    showPremiumOnly ||
    (!showEssentialPlan && plan.code !== PlanCode.Premium)
  );
};

const getStrikeThroughPrice = (plan: PlanType, plans: PlanType[]): string | number => {
  if (plan.term.code === PlanTermEnum.Monthly) {
    return '';
  }
  const monthlyPlan = plans.find((p) => p.billingCode === plan.billingCode.replace('_annual', '_m2m'));
  return monthlyPlan?.price ?? '';
};

type GetPlanCTAType = {
  t: TranslateFn;
  plan: PlanType;
  isUpgrade: boolean;
  currentPlanCode?: BillingCodes;
};

const getPlanCta = ({ t, plan, isUpgrade, currentPlanCode }: GetPlanCTAType): string => {
  if (plan.isUpgradeDisabled) {
    return t('common:paywall.plan.trial.noUpgradeCta');
  }

  if (plan.isTrialPeriodEnabled) {
    return t(`common:paywall.plan.trial.cta`, { days: plan.trialLengthDays });
  }

  if (plan.isReactivation) {
    return t('common:paywall.plan.reactivateNow');
  }

  if (plan.canRenew) {
    return t(`common:paywall.plan.renewNow`);
  }

  if (!isUpgrade) {
    return t(`common:paywall.plan.${plan.billingCode}.choose`);
  }

  if (plan.isCurrent) {
    return t(`common:paywall.plan.currentPlan`);
  }

  if (
    plan?.term?.code === PlanTermEnum.Annual &&
    currentPlanCode &&
    currentPlanCode === plan?.billingCode?.replace('_annual', '_m2m') &&
    plan.canUpgrade
  ) {
    return t(`common:paywall.plan.upgradeAnnual`);
  }

  if (plan.canUpgrade) {
    return t(`common:paywall.plan.upgrade`);
  }

  return ``;
};

type GetBilledAtTextType = {
  plan: PlanType;
  isTrialEligible?: boolean;
  t: TranslateFn;
  price: string;
};

const getBilledAtText = ({ plan, isTrialEligible, t, price }: GetBilledAtTextType): string => {
  const isAnnual = plan?.term?.code === PlanTermEnum.Annual;

  if (plan.isTrialPeriodEnabled && isAnnual) {
    return t(`common:paywall.plan.trial.annual.after_trial_price`, {
      price,
    });
  }

  if (plan.isTrialPeriodEnabled && !isAnnual) {
    return t(`common:paywall.plan.trial.m2m.after_trial_price`, {
      price,
    });
  }

  if (isAnnual && isTrialEligible) {
    return t(`common:paywall.plan.billed_yearly_at`, { price });
  }

  if (isAnnual && !isTrialEligible) {
    return t(`common:paywall.plan.billed_yearly`, { price });
  }

  return t(`common:paywall.plan.billed_monthly`, { price });
};

type TranslatePlansType = {
  t: TranslateFn;
  plans: PlanType[];
  isUpgrade: boolean;
  currentPlanCode?: BillingCodes;
  countryCode?: gqlCountry['code'];
  isTrialEligible?: boolean;
};

const translatePlans = ({
  t,
  plans,
  isUpgrade,
  currentPlanCode,
  isTrialEligible,
  countryCode,
}: TranslatePlansType): PlanType[] => {
  return plans.map((plan) => {
    const keyFeatures = plan.isTrialPeriodEnabled ? plan.combinedFeatures ?? plan.keyFeatures : plan.keyFeatures;
    const price = priceFormatter({
      price: Number(plan.price),
      currencyCode: plan.currencyCode,
      countryCode,
      t,
    });

    return {
      ...plan,
      title: t(plan.title),
      description: t(plan.description),
      billed: getBilledAtText({
        plan,
        isTrialEligible,
        t,
        price,
      }),
      including: t(plan.including),
      month: t(plan.month),
      keyFeatures: keyFeatures.map((feature) => ({
        ...feature,
        label: feature?.label,
      })),
      features: plan.features.map((feature) => ({
        ...feature,
        label: feature?.label,
      })),
      featuresTitle: t(plan.featuresTitle),
      // This portion will need to be worked out to present the proper price for the user:
      price: `${plan.price}`,
      cta: getPlanCta({ t, plan, isUpgrade, currentPlanCode }),
      strikeThroughPrice: getStrikeThroughPrice(plan, plans),
      titleWithBillingInterval:
        plan.billingCode === BillingCodes.registration
          ? t(plan.title)
          : `${t(plan.title)} - ${t(`common:planterm.titlecase.${plan.term.code.toLowerCase()}`)}`,
    };
  });
};
const getPlanByBillingCode = (billingCode: string): PlanType | undefined => {
  return PLANS.find((plan) => plan.billingCode === billingCode);
};

// If the temperature value is invalid, set it to "vanilla"
const applyDefaultTemperature = ({ plans }: { plans: PlanType[] }) => {
  return plans.map((plan) => {
    return {
      ...plan,
      temperature: TemperatureValues[plan.temperature] ? plan.temperature : TemperatureValues.vanilla,
    };
  });
};

const THIRTY_DAYS = 30;

export const usePlans = ({ subscription, paywallLocation, artistId }: UsePlansProps) => {
  const { myAccount, previousSubscription } = useUserContext();
  const showEssentialPlan = useFeatureExperiment(FEATURE_FLAGS.ENABLE_ESSENTIAL_PLAN);
  const countryCode = myAccount?.country?.code ?? 'US';
  const { t } = useTranslation();
  const plansArtistId = useRef(artistId);

  const addTrialPropertyToHeap = useCallback((data) => {
    if (!data?.billingPlans) {
      return;
    }
    const trialLengthDays = data?.billingPlans?.find((plan: PlanType) => plan?.trialLengthDays > 0)?.trialLengthDays;
    if (paywallLocation === PaywallLocation.SIGNUP) {
      if (trialLengthDays) {
        addUserProperties({ subscription: `${trialLengthDays}day-premiumtrial` });
      }
    } else {
      addUserProperties({ '7_day_premium_trial_via_upgrade_path': trialLengthDays === 7 });
    }
  }, []);

  const billingPath = useMemo(() => paywallLocationToBillingPath(paywallLocation), [paywallLocation]);
  const {
    data: plansData,
    error: plansError,
    loading: plansLoading,
    refetch: refetchPlans,
  } = useQuery(BillingPlansDoc, {
    variables: {
      input: {
        billingPath,
      },
    },
    skip: !artistId || !paywallLocation,
    onCompleted(data) {
      addTrialPropertyToHeap(data);
    },
  });

  const handleRefetchPlans = useCallback(() => refetchPlans({ input: { billingPath } }), [billingPath, refetchPlans]);

  const currentPlanCode = subscription?.plan?.billingCode;
  const isTrialEligible = useMemo(
    () => !plansLoading && plansData?.billingPlans?.some((plan) => plan?.trialLengthDays > 0),
    [plansData, plansLoading]
  );

  const hasUltimatePlan = useMemo(
    () =>
      !plansLoading &&
      plansData?.billingPlans?.some(
        (plan) => plan?.billingCode === BillingCodes.ultimateAnnual || plan?.billingCode === BillingCodes.ultimateMonth
      ),
    [plansData, plansLoading]
  );

  const isSubscriptionWithin30Days = useMemo(() => {
    return (
      subscription?.expirationDate && differenceInDays(new Date(subscription.expirationDate), new Date()) < THIRTY_DAYS
    );
  }, [subscription]);

  const isWithinTrialPeriod = useMemo(() => {
    const { hasExpired, isInvalidDate, days, hours, minutes } = getTrialTimeLeft(subscription?.trialEndsAt as string);
    const isDueNow = days === 0 && hours === 0 && minutes === 0;
    return !isInvalidDate && !hasExpired && !isDueNow;
  }, [subscription]);

  const currentPlan = getPlanByBillingCode(currentPlanCode as BillingCodes);

  const isAnnualRenewalDue =
    isSubscriptionWithin30Days &&
    !isWithinTrialPeriod &&
    currentPlan?.term?.code === PlanTermEnum.Annual &&
    paywallLocation === PaywallLocation.MEMBERSHIP;

  const checkIsPremiumPlan = (billingCode: BillingCodes) =>
    [BillingCodes.premiumAnnual, BillingCodes.premiumMonth, BillingCodes.premiumComped].includes(billingCode);

  const hasPriorPremiumPlan =
    checkIsPremiumPlan(previousSubscription?.plan?.billingCode as BillingCodes) &&
    moment().isAfter(moment(previousSubscription?.expirationDate as MomentInput)) &&
    paywallLocation &&
    !NON_REACTIVATE_LOCATIONS.includes(paywallLocation);

  const plans = useMemo((): PlanType[] => {
    if (plansData?.billingPlans?.length) {
      const availablePlans: PlanType[] = plansData?.billingPlans
        ?.map((planFromNetwork) => {
          const plan = PLANS.find((p) => p.billingCode === planFromNetwork.billingCode);
          const isFreePlanOnTrial = planFromNetwork.billingCode === BillingCodes.registration && isTrialEligible;
          if (!plan || isFreePlanOnTrial) {
            return null;
          }
          const isPremiumPlan = checkIsPremiumPlan(planFromNetwork.billingCode as BillingCodes);
          const isAnnualPlanForCurrentM2m =
            (currentPlan?.level ?? 0) + 1 === (plan?.level ?? 0) && plan?.term?.code === PlanTermEnum.Annual;
          const isCurrentPlan = planFromNetwork.billingCode === subscription?.plan?.billingCode;
          const canRenew = isAnnualRenewalDue && isCurrentPlan;
          const isRegistrationPlan = planFromNetwork.billingCode === BillingCodes.registration;
          const isTrialPeriodEnabled = planFromNetwork.trialLengthDays > 0 && isTrialEligible;
          const isUpgradeDisabled = isAnnualPlanForCurrentM2m && isWithinTrialPeriod;
          const canUpgrade = planFromNetwork?.level > (subscription?.plan?.level ?? -1);
          const isUpgradePath = paywallLocation !== PaywallLocation.SIGNUP;
          const showPremiumOnly = hasPriorPremiumPlan && !isPremiumPlan;
          const isReactivation = isPremiumPlan && hasPriorPremiumPlan;
          const shouldHidePlan = determinePlanVisibility({
            plan,
            isTrialEligible,
            hasUltimatePlan,
            paywallLocation,
            canUpgrade,
            isAnnualPlanForCurrentM2m,
            showPremiumOnly,
            showEssentialPlan,
          });
          let planIncludingText = plan.including;
          if (isReactivation || !showEssentialPlan) {
            planIncludingText = `common:paywall.plan.reactivate.${plan.billingCode}.including`;
          }
          if (isTrialPeriodEnabled && plan.code === PlanCode.Essential) {
            planIncludingText = '';
          }
          const { keyFeatures, features, combinedFeatures } = getPlanFeatures({
            code: plan.code,
          });

          return {
            ...plan,
            name: planFromNetwork.name,
            price: planFromNetwork.price,
            discountedPrice: 0,
            isCurrent: isCurrentPlan,
            canUpgrade,
            canRenew,
            currencyCode: planFromNetwork.currency.code,
            term: planFromNetwork.term,
            level: planFromNetwork.level,
            trialLengthDays: planFromNetwork.trialLengthDays ?? 0,
            isSecondary: isRegistrationPlan && !isCurrentPlan,
            including: planIncludingText,
            isTrialPeriodEnabled,
            isUpgradeDisabled,
            shouldHidePlan,
            isUpgradePath,
            isReactivation,
            isEssentialTrialPlan: planFromNetwork.trialLengthDays > 0 && plan.code === PlanCode.Essential,
            features,
            keyFeatures,
            combinedFeatures,
          };
        })
        .filter(Boolean); // Filter out the null values

      // Translate the plans:
      const translatedPlans = translatePlans({
        t,
        currentPlanCode: subscription?.plan?.billingCode as BillingCodes,
        plans: availablePlans,
        isUpgrade: !!subscription?.plan?.billingCode,
        isTrialEligible,
        countryCode,
      });
      // Make sure the temperature value is valid
      const plansWithTemperature = applyDefaultTemperature({ plans: translatedPlans });

      // Sort the plans by order
      return plansWithTemperature.sort((a, b) => a.order - b.order);
    }

    return [];
  }, [
    plansData?.billingPlans,
    t,
    subscription,
    isTrialEligible,
    countryCode,
    currentPlan?.level,
    isAnnualRenewalDue,
    isWithinTrialPeriod,
    showEssentialPlan,
    paywallLocation,
    hasPriorPremiumPlan,
    hasUltimatePlan,
  ]);

  const currentTranslatedPlan = useMemo(() => {
    return plans?.find((plan) => plan.billingCode === currentPlanCode);
  }, [plans, currentPlanCode]);

  // usePlans exists at the highest level which is the BillingPopupContextProvider.tsx this will never remount on page change including
  // context change. This is why we are using the artistId to indicate when a context change has occurred and will refetch the plansData
  useEffect(() => {
    if (plansArtistId.current !== artistId && paywallLocation && billingPath) {
      handleRefetchPlans().catch((e) => captureExceptionEvent(e));
      plansArtistId.current = artistId;
    }
  }, [artistId, billingPath, handleRefetchPlans, paywallLocation, refetchPlans]);

  // check for a paywall location change if present update our plans list
  useEffect(() => {
    if (paywallLocation && billingPath) {
      handleRefetchPlans().catch((e) => captureExceptionEvent(e));
    }
  }, [billingPath, handleRefetchPlans, paywallLocation, refetchPlans]);

  return useMemo(
    () => ({
      plans,
      error: plansError,
      loading: plansLoading,
      isAnnualRenewalDue,
      isWithinTrialPeriod,
      currentPlan: currentTranslatedPlan,
      isTrialEligible,
      isReactivate: hasPriorPremiumPlan,
    }),
    [
      plans,
      plansError,
      plansLoading,
      isAnnualRenewalDue,
      isWithinTrialPeriod,
      currentTranslatedPlan,
      isTrialEligible,
      hasPriorPremiumPlan,
    ]
  );
};
