import React, { useContext, FC } from 'react';
import { useTranslation } from '@blocs.i18n';
import { BodyText } from '@minecraft.atoms';
import Skeleton from 'react-loading-skeleton';
import PaginationContext from './PaginationContext';

/** Types */
export type Props = {
  className?: string;
  loading?: boolean;
};

/** Main Component */
const GridResultText: FC<Props> = ({ className, loading }) => {
  const { totalRecords, pageLimit, currentPage } = useContext(PaginationContext);
  const { t } = useTranslation();

  const from = (currentPage - 1) * pageLimit + 1;
  const to = Math.min(pageLimit * currentPage, totalRecords);

  if (loading) return <Skeleton width="11rem" height="1rem" />;

  return (
    <BodyText
      fontWeight="bold"
      qaId="pagination-result-details"
      testId="pagination-result-details"
      className={className}
    >
      {t('common:pagination.showXOfYResults', { from, to, total: totalRecords })}
    </BodyText>
  );
};

export default GridResultText;
