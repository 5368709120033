import React, { FC } from 'react';
import { useTranslation } from '@blocs.i18n';
import { EnterpriseTheme as theme } from '@minecraft.themes';
import Icon from '@minecraft.icon';
import { BodyTextLarge, BodyTextSmall, FlexCol, FlexRow } from '@minecraft.atoms';
import { StyledCreditCard, CardStatus } from './styles';

export type CreditCardProps = {
  cardType: string;
  paymentType?: string;
  lastFour: string;
  onSelect?: () => void;
  isSelected?: boolean;
  isPrimary?: boolean;
  isBackup?: boolean;
  hideArrow?: boolean;
};

export const cardTypes = {
  MasterCard: 'master-card',
  Visa: 'visa',
  'American Express': 'amex',
  ApplePay: 'applepay',
};

export enum paymentTypes {
  CreditCard = 'credit_card',
  ApplePay = 'apple_pay',
}

export const APPLE_PAY = 'Apple Pay';

export const CreditCard: FC<CreditCardProps> = ({
  cardType,
  paymentType,
  lastFour,
  onSelect,
  isSelected = false,
  isPrimary = false,
  isBackup = false,
  hideArrow = false,
}) => {
  const { t } = useTranslation();
  const isApplePay = paymentType === paymentTypes.ApplePay;
  const cardIcon = isApplePay ? cardTypes.ApplePay : cardTypes[cardType];

  return (
    <StyledCreditCard
      role="button"
      $isSelected={isSelected}
      className="d-flex flex-column justify-content-start"
      onClick={onSelect}
      aria-label={t('talent:billing.card.select', { cardType, lastFour })}
    >
      <FlexCol wrap="nowrap" className="cn_ml-1">
        {(isPrimary || isBackup) && (
          <FlexRow justifyContent="flex-end" className="cn_w-full">
            {isPrimary && <CardStatus>{t('common:billing.card.primary')}</CardStatus>}
            {isBackup && <CardStatus>{t('common:billing.card.backup')}</CardStatus>}
          </FlexRow>
        )}
        <FlexRow gap="4" className="cn_w-full" alignItems="center" wrap="nowrap">
          {cardIcon && <Icon height={3.4} name={cardIcon} />}
          <FlexCol>
            <BodyTextLarge fontWeight="semibold">{isApplePay ? APPLE_PAY : cardType}</BodyTextLarge>
            {lastFour && <BodyTextSmall>{t('common:billing.card.name', { lastFour })}</BodyTextSmall>}
          </FlexCol>
          {!hideArrow && <Icon name="chevron-right-thick" color={theme.colors.primary.darkGrey} />}
        </FlexRow>
        {(isPrimary || isBackup) && <div style={{ height: '1rem' }} />}
      </FlexCol>
    </StyledCreditCard>
  );
};
