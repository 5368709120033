import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { BodyText, FlexRow } from '@minecraft.atoms';

interface WizardFooterProps {
  label?: React.ReactNode;
}

const Wrapper = styled(FlexRow)`
  height: 72px;
  width: 100vw;
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.designSystem.wizard.footer};
  border-top: solid ${({ theme }) => theme.designSystem.wizard.footer.border} 1px;
  padding: 1.25rem;
`;

export const WizardStepFooter = ({ children, label }: PropsWithChildren<WizardFooterProps>) => {
  return (
    <Wrapper alignItems="center" justifyContent="space-between">
      {label && <BodyText>{label}</BodyText>}
      <FlexRow justifyContent="flex-end" gap="3">
        {children}
      </FlexRow>
    </Wrapper>
  );
};
