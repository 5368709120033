import React, { useContext } from 'react';
import styled from 'styled-components';
import Select from '@minecraft.select';
import { arrayToSelectOptions } from '@minecraft.utils';
import { useTranslation } from '@blocs.i18n';
import PaginationContext from '../PaginationContext';
import { handlePageLimitChange } from '../PaginationCommon';

const SelectWrapper = styled.div`
  margin-right: 0.5rem;
`;

const SelectPage = styled(Select)<any>`
  min-width: 3.3125rem;
  height: 2rem;
  padding: 0 0.25rem;
`;

const PageResultsSize: React.FC = () => {
  const { onPageChanged, pageLimit, defaultResultSizeOptions } = useContext(PaginationContext);
  const { t } = useTranslation();

  return (
    <>
      <SelectWrapper>
        <SelectPage
          data-qa-id="pagination-page-size-list"
          data-testid="pagination-page-size-list"
          aria-label={t('common:filter.pageSize')}
          value={pageLimit}
          options={arrayToSelectOptions(defaultResultSizeOptions)}
          size="small"
          onChange={(e) => handlePageLimitChange(e, onPageChanged)}
        />
      </SelectWrapper>
      <div>{t('common:filter.perPage')}</div>
    </>
  );
};

export default PageResultsSize;
