import styled from 'styled-components';
import Icon from '@minecraft.icon';

export const StyledCreditCard = styled.div<{ $isSelected: boolean }>`
  width: 18.3rem;
  height: 6.25rem;
  border-radius: 0.31rem;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.designSystem.borderColors.default};
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.designSystem.bgColors.card : theme.designSystem.bgColors.listItem};
  border-left: ${({ theme, $isSelected }) => $isSelected && `6px solid ${theme.designSystem.borderColors.select}`};
`;

export const CardStatus = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.69rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.primary.grey1};
  background-color: ${(props) => props.theme.colors.notifications.orange[50]};
  border: 1px solid ${(props) => props.theme.colors.accent.darkBrown};
  border-radius: 0.19rem;
  padding: 0 0.375rem;
  margin-left: 0.5rem;
`;

export const StyledAddCard = styled.div<{ $isSelected: boolean }>`
  width: 18.3rem;
  height: 6.25rem;
  border-radius: 0.31rem;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.designSystem.textColors.link};
  border: ${({ theme }) => `1px dashed ${theme.designSystem.borderColors.select}`};
  background-color: ${({ theme }) => theme.designSystem.bgColors.listItem};
`;

export const StyledAddIcon = styled(Icon)`
  margin-left: 1.375rem;
  margin-right: 1rem;
`;
